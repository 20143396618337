import {ITransacton} from "../types/interfaces";
// import {toBase64} from "./toBase64";

export const PrepareDataForAzaTransaction = async (values: ITransacton) => {

    const cleanObject = (function () {
        function isEmpty(value: any) {
            return value === '';
        }

        return function (obj: any) {

            const tempObj = {...obj}

            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {

                    let value = tempObj[key];

                    if (
                        isEmpty(value)
                        || (value instanceof Object && cleanObject(value))
                    ) {
                        delete tempObj[key];
                    }
                }
            }

            return tempObj
        };
    })();

    // const invoiceAsString = await toBase64(values.invoice)

    // recipientDetails
    const recipientTempObject = {
        name: values.name,
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
        fullName: values.fullName,
        bankCode: values.bankCode?.value,
        recipientsBankCountry: values.recipientsBankCountry,
        paymentMethod: values.paymentTypeByRegionForm,
        relationshipToSender: values.relationshipToSender,
        pixKeyValue: values.pixKeyValue,
        pixKeyType: values.pixKeyType?.value,
        branchCode: values.branchCode?.value || values.branchCodeTED,
        cashProvider: values.cashProvider,
        routingNumber: values.routingNumber,
        transferReason: values.transferReason?.value,
        identityCardType: values.identityCardType?.value,
        identityCardId: values.identityCardId,
        postalCode: values.postalCode,
        city: values.city,
        street: values.street,
        mobileProvider: values.mobileProvider?.value,
        country: values.recipientCountry?.value,
        phoneNumber: values.phoneNumber,
        recipientsBank: values.recipientsBank,
        recipientsBankAddress: values.recipientsBankAddress,
        recipientsIban: values.recipientsIban,
        recipientsSwiftBic: values.recipientsSwiftBic,
        recipientsAccountNumber: values.recipientsAccountNumber,
        recipientsAddress: values.recipientsAddress,
        bankAccountType: values.bankAccountType?.value,
        bankAccount: values.bankAccount,
        recipientsEmail: values.email,
        recipientsUniqueId: values.uniqueId,
        recipientsTransitNumber: values.transitNumber,
        recipientsInstitutionNumber: values.institutionNumber,
        senderIdType: values.senderIdType?.value,
        senderIdNumber: values.senderIdNumber,
        securityQuestion: values.securityQuestion,
        securityAnswer: values.securityAnswer,
        beneficiaryEmail: values.beneficiaryEmail,
        beneficiaryName: values.beneficiaryName,
        // invoice: invoiceAsString,
    }

    const cleanedRecipientDetails = cleanObject(recipientTempObject)

    const testObj = {
        paymentRegions: values.paymentRegions,
        fromAccount: values.payerAccount,
        paymentDate: values.paymentDate,
        reference: values.reference,
        amount: Number(values.amount),
        type: values.type,
        purpose: values.purpose,
        templateName: values.templateName,
        recipient: values.bankAccount || values.phoneNumber || values.recipientsIban || values.recipientsAccountNumber || values.pixKeyValue,
    }

    const cleanedMainObject = cleanObject(testObj)

    const preparedForSendObject = {
        ...cleanedMainObject,
        recipientDetails: cleanedRecipientDetails
    }

    return preparedForSendObject
}

