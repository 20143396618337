import React, {useEffect, useState} from 'react';
import {Layout, Space} from 'antd';
import s from './PersonalAccount.module.css'
import logo from '../../assets/newImg/personalAccount/logo.svg'
import icon_point from '../../assets/newImg/personalAccount/point.svg'
import {Link, NavLink, Outlet, useNavigate} from 'react-router-dom';
import '../../components/SideMenu/index.css'
import classnames from "classnames";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {
    getKycListThunk,
    GetTransactionsListThunk,
    LogoutThunk,
} from "../../store/reducers/ActionCreators";
import {motion} from 'framer-motion';
import MyBtn from "../../components/ui/MyBtn/MyBtn";
import CustomModal from "../../components/ui/CustomModal/CustomModal";
import styles from "../../components/ContactUsForm/ContactUsForm.module.css";
import SideMenu from "../../components/SideMenu/SideMenu";
import CustomModalContactContainer from "../../components/ui/CustomModalContactContainer/CustomModalContactContainer";

const {Header, Sider, Content} = Layout;

const headerStyle: React.CSSProperties = {
    color: '#fff',
    height: "auto",
    backgroundColor: 'transparent',
    margin: '48px 24px 36px 264px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0'
};

const contentStyle: React.CSSProperties = {
    minHeight: '100vh',
    color: '#000',
    backgroundColor: '#F6F9FF',
    padding: '0 24px 69px 264px',
};

const contentStyleMobile: React.CSSProperties = {
    minHeight: '100vh',
    color: '#000',
    backgroundColor: '#F6F9FF',
    padding: '0 16px',
};

const siderStyle: React.CSSProperties = {
    position: 'fixed',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#FFFFFF',
    flex: '0 0 240',
    borderRight: '1.5px solid #EEEFEF',
    zIndex: 1,
    padding: '56px 0 56px 48px',
    height: '100vh',
    overflow: "auto"
};

const PersonalAccount = () => {

    const accType: string | null = useAppSelector(state => state.userReducer.user.userData.accountType)
    const firstName: string | null = useAppSelector(state => state.userReducer.user.userData.firstName)
    const lastName: string | null = useAppSelector(state => state.userReducer.user.userData.lastName)
    const companyName: string | null = useAppSelector(state => state.userReducer.user.userData.companyName)
    const isTokenValid: boolean = useAppSelector(state => state.userReducer.tokenIsValid)

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const dispatch = useAppDispatch()

    const token = useAppSelector(state => state.userReducer.user.userToken)
    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus)

    useEffect(() => {
        dispatch(getKycListThunk(token))
        dispatch(GetTransactionsListThunk(token))
    }, [])

    const handleLogout = () => {
        dispatch(LogoutThunk())
            .then(() => {
                navigate("/login");
            })
    }

    const kycIsValid = () => {
        if (!kycStatus) {
            return false
        } else if (kycStatus === "New") {
            return false
        } else if (kycStatus === "Rejected") {
            return false
        } else {
            return true
        }
    }

    return (
        <div>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >

                <CustomModal isModalOpen={!isTokenValid} type={"confirm"}>
                    <div className={styles.popup_title}>
                        Oops!
                    </div>
                    <div className={styles.popup_subtitle}>
                        Your session time has been expired. Please login once again.
                    </div>
                    <div className={styles.popup_button_wrapper}>
                        <div className={styles.button_block}>
                            <MyBtn title={"Ok"} buttonType={"confirm"} onClick={() => handleLogout()}/>
                        </div>
                    </div>
                </CustomModal>

                <CustomModalContactContainer setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}/>

                <div className={s.wrapper_desktop}>
                    <Space direction="vertical" style={{width: '100%', position: "relative", background: '#F6F9FF'}}
                           size={[0, 0]}>
                        <Layout style={{background: '#F6F9FF'}}>
                            <Sider className={s.sider} style={siderStyle} width={240}>
                                <SideMenu/>
                            </Sider>
                            <Layout>
                                {<div className={s.header_wrapper}>
                                    <Header style={headerStyle}>
                                        <div className={s.left}>
                                            <div className={s.icons_block}>
                                                <div className={s.round} onClick={() => setIsModalOpen(true)}>
                                                    <div className={s.mail}></div>
                                                </div>
                                                {/*<div className={s.round}>*/}
                                                {/*    <img className={s.icon_point} src={icon_point} alt=""/>*/}
                                                {/*    <div className={s.chat}></div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                        <div className={s.right}>
                                            <div className={s.user_round}>
                                                {firstName?.slice(0, 1)}{lastName?.slice(0, 1)}{companyName?.slice(0, 1)}
                                            </div>
                                        </div>
                                    </Header>
                                </div>}
                                <Content style={contentStyle}>
                                    <Outlet/>
                                </Content>
                            </Layout>
                        </Layout>
                    </Space>
                </div>
                <div className={s.wrapper_mobile}>
                    <Space direction="vertical" style={{width: '100%', position: "relative", background: '#F6F9FF'}}
                           size={[0, 0]}>
                        <Layout style={{background: '#F6F9FF'}}>
                            <Sider className={s.sider} style={siderStyle} width={240}>
                                <div>
                                    {/*<Link to="/">*/}
                                    {/*    <img src={logo} alt="" className={s.logo}/>*/}
                                    {/*</Link>*/}
                                </div>
                                {    // @ts-ignore
                                    !kycIsValid()
                                        ? <></>
                                        : <div>
                                            <div className={s.links_title}>services</div>
                                            <NavLink to={`/personal_account/accounts`}
                                                     className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                                                <div className="img_block">
                                                    <div className="img_accounts"></div>
                                                </div>
                                                <div className="text_block">
                                                    Accounts
                                                </div>
                                            </NavLink>
                                            <NavLink to={`/personal_account/transfers`}
                                                     className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                                                <div className="img_block">
                                                    <div className="img_transfers"></div>
                                                </div>
                                                <div className="text_block">
                                                    Transfers
                                                </div>
                                            </NavLink>
                                            <NavLink to={`/personal_account/templates`}
                                                     className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                                                <div className="img_block">
                                                    <div className="img_templates"></div>
                                                </div>
                                                <div className="text_block">
                                                    Templates
                                                </div>
                                            </NavLink>
                                            <NavLink to={`/personal_account/currency`}
                                                     className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                                                <div className="img_block">
                                                    <div className="img_currency"></div>
                                                </div>
                                                <div className="text_block">
                                                    Currency
                                                </div>
                                            </NavLink>
                                        </div>

                                }

                                <div className={classnames(s.links_title, s.services_block)}>Account</div>

                                <NavLink to={`/personal_account/security`}
                                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                                    <div className="img_block">
                                        <div className="img_security"></div>
                                    </div>
                                    <div className="text_block">
                                        Security
                                    </div>
                                </NavLink>
                                <NavLink to={`/personal_account/${accType}/settings`}
                                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                                    <div className="img_block">
                                        <div className="img_settings"></div>
                                    </div>
                                    <div className="text_block">
                                        Settings
                                    </div>
                                </NavLink>

                                <div className={classnames(s.logout, 'inactive')} onClick={() => handleLogout()}>
                                    <div className="img_block">
                                        <div className="img_logout"></div>
                                    </div>
                                    <div className={s.yellow}>Logout</div>
                                </div>
                            </Sider>
                            <Layout>
                                {<div className={s.header_wrapper}>
                                    <Header style={headerStyle}>
                                        <div className={s.left}>
                                            <div className={s.icons_block}>
                                                <div className={s.round} onClick={() => setIsModalOpen(true)}>
                                                    <div className={s.mail}></div>
                                                </div>
                                                {/*<div className={s.round}>*/}
                                                {/*    <img className={s.icon_point} src={icon_point} alt=""/>*/}
                                                {/*    <div className={s.chat}></div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                        <div className={s.right}>
                                            <div className={s.user_round}>
                                                {firstName?.slice(0, 1)}{lastName?.slice(0, 1)}{companyName?.slice(0, 1)}
                                            </div>
                                        </div>
                                    </Header>
                                </div>}
                                <Content style={contentStyleMobile}>
                                    <Outlet/>
                                </Content>
                            </Layout>
                        </Layout>
                    </Space>
                </div>
            </motion.div>
        </div>
    );
};

export default PersonalAccount;
