export const ABOUT = "/about_us"
export const VIRTUAL_CURRENCIES = "services/virtual_currencies"
export const FOREIGN_EXCHANGE = "services/foreign_exchange"
export const MONEY_ORDERS = "services/money_orders"
export const MONEY_TRANSFERS = "services/money_transfers"
export const PAYMENT_SERVICE_PROVIDER = "services/payment_service_provider"
export const WHY_US = "/why_us"
export const FEES = "/fees"
export const FAQ = "/faq"
export const CONTACTS = "/contacts"
export const AGENT_PROGRAM = "/agent_program"
export const LEGAL_AND_PRIVACY = "/legal_and_privacy"
