import React, {Dispatch, SetStateAction, useState} from 'react';
import s from './KycBusinessStepTwo.module.css'
import {Field, Formik} from "formik";
import MyInput from "../../../../../../components/ui/MyInput/MyInput";
import MyBtn from "../../../../../../components/ui/MyBtn/MyBtn";
import * as yup from "yup";
import CustomDropdown from "../../../../../../components/ui/CustomDropdown/CustomDropdown";
import legal_account_application from "../../../../../../assets/newImg/personalAccount/legal_account_application.png"
import {ReactComponent as Plus} from "../../../../../../assets/newImg/personalAccount/plus.svg"
import {ReactComponent as Back}  from "../../../../../../assets/newImg/personalAccount/back.svg"
import proofOfAddress from "../../../../../../assets/newImg/personalAccount/proof_of_address.png"
import companyCharter from "../../../../../../assets/newImg/personalAccount/company_charter.png"
import registrationSertificate from "../../../../../../assets/newImg/personalAccount/registration_sertificate.png"
import beneficiaryIdDoc from "../../../../../../assets/newImg/personalAccount/beneficiary_identity.png"
import beneficiaryIdcp from "../../../../../../assets/newImg/personalAccount/beneficiary_idcp.png"
import Poa from "../../../../../../assets/newImg/personalAccount/poa.png"
import license from "../../../../../../assets/newImg/personalAccount/license.png"
import BeneficiaryProofOfAddress from "../../../../../../assets/newImg/personalAccount/proof_of_address.png"
import attachedDoc from "../../../../../../assets/newImg/personalAccount/attached_document.svg"
import delete_document from "../../../../../../assets/newImg/personalAccount/delete_document.svg"
import download_legal from "../../../../../../assets/newImg/personalAccount/download_legal_application.svg"
import classnames from "classnames";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/redux";
import {motion} from 'framer-motion';
import {sendPersonalKycThunk, setTokenState} from "../../../../../../store/reducers/ActionCreators";
import CustomModal from "../../../../../../components/ui/CustomModal/CustomModal";
import {setDisabled} from "../../../../../../store/reducers/UserSlice";
import Icon from "@ant-design/icons";
import TransparentButton from "../../../../../../components/ui/TransparentButton/TransparentButton";
import {countries, phonecodes} from "../../../../../../Constants/StaticData";
import {Checkbox, DatePicker} from "antd";
import {IPhoneNumber} from "../../../../../../types/interfaces";
import dayjs from "dayjs";
import {myDate} from "../../../../../../helpers/CalculateDate";
import SelectWithSearch from "../../../../../../components/ui/SelectWithSearch/SelectWithSearch";
import {FinancialInstitutionHelper} from "../../../../../../helpers/KycFormHelper";
import {toBase64} from "../../../../../../helpers/toBase64";

type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

const validationSchema = yup.object({
    phoneNumber: yup.string().required('Required field'),
    companyName: yup.string().required('Required field').max(90, 'max 90'),
    countryName: yup.string().required('Required field'),
    companyType: yup.string().required('Required field'),
    city: yup.string().required('Required field').max(90, 'max 90'),
    address: yup.string().required('Required field').max(90, 'max 90'),
    postalCode: yup.string().required('Required field').max(90, 'max 90'),
    email: yup.string().email('invalid format').required('Required field').max(90, 'max 90'),
    currencies: yup.array().min(1,"At least one account is required"),
    otherSourcesOfWealth: yup.string().test({
        message: 'Required field',
        test: function (value) {
            const {isOtherSourcesOfWealth} = this.parent;
            if (isOtherSourcesOfWealth) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    sourcesOfWealth: yup.array().test({
        message: 'At least one source is required',
        test: function (value) {
            const {isOtherSourcesOfWealth} = this.parent;
            if (!isOtherSourcesOfWealth) return value !== undefined && value !== null && value.length > 0;
            return true
        },
    }),
    financialRegulator: yup.string().test({
        message: 'Required field',
        test: function (value) {
            const {companyType} = this.parent;
            if (companyType === "Financial Institution") return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    regulatoryLicenseNumber: yup.string().test({
        message: 'Required field',
        test: function (value) {
            const {companyType} = this.parent;
            if (companyType === "Financial Institution") return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
})

const KycPersonalStepTwo = (props: PropsType) => {

    const token: string = useAppSelector(state => state.userReducer.user.userToken)
    const dispatch = useAppDispatch()
    const [documents, setDocuments] = useState<any>(
        {
            accountApplication: '',
            proofOfAddress: '',
            articlesMemorandums: '',
            registrationCertificate: '',
            beneficiaryIdDocument: '',
            beneficiaryIdcp: '',
            beneficiaryProofOfAddress: '',
            poA: '',
            license: '',
        }
    )
    const [docsError, setDocsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again.");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [date, setDate] = useState({
        from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ? ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + (myDate.getDate() < 10 ? ("0" + myDate.getDate()).slice(-2) : myDate.getDate())),
    })
    const initPhoneNumbers = useAppSelector(state => state.userReducer.user.phoneList)

    const getPhoneNumbersArr = () => {
        const phonesArr: string[] | null = []
        initPhoneNumbers?.map((item : IPhoneNumber) => {
            if (item.phoneNumber != null) {
                phonesArr.push(item.phoneNumber)
            }
        })
        return phonesArr
    }
    const closeAndRedirect = () => {
        setIsModalOpen(false)
        props.setCurrent(0)
    }

    const sourcesOfWealthHandlerChange = (e: any, meta: any, form: any, name: string) => {
        form.setFieldValue("otherSourcesOfWealth", '');
        form.setFieldValue("isOtherSourcesOfWealth", false);
        const {checked} = e.target;
        if (checked) {
            form.setFieldValue("sourcesOfWealth", [...meta.value.sourcesOfWealth, name]);
        } else {
            const filteredArr = meta.value.sourcesOfWealth.filter((i: string) => i !== name)
            form.setFieldValue("sourcesOfWealth", filteredArr);
        }
    }

    const companyTypes = [
        'Sole Proprietorship',
        'Partnership',
        'Privately Owned Company (Limited Company)',
        'Publicly Listed Company (PLC)',
        'Government Owned Entity Trusts',
        'Foundations & Similar Entities',
        'Non-Government Organisations / Charities inc Religious bodies and place of worship',
        'Clubs and Societies',
        'GO (Majority Owned Subsidiary of State-Owned Company)',
        'Financial Institution',
    ]

    const natureOfBusinessArray = [
        'Personal',
        'Agriculture and Hunting',
        'Forestry',
        'Fishing',
        'Agricultural By-Products',
        'Coal Mining',
        'Oil Mining',
        'Iron Ore Mining',
        'Other Metal and Diamond Mining',
        'Other Mineral Mining',
        'Manufacture of Food/Drink/Tobacco',
        'Manufacture of Textiles/Leather/Fur/Furniture',
        'Manufacture of Wooden Products/Furniture',
        'Manufacture of Paper/Pulp/Allied Products',
        'Manufacture Of Chemicals Medical Petroleum Rubber Plastic Products',
        'Manufacture Of Pottery China Glass Stone',
        'Manufacture Of Iron Steel Non-Ferrous Metals Basic Industries',
        'Manufacture Of Metal Products Electrical And Scientific Engineering',
        'Manufacture Of Jewelry Musical Instruments Toys',
        'Electricity, Gas And Water',
        'Construction',
        'Wholesale Trade',
        'Retail Trade',
        'Catering Incl. Hotels',
        'Transport Storage',
        'Communications',
        'Finance And Holding Companies',
        'Insurance',
        'Business Services',
        'Real Estate Development Investment',
        'Central State Governments',
        'Community Services Defence Police Prisons Etc',
        'Social Services Education Health Care',
        'Personal Services - Leisure Services',
        'Personal Services - Domestic Laundry Repairs',
        'Personal Services - Embassies'
    ]

    const dateStyle = {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        padding: '17px 16px',
        height: 55,
        width: "100%",
        border: '1.5px solid #EEEFEF',
        borderRadius: 8
    }

    const dateFormat = 'YYYY-MM-DD';
    const getCountryCode = (countryName: string) => {
        const country = phonecodes.find((entry) => entry.na === countryName);
        return country?.co;
    };

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>

                <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                    <div className={s.flex_modal}>
                        <div className={s.modal_title}>
                            Success!
                        </div>
                        <div className={s.modal_subtitle}>
                            Your details will be sent for review.
                        </div>
                        <div className={s.button_block}>
                            <MyBtn title={"Ok"} onClick={() => closeAndRedirect()}/>
                        </div>
                    </div>
                </CustomModal>
                <CustomModal type="error" isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen}>
                    <div className={s.flex_column}>
                        <div className={s.modal_title}>
                            Oops!
                        </div>
                        <div className={s.modal_subtitle}>
                            {errorMessage}
                        </div>
                        <div className={s.button_block}>
                            <MyBtn buttonType="error" title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                        </div>
                    </div>
                </CustomModal>

                <div className={s.title}>
                    KYC for Business Accounts
                </div>

                <Formik
                    initialValues={{
                        companyName: '',
                        countryName: '',
                        city: '',
                        address: '',
                        email: '',
                        postalCode: '',
                        companyType: '',
                        natureOfBusiness: '',
                        registrationNumber: '',
                        financialRegulator: '',
                        regulatoryLicenseNumber: '',
                        phoneNumber: '',
                        currencies: [],
                        sourcesOfWealth: [],
                        isOtherSourcesOfWealth: false,
                        otherSourcesOfWealth: '',
                        files: {
                            accountApplication: '',
                            articlesMemorandums: '',
                            proofOfAddress: '',
                            registrationCertificate: '',
                            beneficiaryIdDocument: '',
                            beneficiaryIdcp: '',
                            beneficiaryProofOfAddress: '',
                        }
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {

                        const tempData = {
                            ...values,
                            registrationDate: date.from,
                            phoneNumber: values.phoneNumber,
                            country: getCountryCode(values.countryName),
                            files: {
                                accountApplication: '',
                                articlesMemorandums: '',
                                proofOfAddress: '',
                                registrationCertificate: '',
                                beneficiaryIdDocument: '',
                                beneficiaryIdcp: '',
                                beneficiaryProofOfAddress: '',
                            }
                        }

                        if (tempData.isOtherSourcesOfWealth) {
                            tempData.sourcesOfWealth = []
                            // @ts-ignore
                            tempData.sourcesOfWealth.push(tempData.otherSourcesOfWealth)
                        }

                        // @ts-ignore
                        delete tempData.otherSourcesOfWealth
                        // @ts-ignore
                        delete tempData.isOtherSourcesOfWealth

                        if (documents.registrationCertificate === '' ||
                            documents.proofOfAddress === '' ||
                            documents.beneficiaryIdcp === '' ||
                            documents.articlesMemorandums === '' ||
                            documents.beneficiaryProofOfAddress === '' ||
                            documents.beneficiaryIdDocument === '' ||
                            documents.accountApplication === ''
                        ) {
                            setDocsError(true)
                            return
                        }

                        tempData.files.accountApplication = await toBase64(documents.accountApplication) as string
                        tempData.files.articlesMemorandums = await toBase64(documents.articlesMemorandums) as string
                        tempData.files.proofOfAddress = await toBase64(documents.proofOfAddress) as string
                        tempData.files.registrationCertificate = await toBase64(documents.registrationCertificate) as string
                        tempData.files.beneficiaryIdDocument = await toBase64(documents.beneficiaryIdDocument) as string
                        tempData.files.beneficiaryIdcp = await toBase64(documents.beneficiaryIdcp) as string
                        tempData.files.beneficiaryProofOfAddress = await toBase64(documents.beneficiaryProofOfAddress) as string

                        if (documents.poA !== '') {
                            // @ts-ignore
                            tempData.files.poA = await toBase64(documents.poA) as string
                        }

                        if (documents.license !== '') {
                            // @ts-ignore
                            tempData.files.license = await toBase64(documents.license) as string
                        }


                        dispatch(sendPersonalKycThunk(token, tempData))
                            .then((res) => {
                                console.log(res)
                                if (res.data === "Created") {
                                    setIsModalOpen(true)
                                } else {
                                    setIsErrorModalOpen(true)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                if (error.code === "ERR_NETWORK" && !error.response) {
                                    setErrorMessage("Maximum files size is 17 MB")
                                    setIsErrorModalOpen(true)
                                }

                                if (error.response.data === "Invalid authorization data") {
                                    dispatch(setTokenState(false))
                                    return
                                }
                                if (error.response.data === "Too many phones. Max count is 5") {
                                    setErrorMessage(error.response.data)
                                    setIsErrorModalOpen(true)
                                    return
                                }
                                if (error.response.data === "Phone already exist") {
                                    setErrorMessage("This phone number already exists. Please try another phone number.")
                                    setIsErrorModalOpen(true)
                                    return
                                }
                            })
                            .finally(() => dispatch(setDisabled(false)))
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (

                        <form onSubmit={handleSubmit}>
                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Company name
                                    </div>
                                    <MyInput id='companyName'
                                             isError={errors.companyName}
                                             name='companyName'
                                             onChange={handleChange}
                                             value={values.companyName}
                                             touched={touched.companyName}
                                    />
                                    {errors.companyName &&
                                        <div className={s.error_message}>{errors.companyName}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Country
                                    </div>
                                    <SelectWithSearch
                                        id="countryName"
                                        name="countryName"
                                        isError={errors.countryName}
                                        items={countries}
                                    />
                                    {errors.countryName &&
                                        <div className={s.error_message}>{errors.countryName}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Phone number
                                    </div>
                                    <CustomDropdown
                                        items={getPhoneNumbersArr()}
                                        placeholder={"Choose your phone number"}
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        isError={errors.phoneNumber}
                                        touched={touched.phoneNumber}
                                    />
                                    {errors.phoneNumber &&
                                        <div className={s.error_message}>{errors.phoneNumber}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * city
                                    </div>
                                    <MyInput id='city'
                                             name='city'
                                             onChange={handleChange}
                                             value={values.city}
                                             isError={errors.city}
                                             touched={touched.city}
                                    />
                                    {errors.city &&
                                        <div className={s.error_message}>{errors.city}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * street (address)
                                    </div>
                                    <MyInput id='address'
                                             name='address'
                                             onChange={handleChange}
                                             value={values.address}
                                             isError={errors.address}
                                             touched={touched.address}
                                    />
                                    {errors.address &&
                                        <div className={s.error_message}>{errors.address}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * post code
                                    </div>
                                    <MyInput id='postalCode'
                                             name='postalCode'
                                             onChange={handleChange}
                                             value={values.postalCode}
                                             isError={errors.postalCode}
                                             touched={touched.postalCode}
                                    />
                                    {errors.postalCode &&
                                        <div className={s.error_message}>{errors.postalCode}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * email
                                    </div>
                                    <MyInput id='email'
                                             name='email'
                                             onChange={handleChange}
                                             value={values.email}
                                             isError={errors.email}
                                             touched={touched.email}
                                    />
                                    {errors.email &&
                                        <div className={s.error_message}>{errors.email}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * company type
                                    </div>
                                    <CustomDropdown
                                        items={companyTypes}
                                        placeholder={"Choose company type"}
                                        id="companyType"
                                        name="companyType"
                                        isError={errors.companyType}
                                        touched={touched.companyType}
                                    />
                                    {errors.companyType &&
                                        <div className={s.error_message}>{errors.companyType}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        registration date
                                    </div>
                                    <DatePicker suffixIcon={null} style={dateStyle}
                                                defaultValue={dayjs(new Date(), dateFormat)}
                                                value={dayjs(date.from)}
                                                onChange={(_, dateString) => {
                                                    if (!dateString) {
                                                        setDate({...date, from: new Date().toDateString()})
                                                    } else {
                                                        setDate({...date, from: dateString})
                                                    }
                                                }}
                                                format={dateFormat}/>
                                </div>
                            </div>

                            {
                                values.companyType === "Financial Institution" &&
                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * financial regulator
                                        </div>
                                        <MyInput id='financialRegulator'
                                                 name='financialRegulator'
                                                 onChange={handleChange}
                                                 value={values.financialRegulator}
                                                 isError={errors.financialRegulator}
                                                 touched={touched.financialRegulator}
                                        />
                                        {errors.financialRegulator &&
                                            <div className={s.error_message}>{errors.financialRegulator}</div>}
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * regulatory license number
                                        </div>
                                        <MyInput id='regulatoryLicenseNumber'
                                                 name='regulatoryLicenseNumber'
                                                 onChange={handleChange}
                                                 value={values.regulatoryLicenseNumber}
                                                 isError={errors.regulatoryLicenseNumber}
                                                 touched={touched.regulatoryLicenseNumber}
                                        />
                                        {errors.regulatoryLicenseNumber &&
                                            <div className={s.error_message}>{errors.regulatoryLicenseNumber}</div>}
                                    </div>
                                </div>
                            }

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        Registration Number
                                    </div>
                                    <MyInput id='registrationNumber'
                                             name='registrationNumber'
                                             onChange={handleChange}
                                             value={values.registrationNumber}
                                             isError={errors.registrationNumber}
                                             touched={touched.registrationNumber}
                                    />
                                    {errors.registrationNumber &&
                                        <div className={s.error_message}>{errors.registrationNumber}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        Nature of Business
                                    </div>
                                    <CustomDropdown
                                        items={natureOfBusinessArray}
                                        placeholder={"Choose nature of business"}
                                        id="natureOfBusiness"
                                        name="natureOfBusiness"
                                        isError={errors.natureOfBusiness}
                                        touched={touched.natureOfBusiness}
                                    />
                                    {errors.natureOfBusiness &&
                                        <div className={s.error_message}>{errors.natureOfBusiness}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={classnames(s.input_block)}>
                                    <div className={s.input_label}>
                                        * Sources of wealth or income
                                    </div>
                                    <div role="group" aria-labelledby="checkbox-group"
                                         className={s.sources_of_wealth_wrapper}>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Inheritances")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Inheritances")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Inheritances</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Investments")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Investments")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Investments</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Business ownership interests")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Business ownership interests")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Business ownership interests</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Employment income")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Employment income")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Employment income</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Personal savings")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Personal savings")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Personal savings</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Pension releases")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Pension releases")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Pension releases</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Share sales and dividends")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Share sales and dividends")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Share sales and dividends</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Property sales")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Property sales")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Property sales</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Gambling winnings")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Gambling winnings")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Gambling winnings</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Inheritances and gifts")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Inheritances and gifts")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Inheritances and gifts</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Compensation from legal rulings")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Compensation from legal rulings")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Compensation from legal rulings</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.isOtherSourcesOfWealth}
                                                            onChange={(e) => {
                                                                const {checked} = e.target;
                                                                if (checked) {
                                                                    props.form.setFieldValue("sourcesOfWealth", []);
                                                                }
                                                                props.form.setFieldValue(
                                                                    "isOtherSourcesOfWealth",
                                                                    checked
                                                                );
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Other</span>
                                        </label>
                                    </div>
                                    {errors.sourcesOfWealth && values.sourcesOfWealth.length === 0 &&
                                        <div className={s.error_message}>{errors.sourcesOfWealth}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                {
                                    // @ts-ignore
                                    values.isOtherSourcesOfWealth &&
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * Other
                                        </div>
                                        <MyInput id='otherSourcesOfWealth'
                                                 name='otherSourcesOfWealth'
                                                 onChange={handleChange}
                                                 value={values.otherSourcesOfWealth}
                                                 isError={errors.otherSourcesOfWealth}
                                                 touched={touched.otherSourcesOfWealth}
                                        />
                                        {errors.otherSourcesOfWealth &&
                                            <div className={s.error_message}>{errors.otherSourcesOfWealth}</div>}
                                    </div>
                                }
                                <div className={s.input_block}>
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={classnames(s.input_block)}>
                                    <div className={classnames(s.input_label, errors.currencies && s.doc_error)}
                                         id="checkbox-group">
                                        * Add a new account
                                    </div>
                                    <div role="group" aria-labelledby="checkbox-group" className={s.checkbox_wrapper}>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="CAD"/>
                                            <span className={s.checkbox_label}>CAD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="EUR"/>
                                            <span className={s.checkbox_label}>EUR</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="GBP"/>
                                            <span className={s.checkbox_label}>GBP</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="USD"/>
                                            <span className={s.checkbox_label}>USD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="USDT"/>
                                            <span className={s.checkbox_label}>USDT</span>
                                        </label>
                                    </div>
                                    {errors.currencies &&
                                        <div className={s.error_message}>{errors.currencies}</div>}
                                </div>
                            </div>


                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={legal_account_application} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.accountApplication === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Legal Account Application
                                        </div>
                                        <div>
                                            <div className={s.passport_spread_list}>
                                                <div>
                                        <span className={classnames(s.flex, s.text_color)}>
                                           1. <img src={download_legal} alt=""/><a
                                            href="/Legal_Account_Application_form.pdf" target="_blank"
                                            className={s.download_link}>Download legal account application</a>
                                        </span>
                                                </div>
                                                <div>
                                        <span className={s.text_color}>
                                             2. Fill in relevant information.
                                        </span>
                                                </div>
                                                <div>
                                        <span className={s.text_color}>
                                            3. Upload filled and signed legal account application form.
                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('accountApplication').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"accountApplication"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                        // @ts-ignore
                                                       accountApplication: event.currentTarget.files[0]
                                                   });
                                               }}
                                        />

                                    </div>
                                    <div className={classnames(s.attached_file)}>
                                        {
                                            documents?.accountApplication?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.accountApplication?.name}
                                        {
                                            documents?.accountApplication?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("accountApplication").value = ""
                                                setDocuments({...documents, accountApplication: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={proofOfAddress} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.proofOfAddress === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Company Proof of Address
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Acceptable documents are:
                                        </div>
                                        <ul className={s.passport_spread_list}>
                                            <li>
                                        <span className={s.text_color}>
                                            Bank statement with the date of issue and the name of the person (the document
                                            must be not older than 3 months);
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Utility bill for gas, electricity, water, internet, etc. linked to the property
                                            (the document must not be older than 3 months).
                                        </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('proofOfAddress').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"proofOfAddress"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       proofOfAddress: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.proofOfAddress?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.proofOfAddress?.name}
                                        {
                                            documents?.proofOfAddress?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("proofOfAddress").value = ""
                                                setDocuments({...documents, proofOfAddress: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={companyCharter} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.articlesMemorandums === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Articles & Memorandums of Association
                                        </div>
                                        <div className={s.add_block_text}>
                                            Upload company Charter/Statute/Memorandum/Articles of Association/Memorandum
                                            of
                                            Association
                                            or any other relevant statutory documents.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('articlesMemorandums').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"articlesMemorandums"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       articlesMemorandums: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.articlesMemorandums?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.articlesMemorandums?.name}
                                        {
                                            documents?.articlesMemorandums?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("articlesMemorandums").value = ""
                                                setDocuments({...documents, articlesMemorandums: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={registrationSertificate} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.registrationCertificate === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Registration Certificate
                                        </div>
                                        <div className={s.add_block_text}>
                                            Upload Certificate of Incorporation or Certificate of enterprise
                                            registration.
                                        </div>
                                        {/*<div className={s.add_block_text}>*/}
                                        {/*    You can paste a link to government website with company information.*/}
                                        {/*</div>*/}
                                        {/*<div className={s.input_block}>*/}
                                        {/*    <div className={s.input_label}>*/}
                                        {/*        Link*/}
                                        {/*    </div>*/}
                                        {/*    <MyInput/>*/}
                                        {/*</div>*/}

                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('registrationCertificate').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"registrationCertificate"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       registrationCertificate: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.registrationCertificate?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.registrationCertificate?.name}
                                        {
                                            documents?.registrationCertificate?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("registrationCertificate").value = ""
                                                setDocuments({...documents, registrationCertificate: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={beneficiaryIdDoc} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.beneficiaryIdDocument === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Beneficiary Identity document (Passport, ID Card, Driving license)
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Good photo tips:
                                        </div>
                                        <ul className={s.passport_spread_list}>
                                            <li>
                                        <span className={s.text_color}>
                                            A color photo contains full document in clear focus and the data can be read easily.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             There're no light glares impeding document readability.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             A photo was not edited in graphic applications (we will definitely check).
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Picture format: JPEG, JPG, PNG, PDF.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Maximum file size: 8Mb.
                                        </span>
                                            </li>
                                            <div className={s.attention}>
                                                Please upload Identity document (Passport, ID Card, Driving license)
                                                photos
                                                of each
                                                Shareholder with more than 25% ownership, all Board Members and
                                                Directors.
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('beneficiaryIdDocument').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf, .png, .jpg, .jpeg, .pdf, .heic"
                                               id={"beneficiaryIdDocument"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       beneficiaryIdDocument: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.beneficiaryIdDocument?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.beneficiaryIdDocument?.name}
                                        {
                                            documents?.beneficiaryIdDocument?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("beneficiaryIdDocument").value = ""
                                                setDocuments({...documents, beneficiaryIdDocument: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={beneficiaryIdcp} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.beneficiaryIdcp === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Beneficiary IDCP
                                        </div>
                                        <div className={s.add_block_text} style={{marginBottom: '16px'}}>
                                            Upload an ID confirmation photo (selfie of the Beneficiary holding in his
                                            hands
                                            his ID
                                            and a paper with written name of platform "Iberbanco", date (dd/mm/yyyy/)
                                            and
                                            Beneficiary's
                                            signature)
                                        </div>
                                        <div className={s.attention}>
                                            Please upload Identity document (Passport, ID Card, Driving license) photos
                                            of
                                            each
                                            Shareholder with more than 25% ownership, all Board Members and Directors.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('beneficiaryIdcp').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept=".png, .jpg, .jpeg, .pdf, .heic, application/pdf"
                                               id={"beneficiaryIdcp"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       beneficiaryIdcp: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.beneficiaryIdcp?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.beneficiaryIdcp?.name}
                                        {
                                            documents?.beneficiaryIdcp?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("beneficiaryIdcp").value = ""
                                                setDocuments({...documents, beneficiaryIdcp: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={BeneficiaryProofOfAddress} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.beneficiaryProofOfAddress === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Beneficiary Proof of Address
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Acceptable documents are:
                                        </div>
                                        <ul className={s.passport_spread_list}>
                                            <li>
                                        <span className={s.text_color}>
                                            Bank statement with the date of issue and the name of the person (the document
                                            must be not older than 3 months);
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Utility bill for gas, electricity, water, internet, etc. linked to the
                                            property (the document must not be older than 3 months).
                                        </span>
                                            </li>
                                        </ul>
                                        {/*<div className={s.attention}>*/}
                                        {/*    Please upload Identity document (Passport, ID Card, Driving license) photos*/}
                                        {/*    of*/}
                                        {/*    each*/}
                                        {/*    Shareholder with more than 25% ownership, all Board Members and Directors.*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('beneficiaryProofOfAddress').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"beneficiaryProofOfAddress"}
                                               className={s.hidden_input}
                                               onChange={(event) => {

                                                   setDocuments({
                                                       ...documents,
                                                       // @ts-ignore
                                                       beneficiaryProofOfAddress: event.currentTarget.files[0]
                                                   })
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.beneficiaryProofOfAddress?.name &&
                                            <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.beneficiaryProofOfAddress?.name}
                                        {
                                            documents?.beneficiaryProofOfAddress?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("beneficiaryProofOfAddress").value = ""
                                                setDocuments({...documents, beneficiaryProofOfAddress: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={Poa} alt=""/>
                                    </div>
                                    <div>
                                        <div className={s.add_doc_title}>
                                            PoA (If applicable)
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            If the company is represented by a person not authorized to do so by the
                                            company’s
                                            Articles of Association or equivalent document, please upload a Power of
                                            Attorney
                                            asserting the right of this person to represent company's interests.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('poA').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"poA"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setDocuments({...documents, poA: event.currentTarget.files[0]})
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.poA?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.poA?.name}
                                        {
                                            documents?.poA?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("poA").value = ""
                                                setDocuments({...documents, poA: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={license} alt=""/>
                                    </div>
                                    <div>
                                        <div className={s.add_doc_title}>
                                            Licence (If applicable)
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            If your business requires a license in order to operate, please upload it.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('license').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"license"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setDocuments({...documents, license: event.currentTarget.files[0]})
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>
                                        {
                                            documents?.license?.name && <img src={attachedDoc} alt=""/>
                                        }
                                        {documents?.license?.name}
                                        {
                                            documents?.license?.name &&
                                            <img onClick={() => {
                                                // @ts-ignore
                                                document.getElementById("license").value = ""
                                                setDocuments({...documents, license: ""})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.required_fields}>
                                * required fields.
                            </div>

                            <div className={s.btn_block}>
                                <div className={s.button_back_wrapper}>
                                    <TransparentButton
                                        large={true}
                                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                        title={"Back"}
                                        type={"button"}
                                        onClick={() => props.setCurrent(0)}/>
                                </div>
                                <div className={s.button_apply_wrapper}>
                                    <MyBtn title={'Apply'} type="submit" onClick={() => console.log(errors)}/>
                                </div>
                            </div>

                            <FinancialInstitutionHelper/>

                        </form>
                    )}
                </Formik>
            </div>
        </motion.div>
    );
};

export default KycPersonalStepTwo;
