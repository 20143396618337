import React from 'react';
import streamlined from "../../../../assets/newImg/publicPages/services/moneyOrders/streamlined.svg";
import fast from "../../../../assets/newImg/publicPages/services/moneyOrders/fast.svg";
import reliable from "../../../../assets/newImg/publicPages/services/moneyOrders/reliable.svg";
import styles from "../../../../styles/styles.module.css";
import ServicesHeaderBlock from "../../../../components/ServicesHeaderBlock/ServicesHeaderBlock";
import imageSrc from "../../../../assets/newImg/publicPages/services/moneyOrders/money_orders.svg";
import s from "./MoneyOrders.module.css";
import SettingsCard from "../../../../components/SettingsCard/SettingsCard";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import support from "../../../../assets/newImg/publicPages/services/moneyOrders/help_money_orders.svg";
import ContactUsForm from "../../../../components/ContactUsForm/ContactUsForm";
import {useNavigate} from "react-router-dom";
import {motion} from 'framer-motion';

const MoneyOrders = () => {

    const title = "Effortless Money Orders"
    const navigate = useNavigate()
    const cards = [
        {
            icon: streamlined,
            title: 'Streamlined Process:',
            text: 'Sending money orders shouldn\'t be a complicated task. That\'s why we have designed a streamlined ' +
                'process that allows you to request and receive money orders effortlessly. Our user-friendly platform ' +
                'ensures a seamless experience from start to finish, saving you time and effort.'
        },
        {
            icon: fast,
            title: 'Fast and Secure Delivery:',
            text: 'We prioritize the timely and secure delivery of your money orders. With our trusted network of partners ' +
                'and efficient handling processes, you can rest assured that your money orders will be delivered promptly ' +
                'and securely to the intended recipients. Experience peace of mind knowing that your funds are in safe hands.'
        },
        {
            icon: reliable,
            title: 'Reliable Tracking and Support:',
            text: 'We believe in providing you with transparency and peace of mind throughout the money order journey. \n' +
                'Our reliable tracking system allows you to monitor the status of your money orders, ensuring you stay ' +
                'informed every step of the way. In addition, our dedicated support team is available to address any ' +
                'inquiries or concerns you may have, providing you with the assistance you need.'
        },
    ]

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={styles.container}>
            <div className={styles.wrapper}>

                <ServicesHeaderBlock title={title} imageSrc={imageSrc}/>

                <div className={s.page_description}>
                    At CompanyName, we understand the importance of convenient and reliable money orders. Experience our
                    comprehensive suite of services and embark on a rewarding journey in the world of digital assets.
                </div>

                <div className={s.cards_block}>
                    {
                        cards.map((card) => {
                            return (
                                <SettingsCard icon={card.icon} title={card.title} text={card.text}/>
                            )
                        })
                    }
                </div>

                <div className={s.button_wrapper}>
                    <div className={s.button_block}>
                        <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                    </div>
                </div>

                <div className={s.support_wrapper}>
                    <div className={s.container_support}>
                        <div>
                            <div className={s.support_subtitle}>
                                Competitive rates, expert assistance, efficiency and reliability
                            </div>
                            <div className={s.support_title}>
                                We are committed to <span className={s.underline}> exceeding </span> your expectations
                            </div>
                            <div className={s.support_text}>
                                Our goal is to provide you with an effortless money order service that meets your financial
                                needs with ease and efficiency.
                            </div>
                        </div>
                        <div className={s.img}>
                            <img src={support} alt=""/>
                        </div>
                    </div>

                    <div className={s.support_text_mobile}>
                        Our goal is to provide you with an effortless money order service that meets your financial
                        needs with ease and efficiency.
                    </div>

                </div>

                <div className={s.contact_us_form_wrapper}>
                    <ContactUsForm/>
                </div>

            </div>
        </div>
        </motion.div>
    );
};

export default MoneyOrders;
