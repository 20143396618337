import {BankCode} from "../../../../types/interfaces";
import {
    bankAccountTypesTED,
    bankAccountTypesUS,
    bankCodeListEGPBank,
    bankCodeListGhanaGHSBank,
    bankCodeListKESBank,
    bankCodeListNgnBank,
    bankCodeListNigeriaUSDBank,
    bankCodeListTEDPayments,
    bankCodeListZARBank,
    branchCodeListBWPBank,
    branchCodeListUGXBank,
    branchCodeListZMWBank, idTypesKenya, idTypesMorocco,
    mobileProvidersBeninXOF,
    mobileProvidersBFXOF,
    mobileProvidersGhana,
    mobileProvidersGuinea,
    mobileProvidersIvoryCoastXOF, mobileProvidersKenya,
    mobileProvidersSenegalXOF,
    mobileProvidersTogoXOF,
    mobileProvidersUganda,
    mobileProvidersXAF,
    otherBank, transferReasons,
    xofCashCountries,
    xofMobileCountries
} from "../../../../Constants/StaticData";
import {getMobileProvider} from "../../../BankTransferFormPersonal/FormHelpers/FormHelpers";

const bankCodeListMapping: Record<string, BankCode[]> = {
    "NGN::Bank": bankCodeListNgnBank,
    "NigeriaUSD::Bank": bankCodeListNigeriaUSDBank,
    "GHS::Bank": bankCodeListGhanaGHSBank,
    "ZAR::Bank":bankCodeListZARBank,
    "TED Payments":bankCodeListTEDPayments,
    "KES::Bank":bankCodeListKESBank,
    "EGP::Bank":bankCodeListEGPBank
};
export const getBankCode = (paymentMethod: string | undefined, value: string | undefined) => {
    const bankList = bankCodeListMapping[paymentMethod || ''] || [];
    return bankList.find((bankCode) => bankCode.value === value) || otherBank;
};

const branchCodeListMapping: Record<string, BankCode[]> = {
    "UGX::Bank": branchCodeListUGXBank,
    "BWP::Bank":branchCodeListBWPBank,
    "ZMW::Bank":branchCodeListZMWBank
};
export const getBranchCode = (paymentMethod: string | undefined, value: string | undefined) => {
    const branchList = branchCodeListMapping[paymentMethod || ''] || [];
    return branchList.find((bankCode) => bankCode.value === value) || otherBank;
};

const accountTypeListMapping: Record<string, BankCode[]> = {
    "USD::Bank":bankAccountTypesUS,
    "TED Payments":bankAccountTypesTED
};
export const getAccountType = (paymentMethod: string | undefined, value: string | undefined) => {
    const typeList = accountTypeListMapping[paymentMethod || ''] || [];
    return typeList.find((bankCode) => bankCode.value === value) || otherBank;
};

const recipientCountryMapping: Record<string, BankCode[]> = {
    "XOF::Cash":xofCashCountries,
    "XOF::Mobile":xofMobileCountries
};
export const getRecipientCountry = (paymentMethod: string | undefined, value: string | undefined) => {
    const list = recipientCountryMapping[paymentMethod || ''] || [];
    return list.find((bankCode) => bankCode.value === value) || otherBank;
};


const mobileProviderMapping: Record<string, BankCode[]> = {
    "GHS::Mobile":mobileProvidersGhana,
    "UGX::Mobile":mobileProvidersUganda,
    "XAF::Mobile":mobileProvidersXAF,
    "GNF::Mobile":mobileProvidersGuinea,
    "KES::Mobile":mobileProvidersKenya,
};

export const getProvider = (paymentMethod: string | undefined, value: string | undefined, country: string | undefined) => {
    const mobileProviders: Record<string, BankCode[]> = {
        "Benin": mobileProvidersBeninXOF,
        "Burkina Faso": mobileProvidersBFXOF,
        "Ivory Coast": mobileProvidersIvoryCoastXOF,
        "Senegal": mobileProvidersSenegalXOF,
        "Togo": mobileProvidersTogoXOF,
    };

    const list: BankCode[] = paymentMethod === "XOF::Mobile" ? mobileProviders[country || ''] || [] : recipientCountryMapping[paymentMethod || ''] || [];

    return list.find((bankCode) => bankCode.value === value) || otherBank;
};

export const getTransferReason = (value: string | undefined) => {
    return transferReasons.find((bankCode) => bankCode.value === value) || otherBank;
};

const idTypeMapping: Record<string, BankCode[]> = {
    "MAD::Cash":idTypesMorocco,
    "KES::Bank":idTypesKenya,
    "KES::Mobile":idTypesKenya
};
export const getIdType = (paymentMethod: string | undefined, value: string | undefined) => {
    const idList = idTypeMapping[paymentMethod || ''] || [];
    return idList.find((bankCode) => bankCode.value === value) || otherBank;
};