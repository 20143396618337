import React, {useState} from 'react';
import s from "../BankTransfer/BankTransfer.module.css";
import ToCompanyNameStepOne from "./ToCompanyNameStepOne/ToCompanyNameStepOne";
import ToCompanyNameStepTwo from "./ToCompanyNameStepTwo/ToCompanyNameStepTwo";

const ToCompanyName = () => {
    const [current, setCurrent] = useState(0);
    const [ourTransferData, setOurTransferData] = useState({})

    const steps = [
        {
            content: <ToCompanyNameStepOne setCurrent={setCurrent}
                                           setTransferData={setOurTransferData}
            />
        },
        {
            content: <ToCompanyNameStepTwo setCurrent={setCurrent}
                                           current={current}
                                           transferData={ourTransferData}
            />
        },
    ];

    return (
        <>
            <div className={s.wrapper}>
                {steps[current].content}
            </div>
            <div className={s.description_wrapper}>
                <p>
                    Internal transfers are possible in any currency. All internal transfers reach the recipient within a
                    minute.
                </p>
                <p>
                    If the specified recipient is incorrect, the money will automatically be refunded to your account
                    within 30 days or, in case you initiate a refund, in 3 days.
                </p>
            </div>
        </>

    );
};

export default ToCompanyName;
