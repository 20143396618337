import React, {useEffect, useState} from 'react';
import s from "./CardSettings.module.css";
import {ReactComponent as FailIcon} from '../../../assets/newImg/personalAccount/fail.svg';
import {ReactComponent as Back} from "../../../assets/newImg/personalAccount/back.svg";
import MyBtn from "../../ui/MyBtn/MyBtn";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import CustomModal from "../../ui/CustomModal/CustomModal";
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {setDisabled} from "../../../store/reducers/UserSlice";
import {
    activatePhysicalCard, getCardInfo, getPhoneListThunk, sendCardTopUp, suspendCard, updateCard
} from "../../../store/reducers/ActionCreators";
import {Formik} from "formik";
import CustomDropdownForAccounts from "../../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import MyInput from "../../ui/MyInput/MyInput";
import MyTextarea from "../../ui/MyTextarea/MyTextarea";
import CustomDropdown from "../../ui/CustomDropdown/CustomDropdown";
import {IPhoneNumber} from "../../../types/interfaces";

const CardSettings = () => {

    const cardInfo = useAppSelector(store => store.userReducer.cardInfo)
    const [showModal, setShowModal] = useState(false)
    const [showContactModal, setShowContactModal] = useState(false)
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [successUpdateModalOpen, setSuccessUpdateModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

    const dispatch = useAppDispatch();
    const token = useAppSelector(shippingCountyState => shippingCountyState.userReducer.user.userToken)

    const phoneList = useAppSelector(state => state.userReducer.user.phoneList);

    useEffect(() => {
        dispatch(getPhoneListThunk(token));
    }, [dispatch]);

    const phoneNumbers = phoneList?.filter(phone => phone.isVerify)?.map(phone => `+${phone.phoneNumber}`) || [];

    const handleSuspendCard = () => {
        const dataForTransaction = {
            san: cardInfo.san,
        };
        dispatch(setDisabled(true))
        dispatch(suspendCard(token, dataForTransaction))
            .then(response => {
                dispatch(getCardInfo(token));
                setShowModal(false)
                setIsSuccessModalOpen(true)
            })
            .catch(error => {
                console.error('Error suspending card:', error);
                setShowModal(false);
                setIsErrorModalOpen(true)
            })
            .finally(() => {
                dispatch(setDisabled(false))
            })
    };
    return (<>
        <CustomModal type={'confirm'} setIsModalOpen={setShowModal} isModalOpen={showModal}
                     children={<div className={s.modalContent}>

                         <h3 className={s.modalTitle}>Are you sure?</h3>
                         <h4 className={s.modalSubtitle}>Suspending your card is reversible.
                             You can reactivate it when needed by contacting customer support.</h4>

                         <div className={s.modalButtonsWrapper}>
                             <div className={s.modalButtonBlock}>
                                 <TransparentButton buttonType={'confirm'} medium title={'Cancel'}
                                                    icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                                    onClick={() => {
                                                        setShowModal(false)
                                                    }}/>
                             </div>
                             <div className={s.buttonBlock}>
                                 <MyBtn buttonType={'confirm'} medium style={{width: 180}} onClick={() => {
                                     handleSuspendCard()
                                 }} title={'Confirm'}/>
                             </div>

                         </div>
                     </div>}/>

        <CustomModal setIsModalOpen={setIsSuccessModalOpen} isModalOpen={isSuccessModalOpen}>
            <div>
                <div className={s.modalTitle}>Success!</div>
                <h4 className={s.modalSubtitle}> Your card has been suspended.</h4>
                <div className={s.modalButtonsWrapper}>
                    <div className={s.modalButtonBlock}>
                        <MyBtn title={"Ok"} onClick={() => setIsSuccessModalOpen(false)}/>
                    </div>
                </div>
            </div>
        </CustomModal>

        <CustomModal setIsModalOpen={setSuccessUpdateModalOpen} isModalOpen={successUpdateModalOpen}>
            <div>
                <div className={s.modalTitle}>Success!</div>
                <h4 className={s.modalSubtitle}> Your card details have been updated.</h4>
                <div className={s.modalButtonsWrapper}>
                    <div className={s.modalButtonBlock}>
                        <MyBtn title={"Ok"} onClick={() => setSuccessUpdateModalOpen(false)}/>
                    </div>
                </div>
            </div>
        </CustomModal>

        <CustomModal setIsModalOpen={setIsErrorModalOpen} isModalOpen={isErrorModalOpen} type={"error"}>
            <div className={s.popup_title}>
                Oops!
            </div>
            <div className={s.popup_subtitle}>
                Something went wrong. Please try again later.
            </div>
            <div className={s.popup_button_wrapper}>
                <div className={s.btn_block}>
                    <MyBtn buttonType={"error"} title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                </div>
            </div>
        </CustomModal>

        <CustomModal isModalOpen={showContactModal} setIsModalOpen={setShowContactModal} >
            <Formik initialValues={{
                phone: cardInfo.phone, email: cardInfo.email, san: cardInfo.san
            }}
                   /* validationSchema={validationSchema}*/
                    onSubmit={(values) => {
                        dispatch(setDisabled(true))
                        dispatch(updateCard(token, values))
                            .then((res) => {
                                if (res.data) {
                                    dispatch(getCardInfo(token));
                                    setShowContactModal(false);
                                    setSuccessUpdateModalOpen(true);
                                } else {

                                    throw new Error("Unexpected response from the server");
                                }
                            })
                            .catch((error) => {
                                console.error('Error occurred:', error);
                                setShowContactModal(false);
                                setIsErrorModalOpen(true)
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })
                    }}>

                {({
                      values, errors, touched, handleChange, handleSubmit
                  }) => <form onSubmit={handleSubmit}>
                    <div className={s.modalForm}>
                        <div className={s.modalTitle}>
                            Edit your details!
                        </div>
                            <span className={s.modalSubtitle}>Phone Number</span>
                        <div className={s.modalBlock}>
                            <CustomDropdown
                                items={phoneNumbers}
                                id={"phone"}
                                name={"phone"}
                                isError={errors.phone}
                                touched={touched.phone}/>
                            {errors.phone && touched.phone && <div className={s.error}>{errors.phone}</div>}
                        </div>
                            <span className={s.modalSubtitle}>Email Address</span>
                            <div className={s.modalBlock}>
                                    <MyInput
                                        name={"email"}
                                        id={"email"}
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                    {touched.email && errors.email &&
                                        <div className={s.error_message}>{errors.email}</div>}
                        </div>

                    </div>
                    <div className={s.modalButtonsWrapper}>
                        <MyBtn
                            large
                            style={{width: 180}}
                            title={"Save"}
                        />
                        <TransparentButton
                            title={"Cancel"}
                            style={{width: 180}}
                            large
                            icon={<Icon component={() => <Back/>} rev={undefined}/>}
                            onClick={() => setShowContactModal(false)}
                        />

                    </div>

                </form>}
            </Formik>
        </CustomModal>


        <div className={s.wrapper}>
            <div className={s.block}>
                <div className={s.title}>Card Actions</div>
                <div className={s.row}>
                    <div className={s.buttonWrapper}>
                        <FailIcon fill='#2046A1'/>
                        <div
                            className={s.rowTitle}>SUSPEND THE CARD<br/>
                            <div
                                className={s.rowSpan}>Deactivation of the card with the possibility of reactivation</div>
                        </div>

                    </div>
                    <div className={s.buttonsWrapper}>
                        <MyBtn
                            style={{width: 100, height: 34}}
                            title={"Suspend"}
                            onClick={() => setShowModal(true)}
                            localDisable={cardInfo.status === 3}
                        />
                    </div>
                </div>
            </div>
            <div className={s.line}></div>
            <div className={s.block}>
                <div className={s.title}>Card Details</div>
                <div className={s.contactRow}>
                    <div className={s.contactTitle}>
                        Phone Number
                    </div>
                    <div className={s.contactTitle}>
                        Email Address
                    </div>
                </div>
                <div className={s.contactRow}>
                    <div className={s.contact}>
                        {cardInfo.phone}
                    </div>
                    <div className={s.contact}>
                        {cardInfo.email}
                    </div>
                </div>
                <div className={s.row} style={{paddingBottom: 24}}>
                <div className={s.buttonWrapper}>

                </div>
                <div className={s.buttonsWrapper}>
                    <MyBtn
                        style={{width: 100, height: 34}}
                        title={"Change"}
                        onClick={() => setShowContactModal(true)}
                    />
                </div>
                </div>
            </div>

        </div>
    </>);
};

export default CardSettings;