import React, {useEffect} from 'react';
import s from './ExchangeRates.module.css'
import {motion} from "framer-motion";
import {Formik, useFormikContext} from "formik";
import MyInput from "../../../../components/ui/MyInput/MyInput";
import CustomDropdown from "../../../../components/ui/CustomDropdown/CustomDropdown";
import TransparentButton from "../../../../components/ui/TransparentButton/TransparentButton";
import Table from "antd/es/table";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {
    setCurrencyExchangeAmount,
    setCurrencyExchangeFrom, setCurrencyExchangeResult,
    setCurrencyExchangeTo
} from "../../../../store/reducers/UserSlice";
import {sendCurrencyExchange} from "../../../../store/reducers/ActionCreators";
import useDebounce from "../../../../hooks/useDebounce";


const columns = [
    {
        title: 'official rate (1 CAD)',
        dataIndex: 'officialRate',
        className: `${s.tableCell}`,
        align: 'left'
    },
    {
        title: 'Iberbanco',
        dataIndex: 'Iberbanco',
        align: 'right',
        className: `${s.iberbancoCell}`,
    },
    /*{
        title: 'RBC',
        dataIndex: 'RBC',
        align: 'right'
    },
    {
        title: 'Scotiaban',
        dataIndex: 'Scotiaban',
        align: 'right'
    },
    {
        title: 'TD Bank',
        dataIndex: 'TDBank',
        align: 'right'
    },*/
    Table.EXPAND_COLUMN,
];


const SetCurrencyByAccount = (props: any) => {

    const {values} = useFormikContext();

    const dispatch = useAppDispatch()

    useEffect(() => {
        // @ts-ignore
        dispatch(setCurrencyExchangeFrom(values.sellCurrency))
        // @ts-ignore
    }, [values.sellCurrency])

    useEffect(() => {
        // @ts-ignore
        dispatch(setCurrencyExchangeTo(values.buyCurrency))
        // @ts-ignore
    }, [values.buyCurrency])

    useEffect(() => {
        // @ts-ignore
        if (!!values.sellAmount && values?.sellAmount?.length > 0) {
            // @ts-ignore
            // @ts-ignore
            dispatch(setCurrencyExchangeFrom(values.sellCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeTo(values.buyCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeAmount(values.sellAmount))
        }

        // @ts-ignore
        if (!!values.buyAmount && values?.buyAmount?.length > 0) {
            // @ts-ignore
            dispatch(setCurrencyExchangeFrom(values.buyCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeTo(values.sellCurrency))
            // @ts-ignore
            dispatch(setCurrencyExchangeAmount(values.buyAmount))
        }

    }, [values])

    useEffect(() => {
        // @ts-ignore
        if (values.sellAmount === '') {
            dispatch(setCurrencyExchangeResult(null))
            // @ts-ignore
            dispatch(setCurrencyExchangeAmount(null))
        }
        // @ts-ignore
    }, [values.sellAmount])

    useEffect(() => {
        // @ts-ignore
        if (values.buyAmount === '') {
            dispatch(setCurrencyExchangeResult(null))
            // @ts-ignore
            dispatch(setCurrencyExchangeAmount(null))
        }
        // @ts-ignore
    }, [values.buyAmount])

    return null;
};

const ExchangeRates = () => {

    const currencyExchange = useAppSelector(state => state.userReducer.currencyExchange)
    const currencyExchangeResult = useAppSelector(state => state.userReducer.currencyExchangeResult)
    const dispatch = useAppDispatch()

    const handleRequest = (debouncedValue: any) => {
        if (!currencyExchange.amount || currencyExchange.amount === 0 || currencyExchange.amount === '') return
        dispatch(sendCurrencyExchange(debouncedValue))
    }

    const debouncedValue = useDebounce(currencyExchange, 1500);

    useEffect(() => {
        handleRequest(debouncedValue)
    }, [debouncedValue])

    const currencyList = [
        'USD',
        'EUR',
        'CAD',
        'GBP',
        'USDT',
    ]

    const currencyData = [
        {
            officialRate: '0.219954',
            Iberbanco: '0.22',
            RBC: '0.22',
            Scotiaban: '0.22',
            TDBank: '0.22',
            currency: 'USD'
        },
        {
            officialRate: '0.482',
            Iberbanco: '0.48',
            RBC: '0.48',
            Scotiaban: '0.48',
            TDBank: '0.48',
            currency: 'EUR'
        },
        {
            officialRate: '0.85468',
            Iberbanco: '0.85',
            RBC: '0.85',
            Scotiaban: '0.85',
            TDBank: '0.85',
            currency: 'DKK'
        },
        {
            officialRate: '0.219954',
            Iberbanco: '0.22',
            RBC: '0.22',
            Scotiaban: '0.22',
            TDBank: '0.22',
            currency: 'NOK'
        },
        {
            officialRate: '0.219954',
            Iberbanco: '0.22',
            RBC: '0.22',
            Scotiaban: '0.22',
            TDBank: '0.22',
            currency: 'PLN'
        },
        {
            officialRate: '0.219954',
            Iberbanco: '0.22',
            RBC: '0.22',
            Scotiaban: '0.22',
            TDBank: '0.22',
            currency: 'RUB'
        },
    ]

    const tableData = currencyData.map((item: any, index: number) => {
        return {
            key: item.index,
            officialRate: item.officialRate + ' ' + item.currency,
            Iberbanco: item.Iberbanco + ' ' + item.currency,
            RBC: item.RBC + ' ' + item.currency,
            Scotiaban: item.Scotiaban + ' ' + item.currency,
            TDBank: item.TDBank + ' ' + item.currency,
        }
    })

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.desc_block}>
                <p className={s.desc_text}>
                    Iberbanco offer the same or even better currency exchange rates than those officially published on
                    the Internet by any commercial bank. This saves you money not only when performing a transfer, but
                    also when buying currency for a specific transfer. If the amount exchanged exceeds <b>5,000 EUR</b> we can
                    offer individual currency rates to private and business clients of Iberbanco on request.
                </p>
                <p className={s.desc_text}>
                    Below in the currency calculator you can check the exchange rates offered by Iberbanco.
                </p>
                <p>
                    <a className={s.link} href="https://www.bankofcanada.ca/">www.bankofcanada.ca</a>
                </p>
            </div>

            <div className={s.table_wrapper}>
                <Formik
                    initialValues={{
                        sellAmount: '',
                        sellCurrency: 'CAD',
                        buyAmount: '',
                        buyCurrency: 'USD',
                    }}
                    onSubmit={(values) => {
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (
                        <form className={s.form} onSubmit={handleSubmit}>
                            <div className={s.form_block}>
                                <div className={s.field_wrapper}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            sell
                                        </div>
                                        <MyInput id='sellAmount'
                                                 name='sellAmount'
                                                 onChange={handleChange}
                                                 value={(currencyExchangeResult && !values.sellAmount) ? currencyExchangeResult : values.sellAmount}
                                                 disabled={!!values.buyAmount}
                                        />
                                    </div>
                                    <div className={s.sell_currency_block}>
                                        <CustomDropdown
                                            items={currencyList}
                                            id='sellCurrency'
                                            name='sellCurrency'
                                        />
                                    </div>
                                </div>
                                <div className={s.field_wrapper}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            buy
                                        </div>
                                        <MyInput id='buyAmount'
                                                 name='buyAmount'
                                                 onChange={handleChange}
                                                 value={(currencyExchangeResult && !values.buyAmount) ? currencyExchangeResult : values.buyAmount}
                                                 disabled={!!values.sellAmount}
                                        />
                                    </div>
                                    <div className={s.sell_currency_block}>
                                        <CustomDropdown
                                            items={currencyList}
                                            id='buyCurrency'
                                            name='buyCurrency'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={s.converted_amount_block}>
                                <span>Amount of currency to sell: </span>
                                <b>{values.sellAmount ? values.sellAmount : currencyExchangeResult} {values.sellCurrency}; </b>
                                <span>buying </span>
                                <b>{values.buyAmount ? values.buyAmount : currencyExchangeResult} {values.buyCurrency}</b>
                            </div>
                            <SetCurrencyByAccount values={values}/>
                            <div className={s.button_wrapper}>
                                <TransparentButton title={'Update'} type={'submit'} large />
                            </div>
                        </form>
                    )}
                </Formik>

                {/*<Table*/}
                {/*    bordered={false}*/}
                {/*    rowClassName={(record, index) => index % 2 !== 0 ? `${s.table_row_dark}` :  ''}*/}
                {/*    className={s.statementTable}*/}
                {/*    columns={columns}*/}
                {/*    pagination={false}*/}
                {/*    // for info*/}
                {/*    expandable={{*/}
                {/*        expandIcon: ({expanded, onExpand, record}) =>*/}
                {/*            (<img src={arrowDown} className={expanded ? s.open : s.close}*/}
                {/*                  onClick={e => onExpand(record, e)}/>)*/}
                {/*        ,*/}
                {/*        expandedRowRender: (record) => <p style={{margin: 0}}>{record.description}</p>,*/}
                {/*        expandRowByClick: true,*/}

                {/*    }}*/}
                {/*    dataSource={tableData}*/}
                {/*/>*/}

            </div>

            <div className={s.official_rate}>
                * Official currency exchange rates are published on the Bank of Canada website
                    <a className={s.link} style={{marginLeft: '6px'}} href="https://www.bankofcanada.ca/" target="_blank">
                        www.bankofcanada.ca
                    </a>
            </div>

        </motion.div>
    );
};

export default ExchangeRates;
