import React, {useState} from 'react';
import s from "./EmailAutenticationLogin.module.css";
import { motion } from 'framer-motion';
import MyInput from "../../../ui/MyInput/MyInput";
import attention from "../../../../assets/newImg/attention.svg";
import {zeroFill} from "../../../../helpers/ZeroFill";
import {loginVereficationThunk} from "../../../../store/reducers/ActionCreators";

const EmailAutenticationLogin = (props: any) => {

    const [time, setTime] = useState(120)
    const [timeIsOver, setTimeIsOver] = useState(false)

    const tick = () => {
        if (time === 0) {
            setTimeIsOver(true)
            props.returnToLogin(0)
            return
        }
        setTime(prevState => prevState - 1)
    }
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;

    React.useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    });

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.error_wrapper}>
                {
                    props.error === "Invalid email code"
                        ? <div className={s.error_block}>
                            <img src={attention} alt=""/>
                            <div>
                                Invalid email code
                            </div>
                        </div>
                        : <></>
                }
            </div>
            <div className={s.sign_in_wrapper}>

                <form>
                    <div className={s.sign_in_content}>
                        <div className={s.title}>
                            Email confirmation
                        </div>
                        <div className={s.subtitle}>
                            The code was sent to your email address
                        </div>
                        <div className={s.input_block}>
                            <div className={s.input_label}>
                                confirmation Code
                            </div>
                            <MyInput
                                   id="code"
                                   name="code"
                                   value={props.dataForSend.emailCode}
                                   onChange={(e) => props.setDataForSend(
                                       {
                                           ...props.dataForSend, emailCode: e.currentTarget.value
                                       }
                                   )}
                                   placeholder={"6 digits"}
                            />
                        </div>
                        <div className={s.timer_wrapper}>
                            {timeIsOver
                                ? <div></div>
                                : <div> {zeroFill(minutes, 2)} : {zeroFill(seconds, 2)}</div>
                            }
                        </div>
                    </div>
                    {/*<div className={s.dont_have_account}>*/}
                    {/*    <div>*/}
                    {/*        Back to*/}
                    {/*    </div>*/}
                    {/*    <Link className={s.link} to={"#"}>Sign up</Link>*/}
                    {/*</div>*/}
                </form>
            </div>
        </motion.div>
    );
};

export default EmailAutenticationLogin;
