import React, {useState} from 'react';
import {motion} from 'framer-motion';
import s from "./RegistrationSteps.module.css"
import company_name from "../../assets/newImg/company_name.svg";
import {NavLink} from "react-router-dom";
import logo from "../../assets/newImg/Logo.svg";
import quest from "../../assets/newImg/quest.svg";
import SignUp from "./SignUp/SignUp";
import RegistrationAutentication from "./RegistrationAutentication/RegistrationAutentication";
import CustomModalContactContainer from "../../components/ui/CustomModalContactContainer/CustomModalContactContainer";

const RegistrationSteps = () => {

    const [current, setCurrent] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false)

    const steps = [
        {
            content: <SignUp setCurrent={setCurrent}
                             current={current}
            />
        },
        {
            content: <RegistrationAutentication/>
        },
    ];

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <div className={s.wrapper}>

                <CustomModalContactContainer setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}/>

                <div className={s.login_wrapper_content_left}>
                    <div>
                    </div>
                    <div className={s.logo_block}>

                    </div>
                </div>
                <div className={s.login_wrapper_content_right}>
                    <div className={s.quest_block}>
                        <img src={quest} alt="" onClick={() => setIsModalOpen(true)}/>
                    </div>
                    <div>{steps[current].content}</div>
                </div>
            </div>

        </motion.div>
    );
};

export default RegistrationSteps;
