import React, {Dispatch, SetStateAction, useState} from 'react';
import s from "./CardsStepThree.module.css";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import TopUpModal from "../../../../components/Card/TopUpModal/TopUpModal";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import CardTransactions from "../../../../components/Card/CardTransactions/CardTransactions";
import {activatePhysicalCard, getCardInfo, sendPhysicalCardRequest} from "../../../../store/reducers/ActionCreators";
import TransparentButton from "../../../../components/ui/TransparentButton/TransparentButton";
import CustomModal from "../../../../components/ui/CustomModal/CustomModal";
import Icon from "@ant-design/icons";
import {ReactComponent as Back} from "../../../../assets/newImg/personalAccount/back.svg";
import {ReactComponent as CopyIcon} from '../../../../assets/newImg/personalAccount/copy.svg';
import {ReactComponent as ViewIcon} from '../../../../assets/newImg/personalAccount/view.svg';
import {ReactComponent as NoViewIcon} from '../../../../assets/newImg/personalAccount/no-view.svg';
import {setDisabled} from "../../../../store/reducers/UserSlice";
import CardSettings from "../../../../components/Card/CardSettings/CardSettings";

type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

const CardsStepThree = (props: PropsType) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const token = useAppSelector(shippingCountyState => shippingCountyState.userReducer.user.userToken)
    const cardInfo = useAppSelector(store => store.userReducer.cardInfo)
    const [showCVV, setShowCVV] = useState(false);
    const dispatch = useAppDispatch();
    function maskCardNumber(cardNumber: string | number) {

        cardNumber = cardNumber.toString();
        cardNumber = cardNumber.replace(/\s/g, '');
        const maskedPart = cardNumber.slice(4, -4).replace(/\d/g, '*');

        //Leave unmasked first and last 4, mask middle with spaces
        return cardNumber.slice(0, 4) + ' ' + maskedPart.replace(/(.{4})/g, '$1 ').trim() + ' ' + cardNumber.slice(-4);
    }

    const handleShowCVV = () => {
        setShowCVV(!showCVV);
    };

    const handleActivatePhysicalCard = () => {
        const dataForTransaction = {
            san: cardInfo.san,
        };
        dispatch(setDisabled(true))
        dispatch(activatePhysicalCard(token, dataForTransaction))
            .then(response => {
                setShowModal(false)
                setIsSuccessModalOpen(true)
            })
            .catch(error => {
                console.error('Error activating physical card:', error);
            })
            .finally(() => {
                dispatch(setDisabled(false))
            })
    };

    const [isCopied, setIsCopied] = useState(false);

    const copyTextToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(cardInfo.cardNumber);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 1500); // Reset copied state after 1.5 seconds
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };


    return (<>
        <CustomModal type={'confirm'} setIsModalOpen={setShowModal} isModalOpen={showModal}
                     children={<div className={s.modalContent}>

                         <h3 className={s.modalTitle}>Are you sure?</h3>
                         <h4 className={s.modalSubtitle}>Activate your Physical Card only after receiving it, and we
                             will send you card PIN code via e-mail.</h4>

                         <div className={s.buttonsWrapper}>
                             <div className={s.buttonBlock}>
                                 <TransparentButton buttonType={'confirm'} medium title={'Cancel'}
                                                    icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                                    onClick={() => {
                                                        setShowModal(false)
                                                    }}/>
                             </div>
                             <div className={s.buttonBlock}>
                                 <MyBtn buttonType={'confirm'} medium onClick={() => {
                                     handleActivatePhysicalCard()
                                 }} title={'Confirm'}/>
                             </div>

                         </div>
                     </div>}/>

        <CustomModal setIsModalOpen={setIsSuccessModalOpen} isModalOpen={isSuccessModalOpen}>
            <div>
                <div className={s.modalTitle}>Success!</div>
                <h4 className={s.modalSubtitle}> Your card has been activated.</h4>
                <div className={s.buttonsWrapper}>
                    <div className={s.buttonBlock}>
                        <MyBtn title={"Ok"} onClick={() => setIsSuccessModalOpen(false)}/>
                    </div>
                </div>
            </div>
        </CustomModal>
        <Tabs>
            <TabList className={s.tabstitle_block}>
                <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Card</Tab>
                <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Transactions</Tab>
                <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Card Settings</Tab>
            </TabList>
            <TabPanel>
                <div className={s.pageWrapper}>
                    <TopUpModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                    />

                    <div className={s.card_wrapper}>
                        <div className={s.credit_card}>
                            <div className={s.card_front}>
                                <div className={s.chip}></div>
                                <div className={s.cardNumber}>
                                    {cardInfo.type == 4 ? maskCardNumber(cardInfo.cardNumber) : cardInfo.cardNumber}
                                </div>
                                <div className={s.expireDate}>{cardInfo.expireDate}</div>
                                <div className={s.masterCard}></div>
                            </div>
                        </div>
                        <div className={s.cardDetailsWrapper}>
                            <div className={s.detailRow}>
                                <div className={s.detailTitle}>CARDHOLDER</div>
                                <div className={s.detail} style={{margin: '0 0'}}>{cardInfo.cardHolder}</div>
                            </div>
                            <div className={s.detailRow}>
                                <div className={s.detailBlock}>
                                    <div className={s.detailTitle}>CARD NUMBER
                                        <div className={s.detail}>
                                            {cardInfo.type == 4 ? maskCardNumber(cardInfo.cardNumber) : cardInfo.cardNumber}
                                            <button onClick={copyTextToClipboard} className={s.copyButton}>
                                                <CopyIcon fill='#2046A1'/>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div className={s.detailBlock}>
                                    <div className={s.detailTitle}>CVV
                                        <div className={s.detail}>
                                            {showCVV ? cardInfo.cvv : '***'}
                                            <div className={s.view} onClick={handleShowCVV}>
                                                {showCVV ? <NoViewIcon fill='#2046A1' /> :   <ViewIcon fill='#2046A1' />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.detailRow}>
                                <div className={s.detailBlock}>
                                    <div className={s.detailTitle}>EXPIRATION DATE
                                        <div className={s.detail}>{cardInfo.expireDate}</div>
                                    </div>
                                </div>
                                <div className={s.detailBlock}>
                                    <div className={s.detailTitle}>SAN NUMBER
                                        <div className={s.detail}>{cardInfo.san}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.detailRow}>
                                <div className={s.detailBlock}>
                                    <div className={s.detailTitle}>CARD STATUS
                                        <div className={`${s.detail} ${cardInfo.status === 3 ? s.redText : ''}`}>
                                            {cardInfo.statusText}
                                        </div>

                                    </div>
                                </div>
                                <div className={s.detailBlock}>
                                    <div className={s.detailTitle}>CARD BALANCE
                                        <div className={s.detail}>{cardInfo.balance} EUR</div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.detailRow}>
                                <div className={s.detailBlock}>
                                    <div className={s.detailTitle}/>
                                </div>
                                <div className={s.detailBlock}>
                                    <div className={s.detailTitle}>
                                        <MyBtn
                                            title={'+ Add Funds'}
                                            style={{maxWidth: 180}}
                                            large
                                            onClick={() => setIsModalOpen(true)}
                                            type={"submit"}
                                            localDisable={cardInfo.status == 3}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.hr}/>
                    {cardInfo.type == 1 ? (<div className={s.blockWrapper}>
                        <div className={s.blockTitle}>NEED A PHYSICAL CARD?
                        </div>
                        <div className={s.blockText}>Click on the request physical card button below and we will
                            process
                            the
                            request for you.
                        </div>
                        <MyBtn
                            title={'Request Physical Card'}
                            style={{maxWidth: 245, paddingBottom: 22}}
                            large
                            onClick={() => props.setCurrent(3)}
                            type={"submit"}/>
                    </div>) : cardInfo.type == 3 ? (<div className={s.blockWrapper}>
                        <div className={s.blockTitle}>Your card is being delivered!
                        </div>
                        {cardInfo.shippingTrackingNo ? (
                            <div className={s.blockText}>Your shipping tracking number is <div
                                className={s.link}>#{cardInfo.shippingTrackingNo}.</div>
                            </div>
                        ) : (
                            <div className={s.blockText}></div>
                        )}
                        <MyBtn
                            title={'Activate Physical Card'}
                            style={{maxWidth: 245, paddingBottom: 22}}
                            large
                            onClick={() => setShowModal(true)}
                            type={"submit"}/>
                    </div>) : (<div className={s.blockWrapper}>
                    </div>)}
                    {cardInfo.description ? (
                        <div className={s.blockText}>Note:{cardInfo.description}
                        </div>
                    ) : ('')}

                </div>
            </TabPanel>
            <TabPanel>
                <CardTransactions></CardTransactions>
            </TabPanel>
            <TabPanel>
                <CardSettings></CardSettings>
            </TabPanel>
        </Tabs>
    </>);
};

export default CardsStepThree;