import React, {Dispatch, SetStateAction, useState} from 'react';
import CustomModal from "../../ui/CustomModal/CustomModal";
import {Formik} from "formik";
import s from "./TopUpModal.module.css";
import MyInput from "../../ui/MyInput/MyInput";
import TransparentButton from "../../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import MyBtn from "../../ui/MyBtn/MyBtn";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import CustomDropdownForAccounts from "../../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import {ReactComponent as Back} from "../../../assets/newImg/personalAccount/back.svg";
import * as yup from "yup";
import MyTextarea from "../../ui/MyTextarea/MyTextarea";
import {setDisabled} from "../../../store/reducers/UserSlice";
import {getCardInfo, sendCardTopUp} from "../../../store/reducers/ActionCreators";

type PropsType = {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
    isModalOpen: boolean
}

const TopUpModal = (props: PropsType) => {

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const dispatch = useAppDispatch()

    const token = useAppSelector(shippingCountyState => shippingCountyState.userReducer.user.userToken)
    const cardInfo = useAppSelector(store => store.userReducer.cardInfo)
    const accountsList = useAppSelector(state => state.userReducer.accountsList)
    const eurAccountNumbers = accountsList
        .filter((account: { currency: string; }) => account.currency === 'EUR');

    const validationSchema = yup.object({
        payerAccount: yup.string().required('Required field'),
        amount: yup.string()
            .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits")
            .required('Required field')
            .max(10, 'max 10 digits')
            .test('minimum-amount', 'Amount should not be less than 5', (value, context) => {
                const numericValue = parseFloat(value.replace(',', ''));
                return numericValue >= 5;
            })
            .test('no-leading-zero', 'invalid format', (value, context) => {

                const tempArr = context.originalValue.split('')

                const onlyZero = tempArr.every((item: string) => {
                    if (item === '0') {
                        return true
                    }
                })

                if (context.originalValue && onlyZero) {
                    return false
                } else if ((context.originalValue.match(/,/g) || []).length > 1) {
                    return false
                }
                return true
            }),
    })

    return <>

        <CustomModal setIsModalOpen={setIsSuccessModalOpen} isModalOpen={isSuccessModalOpen}>
            <div>
                <div className={s.popup_title}>Success!</div>
                <span className={s.popup_subtitle}> The funds have been successfully <br/> credited to your card.</span>
                <div className={s.popup_button_wrapper}>
                    <div className={s.btn_block}>
                        <MyBtn title={"Ok"} onClick={() => setIsSuccessModalOpen(false)}/>
                    </div>
                </div>
            </div>
        </CustomModal>

        <CustomModal setIsModalOpen={setIsErrorModalOpen} isModalOpen={isErrorModalOpen} type={"error"}>
            <div className={s.popup_title}>
                Failed!
            </div>
            <div className={s.popup_subtitle}>
                Something went wrong. Please try again!
            </div>
            <div className={s.popup_button_wrapper}>
                <div className={s.btn_block}>
                    <MyBtn buttonType={"error"} title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                </div>
            </div>
        </CustomModal>

        <CustomModal isModalOpen={props.isModalOpen} setIsModalOpen={props.setIsModalOpen}>
            <Formik initialValues={{
                amount: '', payerAccount: '', reference: '', san: cardInfo.san
            }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        dispatch(setDisabled(true))
                        dispatch(sendCardTopUp(token, values))
                            .then((res) => {
                                if (res.data && res.data.message === "Card topped up successfully") {

                                    dispatch(getCardInfo(token));
                                    props.setIsModalOpen(false);
                                    setIsSuccessModalOpen(true);
                                } else {

                                    throw new Error("Unexpected response from the server");
                                }
                            })
                            .catch((error) => {
                                console.error('Error occurred:', error);
                                props.setIsModalOpen(false);
                                setIsErrorModalOpen(true)
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })
                    }}>

                {({
                      values, errors, touched, handleChange, handleSubmit
                  }) => <form onSubmit={handleSubmit}>
                    <div className={s.form_wrapper}>
                        <div className={s.modal_title}>
                            Deposit Funds
                        </div>
                        <div className={s.subject_block}>
                            <span>SELECT YOUR CURRENCY ACCOUNT</span>
                            <CustomDropdownForAccounts
                                items={eurAccountNumbers}
                                placeholder={"Please select the account"}
                                id="payerAccount"
                                name="payerAccount"
                                isError={errors.payerAccount}
                                touched={touched.payerAccount}
                            />
                            {errors.payerAccount && touched.payerAccount &&
                                <div className={s.error_message}>{errors.payerAccount}</div>}
                        </div>
                        <div className={s.subject_block}>
                            <span>AMOUNT TO LOAD</span>
                            <div className={s.input_block}>
                                <div className={s.amount_box}>
                                    <MyInput
                                        name={"amount"}
                                        id={"amount"}
                                        value={values.amount}
                                        onChange={handleChange}
                                    />
                                    {touched.amount && errors.amount &&
                                        <div className={s.error_message}>{errors.amount}</div>}
                                </div>
                                <div className={s.currency_box}>
                                    <MyInput
                                        id="transferCurrency"
                                        name="transferCurrency"
                                        value={"EUR"}
                                        disabled
                                    />
                                </div>

                            </div>

                        </div>

                        <div className={s.subject_block}>
                            <span>DESCRIPTION (OPTIONAL)</span>
                            <MyTextarea
                                id={"reference"}
                                name={"reference"}
                                value={values.reference}
                                onChange={handleChange}
                                style={{height: "168px"}}
                            />
                            {touched.reference && errors.reference &&
                                <div className={s.error_message}>{errors.reference}</div>}
                        </div>

                    </div>
                    <div className={s.buttons_wrapper}>
                        <MyBtn
                            large
                            style={{width: 180}}
                            title={"Send Request"}
                        />
                        <TransparentButton
                            title={"Cancel"}
                            style={{width: 180}}
                            large
                            icon={<Icon component={() => <Back/>} rev={undefined}/>}
                            onClick={() => props.setIsModalOpen(false)}
                        />

                    </div>

                </form>}
            </Formik>
        </CustomModal>
    </>;
};

export default TopUpModal;
