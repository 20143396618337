import React, {Dispatch, SetStateAction} from 'react';
import ToAnCompanyNameTransferForm
    from "../../../../../components/ToAnCompanyNameTransferForm/ToAnCompanyNameTransferForm";


type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    setTransferData: Dispatch<SetStateAction<any>>
}

const ToCompanyNameStepOne = (props: PropsType) => {

    return (
        <ToAnCompanyNameTransferForm
            setCurrent={props.setCurrent}
            setTransferData={props.setTransferData}
        />
    );
};

export default ToCompanyNameStepOne;
