import React, {createRef, useRef} from 'react';
import s from "./PhoneCodesInput.module.css";
import "./index.css"
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import {useField} from "formik";
import classnames from "classnames";

type propsType = {
    initCode?: any,
    phonecodes?:any,
    value?: string,
    onChange?: any,
    setCode?: any,
    error?: any,
    id?: any,
    name?: any,
    touched?: any,
    selectable?: boolean,
    onlyCountries?: string[]
}

const PhoneCodesInput = (props: propsType) => {

    console.log(props.onlyCountries)

    const inputElement = useRef<any>(null);

    const [field, meta, helpers] = useField<string>(props.name);
    const { setValue } = helpers;

    console.log(inputElement)

    const onChange = (value: any, fullNumber: any, extension: any) => {

        inputElement.current.tel.focus()

        setTimeout(() => {
            inputElement.current.tel.setSelectionRange(fullNumber.length, fullNumber.length);
        }, 0);

        setValue(fullNumber);
    }

    return (
        <div className={s.input_name_block}>
            <IntlTelInput
                onPhoneNumberChange={(isValid, value, selectedCountryData, fullNumber, extension) => onChange(value, fullNumber, extension)}
                containerClassName="intl-tel-input"
                inputClassName={classnames('iti__tel-input', props.error && 'iti__tel-input-error')}
                value={field.value}
                formatOnInit={true}
                autoHideDialCode={true}
                autoPlaceholder={false}
                ref={inputElement}
                onlyCountries={props.onlyCountries && props.onlyCountries}
                preferredCountries={props.onlyCountries ? props.onlyCountries : ['ca']}
            />
        </div>
    );
};

export default PhoneCodesInput;
