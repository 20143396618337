import {ITransacton} from "../types/interfaces";

export const PrepareDataForPaymentTemplate = (values: ITransacton) => {

    const cleanObject = (function () {

        function isEmpty(value: any) {
            return value === '';
        }

        return function (obj: any) {

            const tempObj = {...obj}

            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {

                    let value = tempObj[key];

                    if (
                        isEmpty(value)
                        || (value instanceof Object && cleanObject(value))
                    ) {
                        delete tempObj[key];
                    }
                }
            }

            return tempObj
        };
    })();

    const recipientTempObject = {
        name: values.name,
        firstName: values.firstName,
        lastName: values.lastName,
        middleName: values.middleName,
        fullName: values.fullName,
        bankCode: values.bankCode?.value,
        recipientsBankCountry: values.recipientsBankCountry,
        paymentMethod: values.paymentTypeByRegionForm,
        relationshipToSender: values.relationshipToSender,
        pixKeyValue: values.pixKeyValue,
        pixKeyType: values.pixKeyType?.value,
        branchCode: values.branchCode?.value || values.branchCodeTED,
        cashProvider: values.cashProvider,
        routingNumber: values.routingNumber,
        transferReason: values.transferReason,
        identityCardType: values.identityCardType,
        identityCardId: values.identityCardId,
        postalCode: values.postalCode,
        city: values.city,
        street: values.street,
        mobileProvider: values.mobileProvider?.value,
        country: values.recipientCountry?.value,
        phoneNumber: values.phoneNumber,
        recipientsBank: values.recipientsBank,
        recipientsBankAddress: values.recipientsBankAddress,
        recipientsIban: values.recipientsIban,
        recipientsSwiftBic: values.recipientsSwiftBic,
        recipientsAccountNumber: values.recipientsAccountNumber,
        recipientsAddress: values.recipientsAddress,
        bankAccountType: values.bankAccountType?.value,
        bankAccount: values.bankAccount
    }

    const cleanedRecipientDetails = cleanObject(recipientTempObject)


    return {
        templateName: values.templateName,
        type: values.type,
        fromAccount: values.payerAccount,
        reference: values.reference,
        recipient: values.bankAccount || values.phoneNumber || values.recipientsIban || values.recipientsAccountNumber || values.pixKeyValue,
        recipientDetails: cleanedRecipientDetails,
        paymentRegions: values.paymentRegions
    }
}

export const PrepareDataForPaymentIntraTemplate = (values: ITransacton) => {

    return {
        templateName: values.templateName,
        type: values.type,
        fromAccount: values.accNumberFrom,
        toAccount: values.accNumberTo,
    }
}
