// import {toBase64} from "./toBase64";

export const PrepareDataForCompanyNameTransaction = async (values: any) => {

     // const invoiceAsString = await toBase64(values.invoice) as string

     return {
          accNumberFrom: values.accNumberFrom,
          accNumberTo: values.accNumberTo,
          amount: Number(values.amount),
          email: values.email,
          paymentDate: values.paymentDate,
          reference: values.reference,
          templateName: values.templateName,
          type: values.type,
          // invoice: invoiceAsString
     }
}
