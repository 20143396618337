import {useField} from "formik";
import {useEffect} from "react";

export const IdentityCardTypeHelper = (props: any) => {

    const country = props.country

    const [field, meta, helpers] = useField<string>(props.name);
    const { setValue } = helpers;

    useEffect(() => {
        if (country === "Canada") {
            setValue("")
        } else {
            setValue("Passport")
        }

    },[country])

    return null
}

export const FinancialInstitutionHelper = (props: any) => {

    // const country = props.country
    //
    // const [field, meta, helpers] = useField<string>(props.name);
    // const { setValue } = helpers;
    //
    // useEffect(() => {
    //     if (country === "Canada") {
    //         setValue("")
    //     } else {
    //         setValue("Passport")
    //     }
    //
    // },[country])

    return null
}
