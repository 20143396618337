import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Formik} from "formik";
import s from "./BankTransferFormPersonal.module.css";
import {DatePicker, Popover} from "antd";
import info from "../../assets/newImg/personalAccount/information.svg";
import CustomDropdown from "../ui/CustomDropdown/CustomDropdown";
import MyInput from "../ui/MyInput/MyInput";
import classnames from "classnames";
import MyTextarea from "../ui/MyTextarea/MyTextarea";
import CustomCheckbox from "../ui/customCheckbox/CustomCheckbox";
import MyBtn from "../ui/MyBtn/MyBtn";
import {Persist} from "formik-persist";
import {motion} from "framer-motion";
import {useAppDispatch} from "../../hooks/redux";
import CustomDropdownForAccounts from "../ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import dayjs from "dayjs";
import {myDate} from "../../helpers/CalculateDate";
import type {RangePickerProps} from 'antd/es/date-picker';
import {ReactComponent as Plus} from "../../assets/newImg/personalAccount/plus.svg";
import {
    azaCountries,
    bankAccountTypesTED,
    bankAccountTypesUS,
    bankCodeListEGPBank,
    bankCodeListGhanaGHSBank,
    bankCodeListKESBank,
    bankCodeListNgnBank,
    bankCodeListNigeriaUSDBank,
    bankCodeListTEDPayments,
    bankCodeListZARBank,
    branchCodeListBWPBank,
    branchCodeListUGXBank,
    branchCodeListZMWBank,
    countries,
    idTypesKenya,
    idTypesMorocco,
    languages,
    mobileProvidersGhana,
    mobileProvidersGuinea,
    mobileProvidersKenya,
    mobileProvidersUganda,
    mobileProvidersXAF,
    paymentRegions,
    pixKeyTypesBrazil,
    transferReasons,
    xofCashCountries,
    xofMobileCountries
} from "../../Constants/StaticData"
import {
    CurrencyHelper,
    getItemsPaymentType,
    PaymentTypeHelper,
    getMobileProvider,
    getValidationSchema, PayerAccountHelper
} from "./FormHelpers/FormHelpers";
import PhoneCodesInput from "../ui/PhoneCodesInput/PhoneCodesInput";
import CustomDropdownForAzaTransactions from "../ui/CustomDropdownForAzaTransactions/CustomDropdownForAzaTransactions";
import {setPaymentRegionFilter} from "../../store/reducers/TransactionsSlice/TransactionsSlice";
import {useSelector} from "react-redux";
import {selectAccountsByFilter} from "../../store/reducers/TransactionsSlice/selector";
import SelectWithSearch from "../ui/SelectWithSearch/SelectWithSearch";
import invoice from "../../assets/newImg/personalAccount/invoice.svg";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import attachedDoc from "../../assets/newImg/personalAccount/attached_document.svg";
import delete_document from "../../assets/newImg/personalAccount/delete_document.svg";

type PropsType = {
    setCurrent?: Dispatch<SetStateAction<number>>
    setTransferData?: Dispatch<SetStateAction<any>>
    id?: string | number;
}

const BankTransferFormPersonal = (props: PropsType) => {

    const [paymentType, setPaymentType] = useState("SHA")
    const [paymentRegion, setPaymentRegion] = useState("SWIFT (International Transfer)")
    const [currency, setCurrency] = useState('')
    const [isMobile, setIsMobile] = useState(false)
    const dispatch = useAppDispatch()

    const accountsList = useSelector(selectAccountsByFilter)

    const [date, setDate] = useState({
        from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ? ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + myDate.getDate()),
    })

    const [paymentTypeByRegion, setPaymentTypeByRegion] = useState('')

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [window.innerWidth])

    useEffect(() => {
        dispatch(setPaymentRegionFilter(paymentRegion))
    }, [paymentRegion])

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().startOf('day');
    };

    const dateStyle = {
        fontFamily: 'Inter',
        fontWeight: 800,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        padding: '17px 16px',
        height: 52,
        width: isMobile ? "100%" : 311,
        border: '1.5px solid #EEEFEF',
        borderRadius: 8
    }

    const dateFormat = 'YYYY-MM-DD';


    return (
        <div>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
            >
                <Formik
                    initialValues={{
                        payerAccount: '',
                        paymentRegions: 'SWIFT (International Transfer)',
                        paymentType: '',
                        paymentTypeByRegionForm: '',
                        bankCode: {
                            name: '',
                            value: ''
                        },
                        bankAccount: '',
                        bankAccountType: {
                            name: '',
                            value: ''
                        },
                        fullName: '',
                        firstName: '',
                        lastName: '',
                        middleName: '',
                        recipientsAddress: '',
                        recipientsAccountNumber: '',
                        recipientsSwiftBic: '',
                        recipientsIban: '',
                        recipientsBankCountry: '',
                        recipientsBankAddress: '',
                        recipientsBank: '',
                        email: '',
                        emailLanguage: 'EN',
                        paymentDate: date.from,
                        transferCurrency: 'USD',
                        purpose: '',
                        reference: '',
                        templateName: '',
                        amount: '',
                        type: 'international',
                        phoneNumber: '',
                        recipientCountry: {
                            name: '',
                            value: ''
                        },
                        mobileProvider: {
                            name: '',
                            value: ''
                        },
                        street: '',
                        city: '',
                        postalCode: '',
                        identityCardId: '',
                        identityCardType:  {
                            name: '',
                            value: ''
                        },
                        transferReason: {
                            name: '',
                            value: ''
                        },
                        routingNumber: '',
                        cashProvider: '',
                        branchCode: {
                            name: '',
                            value: ''
                        },
                        branchCodeTED: '',
                        pixKeyType: {
                            name: '',
                            value: ''
                        },
                        pixKeyValue: '',
                        relationshipToSender: '',
                        institutionNumber: '',
                        transitNumber: '',
                        uniqueId: '',
                        sortCode: '',
                        senderIdType: {
                            name: '',
                            value: ''
                        },
                        senderIdNumber: '',
                        beneficiaryName: '',
                        beneficiaryEmail: '',
                        securityQuestion: '',
                        securityAnswer: '',
                        // invoice: '',
                    }}
                    validationSchema={getValidationSchema(paymentTypeByRegion, paymentRegion)}
                    onSubmit={(values) => {

                        let tempData = {
                            ...values,
                            paymentType,
                            phoneNumber: values.phoneNumber !== "" ? values.phoneNumber : "",
                            transferCurrency: currency,
                            paymentDate: date.from
                        };

                        if (values.paymentRegions === "SWIFT (International Transfer)" ||
                            values.paymentRegions === "SEPA (only for EUR)" ||
                            values.paymentRegions === "PAN AFRICA") {
                            tempData = {...tempData, paymentType}
                        }

                        props.setTransferData && props.setTransferData(tempData)
                        props.setCurrent && props.setCurrent(1)
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          resetForm,
                          setFieldValue,
                          handleChange,
                          handleSubmit
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Payment region
                                        <Popover content={"Please select the account you wish to make a transfer from."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <CustomDropdown
                                        customId="paymentRegions"
                                        items={paymentRegions}
                                        placeholder={"Please select the account"}
                                        id="paymentRegions"
                                        name="paymentRegions"
                                        isError={errors.paymentRegions}
                                        touched={touched.paymentRegions}
                                        setItem={setPaymentRegion}
                                    />
                                    {errors.paymentRegions && touched.paymentRegions &&
                                        <div className={s.error_message}>{errors.paymentRegions}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Payer account
                                        <Popover content={"Please select the account you wish to make a transfer from."}
                                                 color={'#EEEFEF'}
                                                 overlayInnerStyle={{
                                                     fontFamily: 'Inter',
                                                     width: '280px',
                                                     padding: '13px 16px'
                                                 }}
                                        >
                                            <img src={info} alt=""/>
                                        </Popover>
                                    </div>
                                    <CustomDropdownForAccounts
                                        items={accountsList}
                                        placeholder={"Please select the account"}
                                        id="payerAccount"
                                        name="payerAccount"
                                        isError={errors.payerAccount}
                                        touched={touched.payerAccount}
                                        setCurrency={setCurrency}
                                    />
                                    {errors.payerAccount && touched.payerAccount &&
                                        <div className={s.error_message}>{errors.payerAccount}</div>}
                                </div>
                            </div>

                            {
                                values.paymentRegions === "PAN AFRICA" &&
                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            RECIPIENT’S BANK COUNTRY/REGION
                                        </div>
                                        <SelectWithSearch
                                            name={'recipientsBankCountry'}
                                            id="recipientsBankCountry"
                                            isError={errors.recipientsBankCountry}
                                            items={azaCountries}
                                            resetAnotherField={true}
                                            touched={touched.recipientsBankCountry}
                                        />
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            PAYMENT METHOD
                                        </div>
                                        <CustomDropdown
                                            customId="paymentTypeByRegionForm"
                                            items={getItemsPaymentType(values.recipientsBankCountry)}
                                            placeholder={"Please select the payment method"}
                                            id="paymentTypeByRegionForm"
                                            name="paymentTypeByRegionForm"
                                            isError={errors.paymentTypeByRegionForm}
                                            touched={touched.paymentTypeByRegionForm}
                                            setItem={setPaymentTypeByRegion}
                                        />
                                    </div>
                                </div>
                            }

                            {/*ACH (only for USD)*/}
                            {
                                values.paymentRegions === "ACH (only for USD)" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Full Name
                                                <Popover
                                                    content={"Enter the name and surname of the recipient or the name of " +
                                                        "the legal person."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName && touched.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * INSTITUTION NUMBER
                                            </div>
                                            <MyInput id={"institutionNumber"}
                                                     name={"institutionNumber"}
                                                     touched={touched.institutionNumber}
                                                     value={values.institutionNumber}
                                                     onChange={handleChange}
                                                     isError={errors.institutionNumber}
                                            />
                                            {errors.institutionNumber && touched.institutionNumber &&
                                                <div className={s.error_message}>{errors.institutionNumber}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * TRANSIT NUMBER
                                            </div>
                                            <MyInput id={"transitNumber"}
                                                     name={"transitNumber"}
                                                     touched={touched.transitNumber}
                                                     value={values.transitNumber}
                                                     onChange={handleChange}
                                                     isError={errors.transitNumber}
                                            />
                                            {errors.transitNumber && touched.transitNumber &&
                                                <div className={s.error_message}>{errors.transitNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * ACCOUNT NUMBER
                                            </div>
                                            <MyInput id={"recipientsAccountNumber"}
                                                     name={"recipientsAccountNumber"}
                                                     touched={touched.recipientsAccountNumber}
                                                     value={values.recipientsAccountNumber}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAccountNumber}
                                            />
                                            {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                RECIPIENT’S ADDRESS
                                            </div>
                                            <MyInput id={"recipientsAddress"}
                                                     name={"recipientsAddress"}
                                                     touched={touched.recipientsAddress}
                                                     value={values.recipientsAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAddress}
                                            />
                                            {errors.recipientsAddress && touched.recipientsAddress &&
                                                <div className={s.error_message}>{errors.recipientsAddress}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                RECIPIENT’S BANK COUNTRY
                                            </div>
                                            <SelectWithSearch
                                                name={'recipientsBankCountry'}
                                                id="recipientsBankCountry"
                                                isError={errors.recipientsBankCountry}
                                                items={countries}
                                                touched={touched.recipientsBankCountry}
                                            />
                                            {errors.recipientsBankCountry && touched.recipientsBankCountry &&
                                                <div className={s.error_message}>{errors.recipientsBankCountry}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                recipient’s bank
                                            </div>
                                            <MyInput id={"recipientsBank"}
                                                     name={"recipientsBank"}
                                                     touched={touched.recipientsBank}
                                                     value={values.recipientsBank}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsBank}
                                            />
                                            {errors.recipientsBank && touched.recipientsBank &&
                                                <div className={s.error_message}>{errors.recipientsBank}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                RECIPIENT’S BANK ADDRESS
                                            </div>
                                            <MyInput id={"recipientsBankAddress"}
                                                     name={"recipientsBankAddress"}
                                                     touched={touched.recipientsBankAddress}
                                                     value={values.recipientsBankAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsBankAddress}
                                            />
                                            {errors.recipientsBankAddress && touched.recipientsBankAddress &&
                                                <div className={s.error_message}>{errors.recipientsBankAddress}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>

                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    Email
                                                    <Popover
                                                        content={"Please indicate the recipient’s email address and language, " +
                                                            "if you would like us to send them an electronically signed automatic email " +
                                                            "about the performed transfer."}
                                                        color={'#EEEFEF'}
                                                        overlayInnerStyle={{
                                                            fontFamily: 'Inter',
                                                            width: '280px',
                                                            padding: '13px 16px'
                                                        }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"email"}
                                                         name={"email"}
                                                         touched={touched.email}
                                                         value={values.email}
                                                         onChange={handleChange}
                                                         isError={errors.email}
                                                />
                                            </div>

                                            <div className={s.languages_dropdown}>
                                                <CustomDropdown
                                                    items={languages}
                                                    id="emailLanguage"
                                                    name="emailLanguage"
                                                    isError={errors.emailLanguage}
                                                    touched={touched.emailLanguage}
                                                />
                                            </div>
                                        </div>

                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                UNIQUE ID
                                            </div>
                                            <MyInput
                                                id={"uniqueId"}
                                                name={"uniqueId"}
                                                touched={touched.uniqueId}
                                                value={values.uniqueId}
                                                onChange={handleChange}
                                                isError={errors.uniqueId}
                                            />
                                            {errors.uniqueId && touched.uniqueId &&
                                                <div className={s.error_message}>{errors.uniqueId}</div>}
                                        </div>

                                    </div>
                                </div>
                            }

                            {/*BACS (only for GBP)*/}
                            {
                                values.paymentRegions === "BACS (only for GBP)" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * SORT CODE
                                            </div>
                                            <MyInput id={"sortCode"}
                                                     name={"sortCode"}
                                                     touched={touched.sortCode}
                                                     value={values.sortCode}
                                                     onChange={handleChange}
                                                     isError={errors.sortCode}
                                            />
                                            {errors.sortCode && touched.sortCode &&
                                                <div className={s.error_message}>{errors.sortCode}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * ACCOUNT NUMBER
                                            </div>
                                            <MyInput id={"recipientsAccountNumber"}
                                                     name={"recipientsAccountNumber"}
                                                     touched={touched.recipientsAccountNumber}
                                                     value={values.recipientsAccountNumber}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAccountNumber}
                                            />
                                            {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IBAN
                                            </div>
                                            <MyInput id={"recipientsIban"}
                                                     name={"recipientsIban"}
                                                     touched={touched.recipientsIban}
                                                     value={values.recipientsIban}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsIban}
                                            />
                                            {errors.recipientsIban && touched.recipientsIban &&
                                                <div className={s.error_message}>{errors.recipientsIban}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * SWIFT / BIC code
                                            </div>
                                            <MyInput id={"recipientsSwiftBic"}
                                                     name={"recipientsSwiftBic"}
                                                     touched={touched.recipientsSwiftBic}
                                                     value={values.recipientsSwiftBic}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsSwiftBic}
                                            />
                                            {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                FULL NAME
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName && touched.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                RECIPIENT’S ADDRESS
                                            </div>
                                            <MyInput id={"recipientsAddress"}
                                                     name={"recipientsAddress"}
                                                     touched={touched.recipientsAddress}
                                                     value={values.recipientsAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAddress}
                                            />
                                            {errors.recipientsAddress && touched.recipientsAddress &&
                                                <div className={s.error_message}>{errors.recipientsAddress}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                RECIPIENT’S BANK COUNTRY
                                            </div>
                                            <SelectWithSearch
                                                name={'recipientsBankCountry'}
                                                id="recipientsBankCountry"
                                                isError={errors.recipientsBankCountry}
                                                items={countries}
                                                touched={touched.recipientsBankCountry}
                                            />
                                            {errors.recipientsBankCountry && touched.recipientsBankCountry &&
                                                <div className={s.error_message}>{errors.recipientsBankCountry}</div>}
                                        </div>

                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                recipient’s bank
                                            </div>
                                            <MyInput
                                                id={"recipientsBank"}
                                                name={"recipientsBank"}
                                                touched={touched.recipientsBank}
                                                value={values.recipientsBank}
                                                onChange={handleChange}
                                                isError={errors.recipientsBank}
                                            />
                                            {errors.recipientsBank && touched.recipientsBank &&
                                                <div className={s.error_message}>{errors.recipientsBank}</div>}
                                        </div>

                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                recipient’s bank address
                                            </div>
                                            <MyInput
                                                id={"recipientsBankAddress"}
                                                name={"recipientsBankAddress"}
                                                touched={touched.recipientsBankAddress}
                                                value={values.recipientsBankAddress}
                                                onChange={handleChange}
                                                isError={errors.recipientsBankAddress}
                                            />
                                            {errors.recipientsBankAddress && touched.recipientsBankAddress &&
                                                <div className={s.error_message}>{errors.recipientsBankAddress}</div>}
                                        </div>

                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    Email
                                                    <Popover
                                                        content={"Please indicate the recipient’s email address and language, " +
                                                            "if you would like us to send them an electronically signed automatic email " +
                                                            "about the performed transfer."}
                                                        color={'#EEEFEF'}
                                                        overlayInnerStyle={{
                                                            fontFamily: 'Inter',
                                                            width: '280px',
                                                            padding: '13px 16px'
                                                        }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"email"}
                                                         name={"email"}
                                                         touched={touched.email}
                                                         value={values.email}
                                                         onChange={handleChange}
                                                         isError={errors.email}
                                                />
                                            </div>

                                            <div className={s.languages_dropdown}>
                                                <CustomDropdown
                                                    items={languages}
                                                    id="emailLanguage"
                                                    name="emailLanguage"
                                                    isError={errors.emailLanguage}
                                                    touched={touched.emailLanguage}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }

                            {/*SWIFT / SEPA*/}
                            {
                                (values.paymentRegions === "SWIFT (International Transfer)" || values.paymentRegions === "SEPA (only for EUR)") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Full Name
                                                <Popover
                                                    content={"Enter the name and surname of the recipient or the name of " +
                                                        "the legal person."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"fullName"}
                                                     name={"fullName"}
                                                     touched={touched.fullName}
                                                     value={values.fullName}
                                                     onChange={handleChange}
                                                     isError={errors.fullName}
                                            />
                                            {errors.fullName && touched.fullName &&
                                                <div className={s.error_message}>{errors.fullName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * recipient’s Address
                                            </div>
                                            <MyInput id={"recipientsAddress"}
                                                     name={"recipientsAddress"}
                                                     touched={touched.recipientsAddress}
                                                     value={values.recipientsAddress}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAddress}
                                            />
                                            {errors.recipientsAddress && touched.recipientsAddress &&
                                                <div className={s.error_message}>{errors.recipientsAddress}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IBAN / Account number
                                                <Popover
                                                    content={"Enter the recipient’s bank account number or IBAN account."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsAccountNumber"}
                                                     name={"recipientsAccountNumber"}
                                                     touched={touched.recipientsAccountNumber}
                                                     value={values.recipientsAccountNumber}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAccountNumber}
                                            />
                                            {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * SWIFT / BIC code
                                                <Popover
                                                    content={"A SWIFT code is an international bank code that identifies " +
                                                        "particular banks worldwide. It's also known as a Bank Identifier Code (BIC). " +
                                                        "A SWIFT code consists of 8 or 11 characters."}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"recipientsSwiftBic"}
                                                     name={"recipientsSwiftBic"}
                                                     touched={touched.recipientsSwiftBic}
                                                     value={values.recipientsSwiftBic}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsSwiftBic}
                                            />
                                            {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * recipient’s bank country
                                            </div>
                                            <SelectWithSearch
                                                name={'recipientsBankCountry'}
                                                id="recipientsBankCountry"
                                                isError={errors.recipientsBankCountry}
                                                items={countries}
                                                touched={touched.recipientsBankCountry}
                                            />
                                            {errors.recipientsBankCountry && touched.recipientsBankCountry &&
                                                <div className={s.error_message}>{errors.recipientsBankCountry}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * recipient’s bank
                                            </div>
                                            <MyInput id={"recipientsBank"}
                                                     name={"recipientsBank"}
                                                     touched={touched.recipientsBank}
                                                     value={values.recipientsBank}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsBank}
                                            />
                                            {errors.recipientsBank && touched.recipientsBank &&
                                                <div className={s.error_message}>{errors.recipientsBank}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block_fixed}>
                                            <div className={s.input_label}>
                                                * recipient’s bank address
                                            </div>
                                            <MyInput
                                                id={"recipientsBankAddress"}
                                                name={"recipientsBankAddress"}
                                                touched={touched.recipientsBankAddress}
                                                value={values.recipientsBankAddress}
                                                onChange={handleChange}
                                                isError={errors.recipientsBankAddress}
                                            />
                                            {errors.recipientsBankAddress && touched.recipientsBankAddress &&
                                                <div className={s.error_message}>{errors.recipientsBankAddress}</div>}
                                        </div>

                                        <div className={s.mobile_row}>
                                            <div className={classnames(s.input_block, s.short)}>
                                                <div className={s.input_label}>
                                                    Email
                                                    <Popover
                                                        content={"Please indicate the recipient’s email address and language, " +
                                                            "if you would like us to send them an electronically signed automatic email " +
                                                            "about the performed transfer."}
                                                        color={'#EEEFEF'}
                                                        overlayInnerStyle={{
                                                            fontFamily: 'Inter',
                                                            width: '280px',
                                                            padding: '13px 16px'
                                                        }}
                                                    >
                                                        <img src={info} alt=""/>
                                                    </Popover>
                                                </div>
                                                <MyInput id={"email"}
                                                         name={"email"}
                                                         touched={touched.email}
                                                         value={values.email}
                                                         onChange={handleChange}
                                                         isError={errors.email}
                                                />
                                            </div>

                                            <div className={s.languages_dropdown}>
                                                <CustomDropdown
                                                    items={languages}
                                                    id="emailLanguage"
                                                    name="emailLanguage"
                                                    isError={errors.emailLanguage}
                                                    touched={touched.emailLanguage}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA NGN::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "NGN::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK CODE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"bankCode"}
                                                name={"bankCode"}
                                                touched={touched.bankCode}
                                                isError={errors.bankCode && errors.bankCode.name}
                                                items={bankCodeListNgnBank}
                                            />
                                            {errors.bankCode && touched.bankCode &&
                                                <div className={s.error_message}>{errors.bankCode.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA NGN::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "NGN::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    selectable = {false}
                                                    onlyCountries={["ng"]}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>

                                        </div>
                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA NigeriaUSD::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "NigeriaUSD::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK CODE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"bankCode"}
                                                name={"bankCode"}
                                                touched={touched.bankCode}
                                                isError={errors.bankCode && errors.bankCode.name}
                                                items={bankCodeListNigeriaUSDBank}
                                            />
                                            {errors.bankCode && touched.bankCode &&
                                                <div className={s.error_message}>{errors.bankCode.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * PHONE NUMBER
                                                </div>
                                                <PhoneCodesInput
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    selectable = {false}
                                                    onlyCountries={['ng']}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA NigeriaUSD::Cash*/}
                            {
                                (values.paymentTypeByRegionForm === "NigeriaUSD::Cash" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * PHONE NUMBER
                                                </div>
                                                <PhoneCodesInput
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    selectable = {false}
                                                    onlyCountries={["ng"]}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA GHS::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "GHS::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK CODE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"bankCode"}
                                                name={"bankCode"}
                                                touched={touched.bankCode}
                                                isError={errors.bankCode && errors.bankCode.name}
                                                items={bankCodeListGhanaGHSBank}
                                            />
                                            {errors.bankCode && touched.bankCode &&
                                                <div className={s.error_message}>{errors.bankCode.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                        </div>

                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA GHS::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "GHS::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_block}>
                                                    <div className={s.input_label}>
                                                        * Phone number
                                                    </div>
                                                    <PhoneCodesInput
                                                        id='phoneNumber'
                                                        name='phoneNumber'
                                                        onChange={handleChange}
                                                        value={values.phoneNumber}
                                                        error={errors.phoneNumber}
                                                        touched={touched.phoneNumber}
                                                        selectable = {false}
                                                        onlyCountries={["gh"]}
                                                    />
                                                    {errors.phoneNumber &&
                                                        <div className={s.error_message}>{errors.phoneNumber}</div>}
                                                </div>
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * MOBILE PROVIDER
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"mobileProvider"}
                                                name={"mobileProvider"}
                                                touched={touched.mobileProvider}
                                                isError={errors.mobileProvider && errors.mobileProvider.name}
                                                items={mobileProvidersGhana}
                                            />
                                            {errors.mobileProvider && touched.mobileProvider &&
                                                <div className={s.error_message}>{errors.mobileProvider.name}</div>}
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA GHS::Cash*/}
                            {
                                (values.paymentTypeByRegionForm === "GHS::Cash" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_block}>
                                                    <div className={s.input_label}>
                                                        * Phone number
                                                    </div>
                                                    <PhoneCodesInput
                                                        id='phoneNumber'
                                                        name='phoneNumber'
                                                        onChange={handleChange}
                                                        value={values.phoneNumber}
                                                        error={errors.phoneNumber}
                                                        touched={touched.phoneNumber}
                                                        selectable = {false}
                                                        onlyCountries={["gh"]}
                                                    />
                                                    {errors.phoneNumber &&
                                                        <div className={s.error_message}>{errors.phoneNumber}</div>}
                                                </div>
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA UGX::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "UGX::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * STREET
                                            </div>
                                            <MyInput id={"street"}
                                                     name={"street"}
                                                     touched={touched.street}
                                                     value={values.street}
                                                     onChange={handleChange}
                                                     isError={errors.street}
                                            />
                                            {errors.street && touched.street &&
                                                <div className={s.error_message}>{errors.street}</div>}

                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * CITY
                                            </div>
                                            <MyInput id={"city"}
                                                     name={"city"}
                                                     touched={touched.city}
                                                     value={values.city}
                                                     onChange={handleChange}
                                                     isError={errors.city}
                                            />
                                            {errors.city && touched.city &&
                                                <div className={s.error_message}>{errors.city}</div>}

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                POSTAL CODE
                                            </div>
                                            <MyInput id={"postalCode"}
                                                     name={"postalCode"}
                                                     touched={touched.postalCode}
                                                     value={values.postalCode}
                                                     onChange={handleChange}
                                                     isError={errors.postalCode}
                                            />
                                            {errors.postalCode && touched.postalCode &&
                                                <div className={s.error_message}>{errors.postalCode}</div>}

                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * IDENTITY CARD ID
                                            </div>
                                            <MyInput id={"identityCardId"}
                                                     name={"identityCardId"}
                                                     touched={touched.identityCardId}
                                                     value={values.identityCardId}
                                                     onChange={handleChange}
                                                     isError={errors.identityCardId}
                                            />
                                            {errors.identityCardId && touched.identityCardId &&
                                                <div className={s.error_message}>{errors.identityCardId}</div>}

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}

                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * BRANCH CODE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"branchCode"}
                                                name={"branchCode"}
                                                touched={touched.branchCode}
                                                isError={errors.branchCode && errors.branchCode.name}
                                                items={branchCodeListUGXBank}
                                            />
                                            {errors.branchCode && touched.branchCode &&
                                                <div className={s.error_message}>{errors.branchCode.name}</div>}
                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_label}>
                                                * TRANSFER REASON
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"transferReason"}
                                                name={"transferReason"}
                                                touched={touched.transferReason}
                                                isError={errors.transferReason && errors.transferReason.name}
                                                items={transferReasons}
                                            />
                                            {errors.transferReason && touched.transferReason &&
                                                <div className={s.error_message}>{errors.transferReason.name}</div>}
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA UGX::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "UGX::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    selectable = {false}
                                                    onlyCountries={["ug"]}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * MOBILE PROVIDER
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"mobileProvider"}
                                                    name={"mobileProvider"}
                                                    touched={touched.mobileProvider}
                                                    isError={errors.mobileProvider && errors.mobileProvider.name}
                                                    items={mobileProvidersUganda}
                                                />
                                                {errors.mobileProvider && touched.mobileProvider &&
                                                    <div className={s.error_message}>{errors.mobileProvider.name}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA EUR::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "EUR::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IBAN
                                                </div>
                                                <MyInput id={"recipientsIban"}
                                                         name={"recipientsIban"}
                                                         touched={touched.recipientsIban}
                                                         value={values.recipientsIban}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsIban}
                                                />
                                                {errors.recipientsIban && touched.recipientsIban &&
                                                    <div className={s.error_message}>{errors.recipientsIban}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK NAME
                                                </div>
                                                <MyInput id={"recipientsBank"}
                                                         name={"recipientsBank"}
                                                         touched={touched.recipientsBank}
                                                         value={values.recipientsBank}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsBank}
                                                />
                                                {errors.recipientsBank && touched.recipientsBank &&
                                                    <div className={s.error_message}>{errors.recipientsBank}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BIC CODE
                                                </div>
                                                <MyInput id={"recipientsSwiftBic"}
                                                         name={"recipientsSwiftBic"}
                                                         touched={touched.recipientsSwiftBic}
                                                         value={values.recipientsSwiftBic}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsSwiftBic}
                                                />
                                                {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                    <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA GBP::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "GBP::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IBAN
                                                </div>
                                                <MyInput id={"recipientsIban"}
                                                         name={"recipientsIban"}
                                                         touched={touched.recipientsIban}
                                                         value={values.recipientsIban}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsIban}
                                                />
                                                {errors.recipientsIban && touched.recipientsIban &&
                                                    <div className={s.error_message}>{errors.recipientsIban}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK NAME
                                                </div>
                                                <MyInput id={"recipientsBank"}
                                                         name={"recipientsBank"}
                                                         touched={touched.recipientsBank}
                                                         value={values.recipientsBank}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsBank}
                                                />
                                                {errors.recipientsBank && touched.recipientsBank &&
                                                    <div className={s.error_message}>{errors.recipientsBank}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BIC CODE
                                                </div>
                                                <MyInput id={"recipientsSwiftBic"}
                                                         name={"recipientsSwiftBic"}
                                                         touched={touched.recipientsSwiftBic}
                                                         value={values.recipientsSwiftBic}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsSwiftBic}
                                                />
                                                {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                    <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA USD::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "USD::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK ACCOUNT
                                                </div>
                                                <MyInput id={"bankAccount"}
                                                         name={"bankAccount"}
                                                         touched={touched.bankAccount}
                                                         value={values.bankAccount}
                                                         onChange={handleChange}
                                                         isError={errors.bankAccount}
                                                />
                                                {errors.bankAccount && touched.bankAccount &&
                                                    <div className={s.error_message}>{errors.bankAccount}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK ACCOUNT TYPE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankAccountType"}
                                                    name={"bankAccountType"}
                                                    touched={touched.bankAccountType}
                                                    isError={errors.bankAccountType && errors.bankAccountType.name}
                                                    items={bankAccountTypesUS}
                                                />
                                                {errors.bankAccountType && touched.bankAccountType &&
                                                    <div className={s.error_message}>{errors.bankAccountType.name}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK NAME
                                                </div>
                                                <MyInput id={"recipientsBank"}
                                                         name={"recipientsBank"}
                                                         touched={touched.recipientsBank}
                                                         value={values.recipientsBank}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsBank}
                                                />
                                                {errors.recipientsBank && touched.recipientsBank &&
                                                    <div className={s.error_message}>{errors.recipientsBank}</div>}
                                            </div>

                                        </div>
                                    </div>


                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * ROUTING NUMBER
                                                </div>
                                                <MyInput id={"routingNumber"}
                                                         name={"routingNumber"}
                                                         touched={touched.routingNumber}
                                                         value={values.routingNumber}
                                                         onChange={handleChange}
                                                         isError={errors.routingNumber}
                                                         placeholder={"9 digits"}
                                                />
                                                {errors.routingNumber && touched.routingNumber &&
                                                    <div className={s.error_message}>{errors.routingNumber}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * SWIFT CODE
                                                </div>
                                                <MyInput id={"recipientsSwiftBic"}
                                                         name={"recipientsSwiftBic"}
                                                         touched={touched.recipientsSwiftBic}
                                                         value={values.recipientsSwiftBic}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsSwiftBic}
                                                />
                                                {errors.recipientsSwiftBic && touched.recipientsSwiftBic &&
                                                    <div className={s.error_message}>{errors.recipientsSwiftBic}</div>}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA MAD::Cash*/}
                            {
                                (values.paymentTypeByRegionForm === "MAD::Cash" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    selectable = {false}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IDENTITY CARD TYPE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"identityCardType"}
                                                    name={"identityCardType"}
                                                    touched={touched.identityCardType}
                                                    isError={errors.identityCardType && errors.identityCardType.name}
                                                    items={idTypesMorocco}
                                                />
                                                {errors.identityCardType && touched.identityCardType &&
                                                    <div className={s.error_message}>{errors.identityCardType.name}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IDENTITY CARD ID
                                                </div>
                                                <MyInput id={"identityCardId"}
                                                         name={"identityCardId"}
                                                         touched={touched.identityCardId}
                                                         value={values.identityCardId}
                                                         onChange={handleChange}
                                                         isError={errors.identityCardId}
                                                />
                                                {errors.identityCardId && touched.identityCardId &&
                                                    <div className={s.error_message}>{errors.identityCardId}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA XOF::Cash*/}
                            {
                                (values.paymentTypeByRegionForm === "XOF::Cash" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * COUNTRY
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"recipientCountry"}
                                                    name={"recipientCountry"}
                                                    touched={touched.recipientCountry}
                                                    isError={errors.recipientCountry && errors.recipientCountry.name}
                                                    items={xofCashCountries}
                                                />
                                                {errors.recipientCountry && touched.recipientCountry &&
                                                    <div className={s.error_message}>{errors.recipientCountry.name}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    selectable = {false}
                                                    onlyCountries={["sn"]}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>

                                        </div>

                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * CASH PROVIDER
                                                </div>
                                                <MyInput id={"cashProvider"}
                                                         name={"cashProvider"}
                                                         touched={touched.cashProvider}
                                                         value={"wizall"}
                                                         onChange={handleChange}
                                                         isError={errors.cashProvider}
                                                />
                                                {errors.cashProvider && touched.cashProvider &&
                                                    <div className={s.error_message}>{errors.cashProvider}</div>}
                                            </div>

                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA XOF::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "XOF::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * COUNTRY
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"recipientCountry"}
                                                name={"recipientCountry"}
                                                touched={touched.recipientCountry}
                                                isError={errors.recipientCountry && errors.recipientCountry.name}
                                                items={xofMobileCountries}
                                            />
                                            {errors.recipientCountry && touched.recipientCountry &&
                                                <div className={s.error_message}>{errors.recipientCountry.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * MOBILE PROVIDER
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"mobileProvider"}
                                                name={"mobileProvider"}
                                                touched={touched.mobileProvider}
                                                isError={errors.mobileProvider && errors.mobileProvider.name}
                                                items={getMobileProvider(values.recipientCountry?.name)}
                                            />
                                            {errors.mobileProvider && touched.mobileProvider &&
                                                <div className={s.error_message}>{errors.mobileProvider.name}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    selectable = {false}
                                                    onlyCountries={["sn"]}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>

                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA XOF::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "XOF::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IBAN
                                                </div>
                                                <MyInput id={"recipientsIban"}
                                                         name={"recipientsIban"}
                                                         touched={touched.recipientsIban}
                                                         value={values.recipientsIban}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsIban}
                                                         placeholder={"AA123 12345 123456789012 12"}
                                                />
                                                {errors.recipientsIban && touched.recipientsIban &&
                                                    <div className={s.error_message}>{errors.recipientsIban}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                   * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA XAF::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "XAF::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * LAST NAME
                                                </div>
                                                <MyInput id={"lastName"}
                                                         name={"lastName"}
                                                         touched={touched.lastName}
                                                         value={values.lastName}
                                                         onChange={handleChange}
                                                         isError={errors.lastName}
                                                />
                                                {errors.lastName && touched.lastName &&
                                                    <div className={s.error_message}>{errors.lastName}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IBAN
                                                </div>
                                                <MyInput id={"recipientsIban"}
                                                         name={"recipientsIban"}
                                                         touched={touched.recipientsIban}
                                                         value={values.recipientsIban}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsIban}
                                                         placeholder={"AA123 12345 123456789012 12"}
                                                />
                                                {errors.recipientsIban && touched.recipientsIban &&
                                                    <div className={s.error_message}>{errors.recipientsIban}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA XAF::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "XAF::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * MOBILE PROVIDER
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"mobileProvider"}
                                                    name={"mobileProvider"}
                                                    touched={touched.mobileProvider}
                                                    isError={errors.mobileProvider && errors.mobileProvider.name}
                                                    items={mobileProvidersXAF}
                                                />
                                                {errors.mobileProvider && touched.mobileProvider &&
                                                    <div className={s.error_message}>{errors.mobileProvider.name}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    selectable = {false}
                                                    onlyCountries={["cm"]}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA GNF::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "GNF::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * MOBILE PROVIDER
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"mobileProvider"}
                                                    name={"mobileProvider"}
                                                    touched={touched.mobileProvider}
                                                    isError={errors.mobileProvider && errors.mobileProvider.name}
                                                    items={mobileProvidersGuinea}
                                                />
                                                {errors.mobileProvider && touched.mobileProvider &&
                                                    <div className={s.error_message}>{errors.mobileProvider.name}</div>}
                                            </div>
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * Phone number
                                                </div>
                                                <PhoneCodesInput
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    selectable = {false}
                                                    onlyCountries={["gn"]}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA ZAR::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "ZAR::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    STREET
                                                </div>
                                                <MyInput id={"street"}
                                                         name={"street"}
                                                         touched={touched.street}
                                                         value={values.street}
                                                         onChange={handleChange}
                                                         isError={errors.street}
                                                />
                                                {errors.street && touched.street &&
                                                    <div className={s.error_message}>{errors.street}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                POSTAL CODE
                                            </div>
                                            <MyInput id={"postalCode"}
                                                     name={"postalCode"}
                                                     touched={touched.postalCode}
                                                     value={values.postalCode}
                                                     onChange={handleChange}
                                                     isError={errors.postalCode}
                                            />
                                            {errors.postalCode && touched.postalCode &&
                                                <div className={s.error_message}>{errors.postalCode}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    CITY
                                                </div>
                                                <MyInput id={"city"}
                                                         name={"city"}
                                                         touched={touched.city}
                                                         value={values.city}
                                                         onChange={handleChange}
                                                         isError={errors.city}
                                                />
                                                {errors.city && touched.city &&
                                                    <div className={s.error_message}>{errors.city}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * EMAIL
                                            </div>
                                            <MyInput id={"email"}
                                                     name={"email"}
                                                     touched={touched.email}
                                                     value={values.email}
                                                     onChange={handleChange}
                                                     isError={errors.email}
                                            />
                                            {errors.email && touched.email &&
                                                <div className={s.error_message}>{errors.email}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK CODE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankCode"}
                                                    name={"bankCode"}
                                                    touched={touched.bankCode}
                                                    isError={errors.bankCode && errors.bankCode.name}
                                                    items={bankCodeListZARBank}
                                                />
                                                {errors.bankCode && touched.bankCode &&
                                                    <div className={s.error_message}>{errors.bankCode.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"recipientsAccountNumber"}
                                                     name={"recipientsAccountNumber"}
                                                     touched={touched.recipientsAccountNumber}
                                                     value={values.recipientsAccountNumber}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsAccountNumber}
                                            />
                                            {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * PHONE NUMBER
                                                </div>
                                                <PhoneCodesInput
                                                    id='phoneNumber'
                                                    name='phoneNumber'
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                    error={errors.phoneNumber}
                                                    touched={touched.phoneNumber}
                                                    selectable = {false}
                                                    onlyCountries={["za"]}
                                                />
                                                {errors.phoneNumber &&
                                                    <div className={s.error_message}>{errors.phoneNumber}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                TRANSFER REASON
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"transferReason"}
                                                name={"transferReason"}
                                                touched={touched.transferReason}
                                                isError={errors.transferReason && errors.transferReason.name}
                                                items={transferReasons}
                                            />
                                            {errors.transferReason && touched.transferReason &&
                                                <div className={s.error_message}>{errors.transferReason.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA BWP::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "BWP::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK ACCOUNT
                                                </div>
                                                <MyInput id={"recipientsAccountNumber"}
                                                         name={"recipientsAccountNumber"}
                                                         touched={touched.recipientsAccountNumber}
                                                         value={values.recipientsAccountNumber}
                                                         onChange={handleChange}
                                                         isError={errors.recipientsAccountNumber}
                                                />
                                                {errors.recipientsAccountNumber && touched.recipientsAccountNumber &&
                                                    <div className={s.error_message}>{errors.recipientsAccountNumber}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK NAME
                                            </div>
                                            <MyInput id={"recipientsBank"}
                                                     name={"recipientsBank"}
                                                     touched={touched.recipientsBank}
                                                     value={values.recipientsBank}
                                                     onChange={handleChange}
                                                     isError={errors.recipientsBank}
                                            />
                                            {errors.recipientsBank && touched.recipientsBank &&
                                                <div className={s.error_message}>{errors.recipientsBank}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_block}>
                                                    <div className={s.input_label}>
                                                        * BRANCH CODE
                                                    </div>
                                                    <CustomDropdownForAzaTransactions
                                                        id={"branchCode"}
                                                        name={"branchCode"}
                                                        touched={touched.branchCode}
                                                        isError={errors.branchCode && errors.branchCode.name}
                                                        items={branchCodeListBWPBank}
                                                    />
                                                    {errors.branchCode && touched.branchCode &&
                                                        <div className={s.error_message}>{errors.branchCode.name}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/*PAN AFRICA PIX Payments*/}
                            {
                                (values.paymentTypeByRegionForm === "PIX Payments" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * CITY
                                                </div>
                                                <MyInput id={"city"}
                                                         name={"city"}
                                                         touched={touched.city}
                                                         value={values.city}
                                                         onChange={handleChange}
                                                         isError={errors.city}
                                                />
                                                {errors.city && touched.city &&
                                                    <div className={s.error_message}>{errors.city}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * POSTAL CODE
                                            </div>
                                            <MyInput id={"postalCode"}
                                                     name={"postalCode"}
                                                     touched={touched.postalCode}
                                                     value={values.postalCode}
                                                     onChange={handleChange}
                                                     isError={errors.postalCode}
                                            />
                                            {errors.postalCode && touched.postalCode &&
                                                <div className={s.error_message}>{errors.postalCode}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * PIX KEY TYPE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"pixKeyType"}
                                                    name={"pixKeyType"}
                                                    touched={touched.pixKeyType}
                                                    isError={errors.pixKeyType && errors.pixKeyType.name}
                                                    items={pixKeyTypesBrazil}
                                                />
                                                {errors.pixKeyType && touched.pixKeyType &&
                                                    <div className={s.error_message}>{errors.pixKeyType.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * PIX KEY VALUE
                                            </div>
                                            <MyInput id={"pixKeyValue"}
                                                     name={"pixKeyValue"}
                                                     touched={touched.pixKeyValue}
                                                     value={values.pixKeyValue}
                                                     onChange={handleChange}
                                                     isError={errors.pixKeyValue}
                                            />
                                            {errors.pixKeyValue && touched.pixKeyValue &&
                                                <div className={s.error_message}>{errors.pixKeyValue}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IDENTITY CARD ID
                                                </div>
                                                <MyInput id={"identityCardId"}
                                                         name={"identityCardId"}
                                                         touched={touched.identityCardId}
                                                         value={values.identityCardId}
                                                         onChange={handleChange}
                                                         isError={errors.identityCardId}
                                                />
                                                {errors.identityCardId && touched.identityCardId &&
                                                    <div className={s.error_message}>{errors.identityCardId}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * TRANSFER REASON
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"transferReason"}
                                                name={"transferReason"}
                                                touched={touched.transferReason}
                                                isError={errors.transferReason && errors.transferReason.name}
                                                items={transferReasons}
                                            />
                                            {errors.transferReason && touched.transferReason &&
                                                <div className={s.error_message}>{errors.transferReason.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA TED Payments*/}
                            {
                                (values.paymentTypeByRegionForm === "TED Payments" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * CITY
                                                </div>
                                                <MyInput id={"city"}
                                                         name={"city"}
                                                         touched={touched.city}
                                                         value={values.city}
                                                         onChange={handleChange}
                                                         isError={errors.city}
                                                />
                                                {errors.city && touched.city &&
                                                    <div className={s.error_message}>{errors.city}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * POSTAL CODE
                                            </div>
                                            <MyInput id={"postalCode"}
                                                     name={"postalCode"}
                                                     touched={touched.postalCode}
                                                     value={values.postalCode}
                                                     onChange={handleChange}
                                                     isError={errors.postalCode}
                                            />
                                            {errors.postalCode && touched.postalCode &&
                                                <div className={s.error_message}>{errors.postalCode}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK CODE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankCode"}
                                                    name={"bankCode"}
                                                    touched={touched.bankCode}
                                                    isError={errors.bankCode && errors.bankCode.name}
                                                    items={bankCodeListTEDPayments}
                                                />
                                                {errors.bankCode && touched.bankCode &&
                                                    <div className={s.error_message}>{errors.bankCode.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BRANCH CODE
                                            </div>
                                            <MyInput id={"branchCodeTED"}
                                                     name={"branchCodeTED"}
                                                     touched={touched.branchCodeTED}
                                                     value={values.branchCodeTED}
                                                     onChange={handleChange}
                                                     isError={errors.branchCodeTED}
                                            />
                                            {errors.branchCodeTED && touched.branchCodeTED &&
                                                <div className={s.error_message}>{errors.branchCodeTED}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK ACCOUNT
                                                </div>
                                                <MyInput id={"bankAccount"}
                                                         name={"bankAccount"}
                                                         touched={touched.bankAccount}
                                                         value={values.bankAccount}
                                                         onChange={handleChange}
                                                         isError={errors.bankAccount}
                                                />
                                                {errors.bankAccount && touched.bankAccount &&
                                                    <div className={s.error_message}>{errors.bankAccount}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT TYPE
                                                <Popover
                                                    content={"10 for savings, 20 for current, 30 for payment, 40 " +
                                                        "for salary"}
                                                    color={'#EEEFEF'}
                                                    overlayInnerStyle={{
                                                        fontFamily: 'Inter',
                                                        width: '280px',
                                                        padding: '13px 16px'
                                                    }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"bankAccountType"}
                                                name={"bankAccountType"}
                                                touched={touched.bankAccountType}
                                                isError={errors.bankAccountType && errors.bankAccountType.name}
                                                items={bankAccountTypesTED}
                                            />
                                            {errors.bankAccountType && touched.bankAccountType &&
                                                <div className={s.error_message}>{errors.bankAccountType.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IDENTITY CARD ID
                                            </div>
                                            <MyInput id={"identityCardId"}
                                                     name={"identityCardId"}
                                                     touched={touched.identityCardId}
                                                     value={values.identityCardId}
                                                     onChange={handleChange}
                                                     isError={errors.identityCardId}
                                            />
                                            {errors.identityCardId && touched.identityCardId &&
                                                <div className={s.error_message}>{errors.identityCardId}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * TRANSFER REASON
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"transferReason"}
                                                name={"transferReason"}
                                                touched={touched.transferReason}
                                                isError={errors.transferReason && errors.transferReason.name}
                                                items={transferReasons}
                                            />
                                            {errors.transferReason && touched.transferReason &&
                                                <div className={s.error_message}>{errors.transferReason.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA ZMW::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "ZMW::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK ACCOUNT
                                                </div>
                                                <MyInput id={"bankAccount"}
                                                         name={"bankAccount"}
                                                         touched={touched.bankAccount}
                                                         value={values.bankAccount}
                                                         onChange={handleChange}
                                                         isError={errors.bankAccount}
                                                />
                                                {errors.bankAccount && touched.bankAccount &&
                                                    <div className={s.error_message}>{errors.bankAccount}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BRANCH CODE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"branchCode"}
                                                name={"branchCode"}
                                                touched={touched.branchCode}
                                                isError={errors.branchCode && errors.branchCode.name}
                                                items={branchCodeListZMWBank}
                                            />
                                            {errors.branchCode && touched.branchCode &&
                                                <div className={s.error_message}>{errors.branchCode.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA KES::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "KES::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Sender’s Details
                                    </div>
                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IDENTIFICATION TYPE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"senderIdType"}
                                                name={"senderIdType"}
                                                touched={touched.senderIdType}
                                                isError={errors.senderIdType && errors.senderIdType.name}
                                                items={idTypesKenya}
                                            />
                                            {errors.senderIdType && touched.senderIdType &&
                                                <div className={s.error_message}>{errors.senderIdType.name}</div>}

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IDENTIFICATION NUMBER
                                                </div>
                                                <MyInput id={"senderIdNumber"}
                                                         name={"senderIdNumber"}
                                                         touched={touched.senderIdNumber}
                                                         value={values.senderIdNumber}
                                                         onChange={handleChange}
                                                         isError={errors.senderIdNumber}
                                                />
                                                {errors.senderIdNumber && touched.senderIdNumber &&
                                                    <div className={s.error_message}>{errors.senderIdNumber}</div>}

                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * STREET
                                                </div>
                                                <MyInput id={"street"}
                                                         name={"street"}
                                                         touched={touched.street}
                                                         value={values.street}
                                                         onChange={handleChange}
                                                         isError={errors.street}
                                                />
                                                {errors.street && touched.street &&
                                                    <div className={s.error_message}>{errors.street}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                CITY
                                            </div>
                                            <MyInput id={"city"}
                                                     name={"city"}
                                                     touched={touched.city}
                                                     value={values.city}
                                                     onChange={handleChange}
                                                     isError={errors.city}
                                            />
                                            {errors.city && touched.city &&
                                                <div className={s.error_message}>{errors.city}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK CODE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankCode"}
                                                    name={"bankCode"}
                                                    touched={touched.bankCode}
                                                    isError={errors.bankCode && errors.bankCode.name}
                                                    items={bankCodeListKESBank}
                                                />
                                                {errors.bankCode && touched.bankCode &&
                                                    <div className={s.error_message}>{errors.bankCode.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    IDENTITY CARD TYPE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"identityCardType"}
                                                    name={"identityCardType"}
                                                    touched={touched.identityCardType}
                                                    isError={errors.identityCardType && errors.identityCardType.name}
                                                    items={idTypesKenya}
                                                />
                                                {errors.identityCardType && touched.identityCardType &&
                                                    <div className={s.error_message}>{errors.identityCardType.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                IDENTITY CARD ID
                                            </div>
                                            <MyInput id={"identityCardId"}
                                                     name={"identityCardId"}
                                                     touched={touched.identityCardId}
                                                     value={values.identityCardId}
                                                     onChange={handleChange}
                                                     isError={errors.identityCardId}
                                            />
                                            {errors.identityCardId && touched.identityCardId &&
                                                <div className={s.error_message}>{errors.identityCardId}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                RELATIONSHIP TO SENDER
                                            </div>
                                            <MyInput id={"relationshipToSender"}
                                                     name={"relationshipToSender"}
                                                     touched={touched.relationshipToSender}
                                                     value={values.relationshipToSender}
                                                     onChange={handleChange}
                                                     isError={errors.relationshipToSender}
                                            />
                                            {errors.relationshipToSender && touched.relationshipToSender &&
                                                <div className={s.error_message}>{errors.relationshipToSender}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA KES::Mobile*/}
                            {
                                (values.paymentTypeByRegionForm === "KES::Mobile" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Sender’s Details
                                    </div>
                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IDENTIFICATION TYPE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"senderIdType"}
                                                name={"senderIdType"}
                                                touched={touched.senderIdType}
                                                isError={errors.senderIdType && errors.senderIdType.name}
                                                items={idTypesKenya}
                                            />
                                            {errors.senderIdType && touched.senderIdType &&
                                                <div className={s.error_message}>{errors.senderIdType.name}</div>}

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IDENTIFICATION NUMBER
                                                </div>
                                                <MyInput id={"senderIdNumber"}
                                                         name={"senderIdNumber"}
                                                         touched={touched.senderIdNumber}
                                                         value={values.senderIdNumber}
                                                         onChange={handleChange}
                                                         isError={errors.senderIdNumber}
                                                />
                                                {errors.senderIdNumber && touched.senderIdNumber &&
                                                    <div className={s.error_message}>{errors.senderIdNumber}</div>}

                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.title}>
                                        Sender’s Details
                                    </div>
                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IDENTIFICATION TYPE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"senderIdType"}
                                                name={"senderIdType"}
                                                touched={touched.senderIdType}
                                                isError={errors.senderIdType && errors.senderIdType.name}
                                                items={idTypesKenya}
                                            />
                                            {errors.senderIdType && touched.senderIdType &&
                                                <div className={s.error_message}>{errors.senderIdType.name}</div>}

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IDENTIFICATION NUMBER
                                                </div>
                                                <MyInput id={"senderIdNumber"}
                                                         name={"senderIdNumber"}
                                                         touched={touched.senderIdNumber}
                                                         value={values.senderIdNumber}
                                                         onChange={handleChange}
                                                         isError={errors.senderIdNumber}
                                                />
                                                {errors.senderIdNumber && touched.senderIdNumber &&
                                                    <div className={s.error_message}>{errors.senderIdNumber}</div>}

                                            </div>
                                        </div>
                                    </div>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * STREET
                                            </div>
                                            <MyInput id={"street"}
                                                     name={"street"}
                                                     touched={touched.street}
                                                     value={values.street}
                                                     onChange={handleChange}
                                                     isError={errors.street}
                                            />
                                            {errors.street && touched.street &&
                                                <div className={s.error_message}>{errors.street}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    CITY
                                                </div>
                                                <MyInput id={"city"}
                                                         name={"city"}
                                                         touched={touched.city}
                                                         value={values.city}
                                                         onChange={handleChange}
                                                         isError={errors.city}
                                                />
                                                {errors.city && touched.city &&
                                                    <div className={s.error_message}>{errors.city}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Phone number
                                            </div>
                                            <PhoneCodesInput
                                                id='phoneNumber'
                                                name='phoneNumber'
                                                onChange={handleChange}
                                                value={values.phoneNumber}
                                                error={errors.phoneNumber}
                                                touched={touched.phoneNumber}
                                                selectable = {false}
                                                onlyCountries={["ke"]}
                                            />
                                            {errors.phoneNumber &&
                                                <div className={s.error_message}>{errors.phoneNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IDENTITY CARD TYPE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"identityCardType"}
                                                    name={"identityCardType"}
                                                    touched={touched.identityCardType}
                                                    isError={errors.identityCardType && errors.identityCardType.name}
                                                    items={idTypesKenya}
                                                />
                                                {errors.identityCardType && touched.identityCardType &&
                                                    <div className={s.error_message}>{errors.identityCardType.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IDENTITY CARD ID
                                            </div>
                                            <MyInput id={"identityCardId"}
                                                     name={"identityCardId"}
                                                     touched={touched.identityCardId}
                                                     value={values.identityCardId}
                                                     onChange={handleChange}
                                                     isError={errors.identityCardId}
                                            />
                                            {errors.identityCardId && touched.identityCardId &&
                                                <div className={s.error_message}>{errors.identityCardId}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * MOBILE PROVIDER
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"mobileProvider"}
                                                name={"mobileProvider"}
                                                touched={touched.mobileProvider}
                                                isError={errors.mobileProvider && errors.mobileProvider.name}
                                                items={mobileProvidersKenya}
                                            />
                                            {errors.mobileProvider && touched.mobileProvider &&
                                                <div className={s.error_message}>{errors.mobileProvider.name}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    RELATIONSHIP TO SENDER
                                                </div>
                                                <MyInput id={"relationshipToSender"}
                                                         name={"relationshipToSender"}
                                                         touched={touched.relationshipToSender}
                                                         value={values.relationshipToSender}
                                                         onChange={handleChange}
                                                         isError={errors.relationshipToSender}
                                                />
                                                {errors.relationshipToSender && touched.relationshipToSender &&
                                                    <div
                                                        className={s.error_message}>{errors.relationshipToSender}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*PAN AFRICA EGP::Bank*/}
                            {
                                (values.paymentTypeByRegionForm === "EGP::Bank" && values.paymentRegions === "PAN AFRICA") &&
                                <div className={s.block}>
                                    {/*<div className={s.title}>*/}
                                    {/*    Sender’s Details*/}
                                    {/*</div>*/}
                                    {/*<div className={s.row}>*/}
                                    {/*    <div className={s.input_block}>*/}
                                    {/*        <div className={s.input_label}>*/}
                                    {/*            * IDENTIFICATION TYPE*/}
                                    {/*        </div>*/}
                                    {/*        <CustomDropdownForAzaTransactions*/}
                                    {/*            id={"senderIdType"}*/}
                                    {/*            name={"senderIdType"}*/}
                                    {/*            touched={touched.senderIdType}*/}
                                    {/*            isError={errors.senderIdType && errors.senderIdType.name}*/}
                                    {/*            items={idTypesKenya}*/}
                                    {/*        />*/}
                                    {/*        {errors.senderIdType && touched.senderIdType &&*/}
                                    {/*            <div className={s.error_message}>{errors.senderIdType.name}</div>}*/}

                                    {/*    </div>*/}

                                    {/*    <div className={s.input_block}>*/}
                                    {/*        <div className={s.input_block}>*/}
                                    {/*            <div className={s.input_label}>*/}
                                    {/*                * IDENTIFICATION NUMBER*/}
                                    {/*            </div>*/}
                                    {/*            <MyInput id={"senderIdNumber"}*/}
                                    {/*                     name={"senderIdNumber"}*/}
                                    {/*                     touched={touched.senderIdNumber}*/}
                                    {/*                     value={values.senderIdNumber}*/}
                                    {/*                     onChange={handleChange}*/}
                                    {/*                     isError={errors.senderIdNumber}*/}
                                    {/*            />*/}
                                    {/*            {errors.senderIdNumber && touched.senderIdNumber &&*/}
                                    {/*                <div className={s.error_message}>{errors.senderIdNumber}</div>}*/}

                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className={s.title}>
                                        Sender’s Details
                                    </div>
                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * IDENTIFICATION TYPE
                                            </div>
                                            <CustomDropdownForAzaTransactions
                                                id={"senderIdType"}
                                                name={"senderIdType"}
                                                touched={touched.senderIdType}
                                                isError={errors.senderIdType && errors.senderIdType.name}
                                                items={idTypesKenya}
                                            />
                                            {errors.senderIdType && touched.senderIdType &&
                                                <div className={s.error_message}>{errors.senderIdType.name}</div>}

                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * IDENTIFICATION NUMBER
                                                </div>
                                                <MyInput id={"senderIdNumber"}
                                                         name={"senderIdNumber"}
                                                         touched={touched.senderIdNumber}
                                                         value={values.senderIdNumber}
                                                         onChange={handleChange}
                                                         isError={errors.senderIdNumber}
                                                />
                                                {errors.senderIdNumber && touched.senderIdNumber &&
                                                    <div className={s.error_message}>{errors.senderIdNumber}</div>}

                                            </div>
                                        </div>
                                    </div>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * FIRST NAME
                                            </div>
                                            <MyInput id={"firstName"}
                                                     name={"firstName"}
                                                     touched={touched.firstName}
                                                     value={values.firstName}
                                                     onChange={handleChange}
                                                     isError={errors.firstName}
                                            />
                                            {errors.firstName && touched.firstName &&
                                                <div className={s.error_message}>{errors.firstName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                MIDDLE NAME
                                            </div>
                                            <MyInput id={"middleName"}
                                                     name={"middleName"}
                                                     touched={touched.middleName}
                                                     value={values.middleName}
                                                     onChange={handleChange}
                                                     isError={errors.middleName}
                                            />
                                            {errors.middleName && touched.middleName &&
                                                <div className={s.error_message}>{errors.middleName}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * LAST NAME
                                            </div>
                                            <MyInput id={"lastName"}
                                                     name={"lastName"}
                                                     touched={touched.lastName}
                                                     value={values.lastName}
                                                     onChange={handleChange}
                                                     isError={errors.lastName}
                                            />
                                            {errors.lastName && touched.lastName &&
                                                <div className={s.error_message}>{errors.lastName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * STREET
                                                </div>
                                                <MyInput id={"street"}
                                                         name={"street"}
                                                         touched={touched.street}
                                                         value={values.street}
                                                         onChange={handleChange}
                                                         isError={errors.street}
                                                />
                                                {errors.street && touched.street &&
                                                    <div className={s.error_message}>{errors.street}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * PHONE NUMBER
                                            </div>
                                            <PhoneCodesInput
                                                id='phoneNumber'
                                                name='phoneNumber'
                                                onChange={handleChange}
                                                value={values.phoneNumber}
                                                error={errors.phoneNumber}
                                                touched={touched.phoneNumber}
                                                selectable = {false}
                                                onlyCountries={["eg"]}
                                            />
                                            {errors.phoneNumber &&
                                                <div className={s.error_message}>{errors.phoneNumber}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * BANK CODE
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"bankCode"}
                                                    name={"bankCode"}
                                                    touched={touched.bankCode}
                                                    isError={errors.bankCode && errors.bankCode.name}
                                                    items={bankCodeListEGPBank}
                                                />
                                                {errors.bankCode && touched.bankCode &&
                                                    <div className={s.error_message}>{errors.bankCode.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * BANK ACCOUNT
                                            </div>
                                            <MyInput id={"bankAccount"}
                                                     name={"bankAccount"}
                                                     touched={touched.bankAccount}
                                                     value={values.bankAccount}
                                                     onChange={handleChange}
                                                     isError={errors.bankAccount}
                                            />
                                            {errors.bankAccount && touched.bankAccount &&
                                                <div className={s.error_message}>{errors.bankAccount}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_block}>
                                                <div className={s.input_label}>
                                                    * TRANSFER REASON
                                                </div>
                                                <CustomDropdownForAzaTransactions
                                                    id={"transferReason"}
                                                    name={"transferReason"}
                                                    touched={touched.transferReason}
                                                    isError={errors.transferReason && errors.transferReason.name}
                                                    items={transferReasons}
                                                />
                                                {errors.transferReason && touched.transferReason &&
                                                    <div className={s.error_message}>{errors.transferReason.name}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                            {/*INTERAC (only for CAD)*/}
                            {
                                values.paymentRegions === "INTERAC (only for CAD)" &&
                                <div className={s.block}>
                                    <div className={s.title}>
                                        Recipient’s Details
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary Name
                                            </div>
                                            <MyInput id={"beneficiaryName"}
                                                     name={"beneficiaryName"}
                                                     touched={touched.beneficiaryName}
                                                     value={values.beneficiaryName}
                                                     onChange={handleChange}
                                                     isError={errors.beneficiaryName}
                                            />
                                            {errors.beneficiaryName && touched.beneficiaryName &&
                                                <div className={s.error_message}>{errors.beneficiaryName}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Beneficiary Email
                                            </div>
                                            <MyInput id={"beneficiaryEmail"}
                                                     name={"beneficiaryEmail"}
                                                     touched={touched.beneficiaryEmail}
                                                     value={values.beneficiaryEmail}
                                                     onChange={handleChange}
                                                     isError={errors.beneficiaryEmail}
                                            />
                                            {errors.beneficiaryEmail && touched.beneficiaryEmail &&
                                                <div className={s.error_message}>{errors.beneficiaryEmail}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Security Question
                                            </div>
                                            <MyInput id={"securityQuestion"}
                                                     name={"securityQuestion"}
                                                     touched={touched.securityQuestion}
                                                     value={values.securityQuestion}
                                                     onChange={handleChange}
                                                     isError={errors.securityQuestion}
                                            />
                                            {errors.securityQuestion && touched.securityQuestion &&
                                                <div className={s.error_message}>{errors.securityQuestion}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                * Security Answer
                                            </div>
                                            <MyInput id={"securityAnswer"}
                                                     name={"securityAnswer"}
                                                     touched={touched.securityAnswer}
                                                     value={values.securityAnswer}
                                                     onChange={handleChange}
                                                     isError={errors.securityAnswer}
                                            />
                                            {errors.securityAnswer && touched.securityAnswer &&
                                                <div className={s.error_message}>{errors.securityAnswer}</div>}
                                        </div>
                                    </div>

                                    <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                Unique id
                                            </div>
                                            <MyInput id={"uniqueId"}
                                                     name={"uniqueId"}
                                                     touched={touched.uniqueId}
                                                     value={values.uniqueId}
                                                     onChange={handleChange}
                                                     isError={errors.uniqueId}
                                            />
                                            {errors.uniqueId && touched.uniqueId &&
                                                <div className={s.error_message}>{errors.uniqueId}</div>}
                                        </div>

                                        <div className={s.input_block}>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className={s.block}>
                                <div className={s.title}>
                                    Transfer Details
                                </div>

                                {props.id !== 'create' && <div className={s.row}>
                                    <div className={s.input_block_fixed}>
                                        <div className={s.input_label}>
                                            * Payment date
                                            <Popover
                                                content={"Please enter the date of the transfer. The date can be 2 years ahead from today."}
                                                color={'#EEEFEF'}
                                                overlayInnerStyle={{
                                                    fontFamily: 'Inter',
                                                    width: '280px',
                                                    padding: '13px 16px'
                                                }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        <DatePicker suffixIcon={null} style={dateStyle}
                                                    defaultValue={dayjs(new Date(), dateFormat)}
                                                    disabledDate={disabledDate}
                                                    value={dayjs(date.from)}
                                                    onChange={(_, dateString) => {
                                                        if (!dateString) {
                                                            setDate({...date, from: new Date().toDateString()})
                                                        } else {
                                                            setDate({...date, from: dateString})
                                                        }
                                                    }}
                                                    format={dateFormat}/>
                                    </div>

                                    <div className={s.mobile_row}>
                                        <div className={classnames(s.input_block, s.short)}>
                                            <div className={s.input_label}>
                                                * Amount
                                                <Popover content={"Select a currency and enter the transfer amount.\n" +
                                                    "In the account balance in the selected currency is insufficient, the system " +
                                                    "will automatically offer you to top up using funds in a different currency " +
                                                    "you have on the account."}
                                                         color={'#EEEFEF'}
                                                         overlayInnerStyle={{
                                                             fontFamily: 'Inter',
                                                             width: '280px',
                                                             padding: '13px 16px'
                                                         }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                            <MyInput id={"amount"}
                                                     name={"amount"}
                                                     touched={touched.amount}
                                                     value={values.amount}
                                                     onChange={handleChange}
                                                     isError={errors.amount}
                                            />
                                            {errors.amount && touched.amount &&
                                                <div className={classnames(s.error_message, s.error_amount)}>{errors.amount}</div>}
                                        </div>

                                        <div className={s.currency_dropdown}>
                                            <div className={s.input_label}>
                                                Currency
                                            </div>
                                            <MyInput
                                                id="transferCurrency"
                                                name="transferCurrency"
                                                value={currency}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>


                                </div>}

                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            ** Purpose
                                        </div>
                                        <MyTextarea
                                            id={"purpose"}
                                            name={"purpose"}
                                            onChange={handleChange}
                                            value={values.purpose}
                                            placeholder={"Enter your purpose of payment"}
                                            isError={errors.purpose}
                                            touched={touched.purpose}
                                        />
                                        {errors.purpose && touched.purpose &&
                                            <div className={s.error_message}>{errors.purpose}</div>}
                                    </div>
                                </div>

                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            ** reference
                                        </div>
                                        <MyInput id={"reference"}
                                                 name={"reference"}
                                                 touched={touched.reference}
                                                 value={values.reference}
                                                 onChange={handleChange}
                                                 placeholder={"Enter if required by the recipient"}
                                                 isError={errors.reference}
                                        />
                                        {errors.reference && touched.reference &&
                                            <div className={s.error_message}>{errors.reference}</div>}
                                    </div>
                                    <div className={s.input_block}>

                                    </div>
                                </div>

                                {   values.paymentRegions === "INTERAC (only for CAD)" &&
                                    <div className={s.notice}>
                                        Maximum Transaction Size: $10000.0
                                    </div>
                                }

                                {
                                    (
                                        values.paymentRegions === "SWIFT (International Transfer)" ||
                                        values.paymentRegions === "SEPA (only for EUR)" ||
                                        values.paymentRegions === "PAN AFRICA"
                                    )   && <div className={s.row}>
                                        <div className={s.input_block}>
                                            <div className={s.input_label}>
                                                payment type
                                                <Popover content={<div>
                                                    <p>
                                                        SHA - the payer (you) pays all the fees applied to the transfer, and
                                                        fees
                                                        applied by other banks are paid by the recipient (in some cases the
                                                        recipient can simply receive a smaller amount).
                                                    </p>
                                                    <p>
                                                        OUR - the payer (you) pays all the fees including the fees of other
                                                        banks
                                                        applied to this transfer. If the paid fee does not cover all the
                                                        fees of
                                                        a correspondent bank and the recipient’s bank, the missing amount
                                                        must
                                                        be paid additionally. Additional fees of other banks may also be
                                                        deducted
                                                        from the transferred amount (frequent cases with transfers in USD).
                                                    </p>
                                                </div>}
                                                         color={'#EEEFEF'}
                                                         overlayInnerStyle={{
                                                             fontFamily: 'Inter',
                                                             width: '280px',
                                                             padding: '13px 16px'
                                                         }}
                                                >
                                                    <img src={info} alt=""/>
                                                </Popover>
                                            </div>
                                                <div className={s.flex_label}>
                                                    <label className={s.flex_label}>
                                                        <CustomCheckbox
                                                            handleChange={() => setPaymentType("SHA")}
                                                            id="SHA"
                                                            name="SHA"
                                                            isChecked={paymentType === "SHA"}
                                                        />
                                                        <span
                                                            className={paymentType === "SHA" ? s.type_of_payment_active : s.type_of_payment_inactive}>SHA</span>
                                                    </label>
                                                    <label className={s.flex_label}>
                                                        <CustomCheckbox
                                                            handleChange={() => setPaymentType("OUR")}
                                                            id="OUR"
                                                            name="OUR"
                                                            isChecked={paymentType === "OUR"}
                                                        />
                                                        <span
                                                            className={paymentType === "OUR" ? s.type_of_payment_active : s.type_of_payment_inactive}>OUR</span>
                                                    </label>
                                                </div>
                                        </div>
                                    </div>
                                }

                            </div>

                            {!props.id && <div className={s.block}>
                                <div className={s.title}>
                                    Save the Transfer Details as a Template
                                </div>

                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            template Name
                                            <Popover
                                                content={"In order to save this transfer as a template, please enter the " +
                                                    "template name."}
                                                color={'#EEEFEF'}
                                                overlayInnerStyle={{
                                                    fontFamily: 'Inter',
                                                    width: '280px',
                                                    padding: '13px 16px'
                                                }}
                                            >
                                                <img src={info} alt=""/>
                                            </Popover>
                                        </div>
                                        <MyInput id={"templateName"}
                                                 name={"templateName"}
                                                 touched={touched.templateName}
                                                 value={values.templateName}
                                                 onChange={handleChange}
                                        />
                                        {errors.templateName && touched.templateName &&
                                            <div className={s.error_message}>{errors.templateName}</div>}
                                    </div>

                                    <div className={s.input_block}>
                                    </div>
                                </div>
                            </div>}

                            {/*{*/}
                            {/*    !props.id &&*/}
                            {/*    <div className={s.add_dock_block}>*/}
                            {/*        <div className={classnames(s.row, s.flex_start)}>*/}
                            {/*            <div className={s.invoice_img}>*/}
                            {/*                <img src={invoice} alt=""/>*/}
                            {/*            </div>*/}
                            {/*            <div>*/}
                            {/*                <p className={s.invoice_title}>Add an invoice (optional)</p>*/}
                            {/*                <p className={s.invoice_desc}>Appropriate format: JPEG, JPG, PNG, TXT, PDF.</p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className={s.attached_file_wrapper}>*/}
                            {/*            <div className={s.button_wrapper}>*/}
                            {/*                <TransparentButton*/}
                            {/*                    title={"Add file"}*/}
                            {/*                    small*/}
                            {/*                    icon={<Icon component={() => <Plus/>} rev={undefined}/>}*/}
                            {/*                    onClick={(e: any) => {*/}
                            {/*                        e.preventDefault()*/}
                            {/*                        // @ts-ignore*/}
                            {/*                        document.getElementById('hiddenInvoice').click()*/}
                            {/*                    }}*/}
                            {/*                />*/}
                            {/*                <input type="file"*/}
                            {/*                       accept=".png, .jpg, .jpeg, .pdf, .txt"*/}
                            {/*                       id={"hiddenInvoice"}*/}
                            {/*                       className={s.hidden_input}*/}
                            {/*                       onChange={(event) => {*/}
                            {/*                           // @ts-ignore*/}
                            {/*                           setFieldValue("invoice", event.currentTarget.files[0]);*/}
                            {/*                       }}*/}
                            {/*                />*/}
                            {/*            </div>*/}
                            {/*            <div className={s.attached_file}>*/}
                            {/*                {*/}
                            {/*                    values.invoice && <img src={attachedDoc} alt=""/>*/}
                            {/*                }*/}
                            {/*                {*/}
                            {/*                    // @ts-ignore*/}
                            {/*                    <span className={s.invoice_doc_name}>{values?.invoice?.name}</span>*/}
                            {/*                }*/}
                            {/*                {*/}
                            {/*                    // @ts-ignore*/}
                            {/*                    values?.invoice?.name &&*/}
                            {/*                    <img onClick={() => {*/}
                            {/*                        // @ts-ignore*/}
                            {/*                        document.getElementById("hiddenInvoice").value = ""*/}
                            {/*                        setFieldValue("invoice", "")*/}
                            {/*                    }}*/}
                            {/*                         className={s.delete_doc}*/}
                            {/*                         src={delete_document}*/}
                            {/*                         alt=""*/}
                            {/*                    />*/}
                            {/*                }*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*}*/}

                            <div className={s.continue_block}>
                                <div className={s.required}>
                                    <div>
                                        * required fields.
                                    </div>
                                    <div>
                                        ** one of the fields is required.
                                    </div>
                                </div>
                                {!props.id && <div className={s.button_wrapper}>
                                    <MyBtn title={"Continue"} type={"submit"}/>
                                </div>}
                            </div>

                            <Persist name="transfer-form"/>

                            <CurrencyHelper
                                accountsList={accountsList}
                                payerAccount={values.payerAccount}
                                setCurrency={setCurrency}
                            />

                            <PaymentTypeHelper name={"paymentType"}/>
                            <PayerAccountHelper name={"payerAccount"}/>

                        </form>
                    )}
                </Formik>
            </motion.div>
        </div>
    );
};

export default BankTransferFormPersonal;
