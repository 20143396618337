import React, {Dispatch, SetStateAction, useState} from 'react';
import s from "./CardsStepOne.module.css";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import VirtualCardRequest from "../../../../components/Card/VirtualCardRequest/VirtualCardRequest";

type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

const CardsStepOne = (props: PropsType) => {

    const [showForm, setShowForm] = useState(false)

    const handleCancelClick = () => {
        setShowForm(false);
    };

    const handleSuccessClick = () => {
        props.setCurrent(1);
    };


    return <>
        {!showForm && <div className={s.createCardWrapper}>
            <div className={s.cardIcon}>
            </div>
            <p className={s.cardsText}>NO CARDS</p>
            <div className={s.buttonWrapper}>
                <MyBtn
                    title={'+ Add Card'}
                    large
                    onClick={() => setShowForm(true)}
                    type={"submit"}/>
            </div>
        </div>}

        {showForm && <VirtualCardRequest onCancelClick={handleCancelClick} onSuccessClick={handleSuccessClick}/>}
    </>;
};

export default CardsStepOne;