import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import s from "../../../styles/styles.module.css";
import {motion} from "framer-motion";
import BankTransfer from "./BankTransfer/BankTransfer";
import ListOfTransfers from "./ListOfTransfers/ListOfTransfers";
import ToCompanyName from "./ToCompanyName/ToCompanyName";

const Transfers = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <Tabs>
                <TabList className={s.tabstitle_block}>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>Bank Transfer</Tab>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>To a Company Name Transfer</Tab>
                    <Tab selectedClassName={s.active_tab} className={s.tabtitle}>List of Transfers</Tab>
                </TabList>

                <TabPanel>
                    <BankTransfer/>
                </TabPanel>
                <TabPanel>
                    <ToCompanyName/>
                </TabPanel>
                <TabPanel>
                    <ListOfTransfers/>
                </TabPanel>
            </Tabs>
        </motion.div>
    );
};

export default Transfers;

