import React from 'react';
import s from "../LegalAndPrivacy.module.css";

const SecondPanel = () => {
    return (
        <div className={s.collapse_text}>

            <div className={s.point_text}>
                At CompanyName Ltd, we take pride in our service and are committed to providing you with the best customer
                experience that we can. If you have a complaint or concern, please follow our easy 3-step Customer
                Complaint Handling Procedures (CCHP) so that we can work with you to resolve it as quickly and
                efficiently as possible.
            </div>

            <div className={s.point_title}>
                Step 1: Talk to our Unit Representative
            </div>

            <div className={s.point_text}>
                Many complaints can be resolved at the first point of contact. If you have a complaint, talk to the
                representative of the unit that you have been dealing with. If the business unit representative is not
                able to resolve your complaint to your satisfaction, your complaint can be referred to that individual’s
                manager, who has the authority to resolve, in a timely manner, most complaints that arise. You can reach
                your business unit representative by:
            </div>

            <div className={s.point_subtitle}>
                Ibernanco Ltd:
            </div>

            <div className={s.point_text}>
                <b>Telephone</b>: 333-333-333. <br/>
                <b>Email</b>: <a className={s.point_link}
                                 href="mailto:info@CompanyName.com">info@CompanyName.com</a>, as applicable<br/>
                <b>Mail</b>: Customer Service, CompanyName.<br/>
                CompanyName address
            </div>

            <div className={s.point_title}>
                Step 2: Contact the Business Unit Head
            </div>

            <div className={s.point_text}>
                If the business unit representative or that individual’s Manager is not able to resolve your complaint
                within 14 calendar days following the date on which we first received your complaint, we will
                automatically escalate it to the Business Unit Head (or delegate). Also, if your complaint is not
                resolved to your satisfaction in Step 1, you can escalate it to the Business Unit Head by:
            </div>

            <div className={s.point_text}>
                <b>Complaints Hotline</b>: 333-333-333. <br/>
                <b>Email</b>: <a className={s.point_link}
                                 href="mailto:info@CompanyName.com">info@CompanyName.com</a>, as applicable<br/>
                <b>Mail</b>: Customer Service, CompanyName.<br/>
                CompanyName address
            </div>

            <div className={s.point_title}>
                Step 3: Write to the Dispute Resolution Office (DRO)
            </div>

            <div className={s.point_text}>
                If the Business Unit Head (or delegate) is not able to resolve the complaint to your satisfaction, you
                can escalate it to <b>CompanyName</b>’s Dispute Resolution Office. Working closely with both customers
                and
                <b> CompanyName</b>’s relevant business units, the DRO independently conducts a full investigation of
                customer
                complaints, subject to certain limitations, and is committed to providing an impartial review to help
                customers and <b>CompanyName</b> reach a fair, reasonable and transparent resolution. If your
                complaint has
                not been resolved within the first two steps of our CCHP, you may wish to submit your complaint in
                writing to the DRO. The DRO is headed by <b>CompanyName</b>’s Chief Compliance Officer.
            </div>

            <div className={s.point_text}>
                <b>Email</b>: <a className={s.point_link}
                                 href="mailto:info@CompanyName.com">info@CompanyName.com</a> subject line DRO office
                compliants <br/>
                <b>Mail</b>: Dispute Resolution Office, CompanyName.<br/>
                CompanyName address
            </div>

            <div className={s.point_text}>
                You will receive an acknowledgement of your written complaint within 48 hours of it being received. If
                your complaint is within the DRO’s mandate and has been through the first two steps of the CCHP, a full
                investigation will be undertaken. Most investigations are concluded within 4 - 6 weeks and you will be
                provided with a final resolution/recommendation in writing. Any recommendations made are non-binding and
                parties are free to accept or reject them and pursue other options for resolution. , you may refer your
                complaint in writing to CompanyName’s Dispute Resolution Office, Step 3 of CompanyName’s Customer Complaint
                Handling Procedure, as described above.
            </div>

            <div className={s.point_title}>
                Additional Resources - External Agencies
            </div>

            <div className={s.point_text}>
                If you require further information or are still not satisfied after receiving our Dispute Resolution
                Office’s decision, the following external agencies can provide you with information and a further review
                of your complaint.
            </div>

            <div className={s.point_title}>
                Ombudsman for Banking Services and Investments (OBSI)
            </div>

            <div className={s.point_text}>
                If you are not satisfied with the final recommendation made by our Dispute Resolution Office, you can
                refer your complaint to the OBSI, an independent external complaints body, for further review. If you
                choose to contact the OBSI, please do so within 180 calendar days from the date you received a response
                from CompanyName’s Dispute Resolution Office. You may also contact OBSI if it has been more than 56
                calendar days from the day you made the complaint to CompanyName and you have yet to receive a final
                response.
            </div>

            <div className={s.point_text}>
                <b>Telephone</b>: In Toronto at 416-287-2877 <br/>
                Toll-free at 1-888-451-4519 <br/>
                <b>Online Form</b>: Through the OBSI website at
                <a className={s.point_link} href="https://www.obsi.ca/en/index.aspx" target="_blank"> www.obsi.ca </a>
                or email to <a className={s.point_link} href="mailto:ombudsman@obsi.ca"> ombudsman@obsi.ca </a> <br/>
                <b>Mail</b>: Ombudsman for Banking Services and Investments <br/>
                20 Queen Street West, Suite 2400 <br/>
                P.O. Box 8 Toronto, Ontario <br/>
                M5H 3R3
            </div>

            <div className={s.point_title}>
                Financial Consumer Agency of Canada (FCAC)
            </div>

            <div className={s.point_text}>
                The FCAC supervises federally regulated financial institutions to ensure they comply with federal
                consumer protection laws and voluntary codes of conduct and public commitments. For example, financial
                institutions must provide consumers with information about fees, interest rates and complaint-handling
                procedures. If you have a complaint regarding a potential violation of a consumer provision, voluntary
                code of conduct or public commitment, you may, at any time, contact the FCAC. Please note that the FCAC
                does not become involved in matters of redress or compensation.
            </div>

            <div className={s.point_text}>
                <b>Telephone</b>: Toll-free at 1-866-461-3222 <br/>
                <b>Online Form</b>: Through the FCAC website at <a className={s.point_link}
                                                                   target="_blank"
                                                                   href="https://www.canada.ca/en/financialconsumer-agency.html">www.canada.ca/en/financialconsumer-agency</a>
                <br/>
                <b>Mail</b>: Financial Consumer Agency of Canada <br/>
                427 Laurier Avenue West, 6th Floor <br/>
                Ottawa, Ontario K1R 1B9
            </div>

            <div className={s.point_title}>
                Office of the Privacy Commissioner of Canada (OPC)
            </div>

            <div className={s.point_text}>
                The OPC oversees compliance with Canada's privacy laws, and you may contact the OPC at any time with a
                privacy complaint.
            </div>

            <div className={s.point_text}>
                <b>Telephone</b>: Toll-free at 1-800-282-1376 <br/>
                <b>Online Form</b>: Through the OPC website at <a className={s.point_link} target="_blank" href="https://www.priv.gc.ca/">www.priv.gc.ca</a> <br/>
                <b>Mail</b>: Office of the Privacy Commissioner of Canada <br/>
                30 Victoria Street <br/>
                Gatineau, Quebec K1A 1H3
            </div>

        </div>
    );
};

export default SecondPanel;
