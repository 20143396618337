import React from 'react';
import styles from "../../../styles/styles.module.css";
import s from "./Faq.module.css";
import ContactUsForm from "../../../components/ContactUsForm/ContactUsForm";
import {motion} from 'framer-motion';
import {Collapse, theme} from 'antd';
import minus from '../../../assets/newImg/publicPages/faq/minus.svg'
import plus from '../../../assets/newImg/publicPages/faq/plus.svg'
import star from '../../../assets/newImg/publicPages/faq/remember.svg'

const {Panel} = Collapse;

const dataForCollapse = [
    {
        title: "What is Iberbanco Ltd.?",
        text: `Iberbanco Ltd. is a Money Services Business (MSB) registered in Canada. Its registration number is M23371461, 
        and it was initially registered on May 2, 2023. The company is involved in various financial activities, including 
        foreign exchange dealing, issuing or redeeming money orders, money transferring, dealing in virtual currencies, 
        and acting as a payment service provider. The authenticity of Iberbanco Ltd. can be checked by its MSB registration number (M23371461) on
         the official website of the Financial Transactions and Reports Analysis Centre of Canada (FINTRAC).`,
        key: "1",
    },
    {
        title: "Where is the main office of Iberbanco Ltd. located?",
        text: `The main office of Iberbanco Ltd. is located at 4 Robert Speck Parkway, Mississauga, ON, Canada, L4Z1S1.`,
        key: "2",
    },
    {
        title: "How can I contact Iberbanco Ltd.?",
        text: `You can contact Iberbanco Ltd. by sending them an email on support@iberbancoltd.com . Additionally, you 
        can reach out to them via the contact information provided on their official website: https://iberbancoltd.com/contacts`,
        key: "3",
    },
    {
        title: "What financial activities does Iberbanco Ltd. engage in?",
        text: `Iberbanco Ltd. is involved in the following financial activities:`,
        list: [
            "Foreign exchange dealing: Facilitating the exchange of one currency for another for various purposes, such as business transactions or personal travel.",
            "Issuing or redeeming money orders: Providing money orders that act as a secure payment method.",
            "Money transferring: Offering services for transferring funds domestically and internationally.",
            "Dealing in virtual currencies: Facilitating the buying, selling, or exchange of virtual currencies, such as cryptocurrencies.",
            "Payment service provider: Offering payment processing services for various types of transactions.",
        ],
        key: "4",
    },
    {
        title: "What is the incorporation number of Iberbanco Ltd., and when was it incorporated?",
        text: `The incorporation number of Iberbanco Ltd. is 1474250-8. The company was incorporated on February 6, 2023.`,
        key: "5",
    },
    {
        title: "Under which jurisdiction was Iberbanco Ltd. incorporated?",
        text: `Iberbanco Ltd. was incorporated under the jurisdiction of the Federal Government of Canada.`,
        key: "6",
    },
    {
        title: "Is Iberbanco Ltd. regulated by any authority?",
        text: `Yes, Iberbanco Ltd. is regulated and registered as an MSB with the Financial Transactions and Reports 
        Analysis Centre of Canada (FINTRAC). It must adhere to the regulatory guidelines set forth by FINTRAC to conduct 
        its financial activities lawfully.`,
        key: "7",
    },
    {
        title: "Can I trust Iberbanco Ltd. for my financial transactions?",
        text: `As a registered MSB, Iberbanco Ltd. is obligated to comply with strict regulations to prevent money 
        laundering, terrorist financing, and other illicit financial activities. Being a legitimate financial service 
        provider, you can generally trust Iberbanco Ltd. for your financial transactions. However, as with any financial 
        institution, it is always recommended to exercise due diligence and make informed decisions when dealing with 
        money matters.`,
        key: "8",
    },
    {
        title: "What security measures does Iberbanco Ltd. have in place to protect customer information?",
        text: `Iberbanco Ltd. prioritizes the security and confidentiality of customer information. They employ 
        industry-standard security measures to safeguard sensitive data against unauthorized access, theft, or misuse. 
        This may include encryption, secure data storage, regular security audits, and compliance with relevant data 
        protection laws.`,
        key: "9",
    },
    {
        title: "How can I apply for financial services with Iberbanco Ltd.?",
        text: `To apply for financial services with Iberbanco Ltd., you can visit their website (https://iberbancoltd.com/) 
        and navigate to the relevant service section. They may have application forms or contact information provided 
        for specific services. Alternatively, you can contact their main office directly for assistance.`,
        key: "10",
    },
    {
        title: "Does Iberbanco Ltd. charge any fees for its services?",
        text: `Iberbanco Ltd. may charge fees for some of its financial services. The fee structure and details should 
        be available on their website or disclosed during the application process. It is advisable to review the terms 
        and conditions, including fee schedules, before proceeding with any financial transactions.`,
        key: "11",
    },
    {
        title: "Can I request a refund for the services provided by Iberbanco Ltd.?",
        text: `Refund policies may vary depending on the specific financial service you have availed from Iberbanco Ltd. 
        It is recommended to review their terms and conditions or contact their customer support for information regarding 
        refund policies and procedures.`,
        key: "12",
    },
    {
        title: "Is Iberbanco Ltd. available for international customers?",
        text: `Yes, Iberbanco Ltd. offers its financial services to both domestic and international customers, subject 
        to the regulations and restrictions of the jurisdictions involved.`,
        key: "13",
    },
    {
        title: "What currencies does Iberbanco Ltd. deal with for foreign exchange transactions?",
        text: `Iberbanco Ltd. may deal with a wide range of currencies for foreign exchange transactions. For specific 
        currency availability and exchange rates, you can contact their customer support or check their website.`,
        attention: '* Please note that the information provided in this FAQ is subject to change, and it is advisable to ' +
            'refer to the official website or contact Iberbanco Ltd. directly for the most up-to-date and accurate ' +
            'information regarding their services and policies.',
        key: "14",
    },
    {
        title: "Can I open multiple accounts with Iberbanco Ltd.?",
        text: `Iberbanco Ltd. may allow customers to open multiple accounts depending on the type of services they offer. 
        However, it is essential to comply with their policies and any applicable regulations regarding account usage 
        and management.`,
        key: "15",
    },
    {
        title: "Are there any transaction limits with Iberbanco Ltd.?",
        text: `Yes, Iberbanco Ltd. may impose transaction limits to comply with regulatory requirements and mitigate the 
        risk of illegal financial activities. These limits can vary based on the type of service, customer status, and 
        applicable regulations.`,
        key: "16",
    },
    {
        title: "How long does it take to complete a money transfer with Iberbanco Ltd.?",
        text: `The time required to complete a money transfer with Iberbanco Ltd. can vary depending on several factors, 
        including the destination country, payment method, and any potential compliance checks. Transfers within the 
        same country may be quicker than international transfers, which could take a few business days. It's recommended 
        to inquire about the estimated transfer time when initiating the transaction.`,
        key: "17",
    },
    {
        title: "Does Iberbanco Ltd. offer customer support?",
        text: `Yes, Iberbanco Ltd. typically provides customer support to assist with inquiries, account-related issues, 
        and other concerns. You can reach their customer support team through the contact details provided on their website.`,
        key: "18",
    },
    {
        title: "What should I do if I suspect fraudulent activities related to Iberbanco Ltd.?",
        text: `If you suspect any fraudulent or suspicious activities related to Iberbanco Ltd., it's essential to report 
        them immediately. You can contact their customer support team or relevant regulatory authorities, such as FINTRAC, 
        to file a complaint or report your concerns.`,
        key: "19",
    },
    {
        title: "Are there any restrictions on using Iberbanco Ltd.'s services for virtual currencies?",
        text: `Iberbanco Ltd. may impose certain restrictions or guidelines for using their services concerning virtual 
        currencies to comply with legal and regulatory requirements. Customers are advised to review the terms and conditions 
        and any applicable policies related to virtual currency transactions.`,
        key: "20",
    },
    {
        title: "Can I transfer money between different virtual currencies with Iberbanco Ltd.?",
        text: `Iberbanco Ltd.'s ability to facilitate transfers between different virtual currencies depends on their 
        specific services and offerings. Customers interested in such transactions should inquire directly with Iberbanco
         Ltd. to confirm the availability and requirements.`,
        key: "21",
    },
    {
        title: "What happens if I encounter technical issues while using Iberbanco Ltd.'s website or services?",
        text: `If you encounter technical issues while using Iberbanco Ltd.'s website or services, you can contact their 
        customer support for assistance. They may provide guidance to troubleshoot the problem or suggest alternative 
        methods for completing your transactions.`,
        key: "22",
    },
    {
        title: "Can I cancel a financial transaction with Iberbanco Ltd. after it has been initiated?",
        text: `The ability to cancel a financial transaction with Iberbanco Ltd. may depend on the type of service and 
        the stage of the transaction process. It is recommended to contact their customer support immediately if you wish 
        to cancel a transaction and inquire about their policies and procedures for cancellations.`,
        key: "23",
    },
    {
        title: "Does Iberbanco Ltd. offer any incentives or referral programs for its customers?",
        text: `Iberbanco Ltd. may offer incentives or referral programs to its customers as part of its marketing and 
        promotional activities. Information about such programs is usually available on their website or can be obtained 
        by contacting their customer support.`,
        key: "24",
    },
]

const Faq = () => {

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={styles.container}>
                <div className={styles.wrapper}>


                    <div className={s.top_block}>
                        <div className={s.content}>
                            <div className={s.title}>
                                Frequently Asked Questions (FAQ)
                            </div>
                            <div className={s.collapse_block}>
                                {
                                    dataForCollapse.map((item) =>
                                        <Collapse
                                            key={item.key}
                                            className={s.my_collapse}
                                            expandIconPosition={"end"}
                                            bordered={false}
                                            expandIcon={({isActive}) => isActive ?
                                                <img className={s.icon_minus} src={minus} alt=""/> :
                                                <img src={plus} className={s.icon_plus} alt=""/>}
                                            style={{
                                                background: "transparent",
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Panel header={item.title} key={item.key} className={s.panelStyle}>
                                                <div className={s.collapse_text}>
                                                    <p>{item.text}</p>
                                                    { item.list && <ul>
                                                            {item?.list?.map((element) => {
                                                            return (
                                                                <li>{element}</li>
                                                            )
                                                        })}
                                                        </ul> }
                                                    <p className={s.attention}>{item.attention && item.attention}</p>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className={s.remember_wrapper}>
                        <div className={s.remember_block}>
                            <div>
                                <img src={star} alt=""/>
                            </div>
                            <div>
                                <p className={s.remember_title}>
                                    Remember
                                </p>
                                <p className={s.remember_text}>
                                    This FAQ provides general information and is subject to change based on Iberbanco
                                    Ltd.'s policies, regulatory updates, and other factors. Always refer to the latest
                                    information on their official website or reach out to their customer support for the
                                    most up-to-date details regarding their services and operations.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={s.contact_us_form_wrapper}>
                        <ContactUsForm/>
                    </div>

                </div>
            </div>
        </motion.div>
    );
};

export default Faq;
