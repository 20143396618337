import {useAppSelector} from "./redux";

export const useAccountsForSelect = () => {

    const keys = ['number', 'currency']
    const userAccountsList = useAppSelector( state => state.userReducer.accountsList)

    console.log(userAccountsList)

    const filteredAccounts = userAccountsList.filter((obj: any) => obj.status === "Active").map((obj: any) => keys.reduce((acc: any, key: any) => {
        acc[key] = obj[key];
        return acc;
    }, {}));

    filteredAccounts.push({number: "ALL", currency: ""})

    return filteredAccounts
}
