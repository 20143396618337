import React, {useEffect, useState} from 'react';
import CardsStepOne from "./CardsStepOne/CardsStepOne";
import CardsStepThree from "./CardsStepThree/CardsStepThree";
import CardsStepFour from "./CardsStepFour/CardsStepFour";
import CardsStepTwo from "./CardsStepTwo/CardsStepTwo";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getCardInfo} from "../../../store/reducers/ActionCreators";

const Cards = () => {
    const dispatch = useAppDispatch()
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const cardInfo = useAppSelector(store => store.userReducer.cardInfo)

    useEffect(() => {
        dispatch(getCardInfo(userToken))
    }, [])

    const hasExistentCard = !!cardInfo.status;
    const hasCardNumber = !!cardInfo.san;

    const [current, setCurrent] = useState(
        hasCardNumber ? 2 :
            hasExistentCard ? 1 :
                0
    );

    const steps = [{
        content: <CardsStepOne setCurrent={setCurrent}
                               current={current}
        />
    }, {
        content: <CardsStepTwo setCurrent={setCurrent}
                               current={current}
        />
    }, {
        content: <CardsStepThree setCurrent={setCurrent}
                                 current={current}
        />
    }, {
        content: <CardsStepFour setCurrent={setCurrent}
                                current={current}
        />
    },];

    return (<div>{steps[current].content}</div>);
};

export default Cards;