import React, {useState} from 'react';
import classnames from "classnames";
import styles from "./ContactUsForm.module.css";
import efficiently from "../../assets/newImg/publicPages/efficiently.svg";
import MyInput from "../ui/MyInput/MyInput";
import MyTextarea from "../ui/MyTextarea/MyTextarea";
import MyBtn from "../ui/MyBtn/MyBtn";
import {useFormik} from "formik";
import {useAppDispatch} from "../../hooks/redux";
import {sendContactFormData} from "../../store/reducers/ActionCreators";
import * as yup from "yup";
import CustomModal from "../ui/CustomModal/CustomModal";
import {setDisabled} from "../../store/reducers/UserSlice";

const validationSchema = yup.object({
    name: yup.string().required('Required field').max(100, 'max 100 symbols'),
    message: yup.string().required('Required field').max(1000, 'max 1000 symbols'),
    email: yup.string().email("invalid format").required('Required field'),
})

const ContactUsForm = () => {

    const dispatch = useAppDispatch()
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

    const contactForm = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: "",
        },
        validationSchema,
        onSubmit: (values, formikHelpers) => {
            dispatch(sendContactFormData(values))
                .then((res) => {
                    if (res.data === 'ok') {
                        setIsSuccessModalOpen(true)
                    }
                })
                .catch((e) => {
                    setIsErrorModalOpen(true)
                })
                .finally(() => {
                    dispatch(setDisabled(false))
                    formikHelpers.resetForm()
                })
        }
    })

    return (
        <>
            <CustomModal setIsModalOpen={setIsSuccessModalOpen} isModalOpen={isSuccessModalOpen}>
                <div className={styles.popup_title}>
                    Success!
                </div>
                <div className={styles.popup_subtitle}>
                    Your request has been sent.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn title={"Ok"} onClick={() => setIsSuccessModalOpen(false)}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal setIsModalOpen={setIsErrorModalOpen} isModalOpen={isErrorModalOpen} type={"error"}>
                <div className={styles.popup_title}>
                    Oops!
                </div>
                <div className={styles.popup_subtitle}>
                    Something went wrong. Please try again later.
                </div>
                <div className={styles.popup_button_wrapper}>
                    <div className={styles.button_block}>
                        <MyBtn buttonType={"error"} title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                    </div>
                </div>
            </CustomModal>

            <div className={classnames(styles.flex, styles.gap20)}>
                <div className={classnames(styles.black_bg, styles.block)}>
                    <span className={styles.color_title_white}>Start your introduction to CompanyName today!</span>
                    <div className={classnames(styles.block_title, styles.color_title_white)}>
                        <h2 className={styles.contact_description}>
                            Get a&nbsp;
                            <span className={classnames(styles.decorated, styles.color_title_white)}>
                                    <img src={efficiently} className={styles.consultation} alt={""} width={238}
                                         height={55}/>
                                    consultation
                                </span>
                        </h2>
                        <h3 className={styles.contact_subtitle}>& we&apos;ll answer all your questions</h3>
                    </div>
                </div>
                <div className={classnames(styles.gray_bg, styles.block)}>
                    <form onSubmit={contactForm.handleSubmit}>
                        <div className={styles.input_wrapper}>
                            <div className={styles.input_label}>name</div>
                            <MyInput
                                placeholder={"Enter your name"}
                                id={"name"}
                                name={"name"}
                                value={contactForm.values.name}
                                onChange={contactForm.handleChange}
                            />
                            {
                                contactForm.touched.name
                                && contactForm.errors.name
                                && <div className={styles.error_text}>{contactForm.errors.name}</div>
                            }
                        </div>

                        <div className={styles.input_wrapper}>
                            <div className={styles.input_label}>email</div>
                            <MyInput
                                placeholder={"Enter your email"}
                                id={"email"}
                                name={"email"}
                                value={contactForm.values.email}
                                onChange={contactForm.handleChange}
                            />
                            {
                                contactForm.touched.email
                                && contactForm.errors.email
                                && <div className={styles.error_text}>{contactForm.errors.email}</div>
                            }
                        </div>

                        <div className={styles.input_wrapper}>
                            <div className={styles.input_label}>message</div>
                            <MyTextarea
                                placeholder={"Enter your message"}
                                id={"message"}
                                name={"message"}
                                value={contactForm.values.message}
                                onChange={contactForm.handleChange}
                            />
                            {
                                contactForm.touched.message
                                && contactForm.errors.message
                                && <div className={styles.error_text}>{contactForm.errors.message}</div>
                            }
                        </div>

                        <div className={styles.contacts_button_wrapper}>
                            <div className={styles.button_block}>
                                <MyBtn title={"Contact Us"} type={"submit"}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>

    );
};

export default ContactUsForm;
