import React from 'react';
import s from "../LegalAndPrivacy.module.css";

const FourthPanel = () => {
    return (
        <div className={s.collapse_text}>

            <div className={s.point_title}>
                1. Introduction
            </div>

            <div className={s.point_text}>
                <b>1.1.</b> New Client Onboarding and KYC Refresh processes entail the initial and subsequent reviews of
                potential and current clients. This process includes the review and retention of required Documentation,
                ID Verification of Authorized Use verification of Beneficial Ownership, Adverse Media Searches,
                Sanctions Screening, client Risk Assessment, and obtaining approval(s) of risk owners). Before <b>CompanyName </b>
                onboards a new client and during periodic ongoing reviews, the KYC Analyst is required to ensure
                the
                client's due diligence is in accordance with PCMLTFA, <b>CompanyName</b>‘s internal policies, and
                other
                regulatory requirements as per the client Entity Type. Documentation and review requirements are unique
                to client Entity Types and/or <b>CompanyName</b> products. As per record retention requirements,
                client
                documentation is required to be retained for at least five years following the date of the last
                transaction or 5 years after the client ceases to deal with <b>CompanyName</b>. New Onboard and KYC
                Reviews
                are primarily hosted. This document must be read in conjunction with the AML ATF Operating Directives
                (OD). <b>CompanyName</b> of Canada (<b>CompanyName</b>) commonly deals with the following Client
                Entity
            </div>

            <div className={s.point_subtitle}>
                1.2. Types:
            </div>

            <div className={s.point_text}>
                <ul>
                    <li>Corporations</li>
                    <li>Money Service Businesses that аre registered with FINTRAC (MSB)</li>
                    <li>Domestic Financial Institutions (FI) and Credit Unions (CU)</li>
                    <li>Foreign Financial Institutions (FFs)</li>
                    <li>Dealers of Precious Metals and Stones (DPMS)</li>
                    <li>Other Structures; Trusts, Joint Ventures, Sole Proprietorships</li>
                </ul>
            </div>

            <div className={s.point_text}>
                For a list of prohibited entity types and transactions, please refer to the AML/ATF Operating Directive
                Section 6.2 - Prohibited Risks
            </div>

            <div className={s.point_title}>
                2. New Client Onboarding
            </div>

            <div className={s.point_title}>
                2.1. Notifications of Review & Initial Steps - Sales Support Team
            </div>

            <div className={s.point_text}>
                When a new client entity has been created, the Representative from <b>CompanyName</b> Sales Support
                Team
                (also known Sales Support) will conduct an initial review to verify that required documents are received
                and filled where mandatory; Sales Support will review the Onboarding form including Authorized Person
                (AP) details, ensuring the necessary information has been provided in required fields. Sales Support
                reviews for completion rather than content. Once Sales Support has determined that the required
                documentation is complete, they will upload available forms and will complete the initial phase of data
                entry. Finally, Sales Support will then create a new workflow entry on the Compliance Portal in
                SharePoint. When the client is entered into SharePoint, an email notification will be automatically sent
                to all KYC Analysts and KYC Manager to notify Compliance that a new client is ready for onboarding. If
                clarifications were required prior to the new onboard notification, Sales Support will note in the
                comments field of the SharePoint entry and should upload communications between the client and RM
            </div>

            <div className={s.point_subtitle}>
                2.2. New Onboard Reviews - Compliance Team
            </div>

            <div className={s.point_text}>
                <ul>
                    <li>Once the email notification is received in Compliance Inbox, the KYC team Manager/ delegate
                        assigns the Onboard requests to the KYC analyst for review.
                    </li>
                    <li>The KYC Analyst begins the new onboard by opening the SharePoint entry, the Analyst will review
                        the entity name and details including the Entity Type, the priority level of the file in
                        SharePoint, the Relationship Manager (RM), the desired products, and comments left by Sales
                        Support. Attachments may also be included in SharePoint.
                    </li>
                    <li>KYC analyst will access Compliance Shared Drive folder</li>
                    <li>Onboarding and Client Files \Prospects and Declined\ Prospect Clients</li>
                    <li>In the folder, create a new subfolder for the new onboard using the prospect client's full
                        business name (can include DBA).
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Within the newly created Prospect folder, save a blank copy of the AML Client Risk Assessment Workbook
                for the current month and create empty folders with names listed below. You can alternatively choose to
                copy created templates for the same, found here: 1. AML\2. Onboarding and Client Files\Templates -
                Corporate Prospect and as subfolder named as follows:
            </div>

            <div className={s.point_text}>
                1) Address & Phone Verification <br/>
                2) Adverse Media Search (Only applicable for manual screening on Director's Other business, other relate
                entities) <br/>
                3) Equifax Reports <br/>
                4) Confirmation of Existence
            </div>

            <div className={s.point_text}>
                Commence review of the new prospect by following the steps listed below. The KYC Analyst will verify
                that client information is accurate.
            </div>

            <div className={s.point_subtitle}>
                2.3. Checking for previously Demarketed Clients
            </div>

            <div className={s.point_text}>
                Before reviewing, KYC Analyst will manually check if they have been previously de-marketed or are a
                related party to a previously declined or demarketed client (a relationship like this may be determined
                later in the review). <br/>
                If no matches are found, proceed to review the client for onboarding. However, if a potential match or
                true match is identified, immediately escalate the finding to the KYC Manager to determine the impact on
                the client.
            </div>

            <div className={s.point_text}>
                <b>2.4.</b> If the client operations are related to MSB activities Verify Domestic MSB Registration with
                FINTRAC All MSB clients must be registered with FINTRAC with a current active status. Before proceeding
                with an MSB review, please check to verify that MSB registration is intact and current.
            </div>

            <div className={s.point_text}>
                1) To verify an MSB's registration with FINTRAC, navigate to the following website: <a
                className={s.point_link}
                target="_blank"
                href="https://www10.fintrac-canafe.gc.ca/msb-esm/public/msb-search/search-by-name/">
                https://www10.fintrac-canafe.gc.ca/msb-esm/public/msb-search/search-by-name/
            </a>
                <br/>
                For MSBs in Quebec, you can also verify via the following website:
                <a className={s.point_link}
                   target="_blank"
                   href="https://lautorite.qc.ca/en/general-public/registers/register-money-services-businesses/">
                    https://lautorite.qc.ca/en/general-public/registers/register-money-services-businesses/
                </a>
                <br/>
                2) Copy and paste the MSB's legal name into the Search Bar (Search by name of business) and hit Enter.
                Double-click on the correct result (verify match via name, business address, etc.). <br/>
                3) Review the information, ensuring the status of the MSB is documented as "Registered" and is active,
                Note the MSB date and activities located at the bottom of the report. <br/>
                4) If available, from the menu on the left toggle between MSB details and MSB branches tabs. Print &
                Save in the client folder both pages showing current registration, for upload to Documents Tab. <br/>
                5) If the MSB status is listed as Ceased, or not active for the current date, please escalate to the
                Compliance Manager for next steps. Without an MSB Registered and Active status, <b>CompanyName</b> will
                not
                onboard MSBs.
            </div>

            <div className={s.point_text}>
                <b>2.5. Verify Foreign MSB Registration with FINTRAC</b> and Jurisdictional Regulatory Authority All
                Foreign
                MSB clients must be registered with FINTRAC with a current active status. Before proceeding with Foreign
                MSB review, please check to verify that MSB registration is intact and current. To verify an MSB's
                registration with FINTRAC, navigate to the following website:
                <a className={s.point_link}
                   target="_blank"
                   href="https://www10.fintrac-canafe.gc.ca/msb-esm/public/msb-search/search-by-name/">
                    https://www10.fintrac-canafe.gc.ca/msb-esm/public/msb-search/search-by-name/
                </a>
                <br/>
                For Foreign MSB's, please check to verify the MSB registration with the jurisdictional regulatory
                authority is instant and current. For example, if the MSB is incorporated in the USA, the MSB will
                require FINCEN registration.
            </div>

            <div className={s.point_text}>
                The steps can be summarized into the following broad categories which may occur in the following order:
            </div>

            <div className={s.point_text}>
                1) Gathering the relevant Onboarding/KYC documentation and information, including confirmation of
                beneficial ownership, confirmation of existence, confirming Power to Bind, and identity
                verification. <br/>
                2) Completing or confirming initial name matching for designated persons, entities, sanctioned
                countries, PEPs (domestic and foreign) and HIOs, and adverse media screening. <br/>
                3) Completing the Client's Risk Assessment by utilizing the Risk Based Approach Workbook (in the RBA
                Methodology) and obtaining approvals. <br/>
                4) Performing additional or enhanced due diligence based on the risk score; and Implementing (ongoing)
                monitoring in accordance with the level of Money Laundering/Terrorist Financing risk presented by the
                Client. <br/>
                5) Depending on the Entity Type, each client is required to complete the applicable forms and provide
                the listed documentation. A quick reference of the documents required per Entity Type is attached in
                Appendix B. For a complete list of possible required documentation and which team (Compliance) is
                responsible to collect and upload that documentation.
            </div>

            <div className={s.point_subtitle}>
                2.6. С.Document Content Review
            </div>

            <div className={s.point_text}>
                2.6.1. Select Documents Tab to review uploaded files. Cross-reference the uploaded documents (or in
                zipped file) with the list of required documents found on the top of the form and in AML ATF Operating
                Directive. As you review, track missing items.
            </div>

            <div className={s.point_text}>
                <b>2.6.2.</b> When all the required documents are received and uploaded to the client file, proceed by
                opening
                each form, clicking "Preview", and reviewing the contents against the following checklist as a guide
                (non-exhaustive).
            </div>

            <div className={s.point_text}>
                <b>2.6.3.</b> Ensure each document is correctly completed, that no mandatory sections or fields have
                been left
                blank or pages omitted (all questions answered, all yes/no's selected where applicable, etc.)
            </div>

            <div className={s.point_text}>
                <b>2.6.4.</b> As per Confirmation of Existence requirements for entities, ensure the most recent version
                of the
                articles of incorporation or an equivalent document (certificate, amalgamation) is saved.; this may
                include any other record that confirms the entity's existence and contains its name and address (and the
                names of its directors if it is a corporation). If the RM was unable to obtain the articles of
                incorporation or equivalent ensure a record of the best effort to obtain is saved in place (an email
                confirmation from the client, etc). Additionally, for federal corporations you may obtain public records
                from Corporations Canada <a className={s.point_link}
                                            target="_blank"
                                            href="https://www.ic.gc.ca/app/scr/cc/CorporationsCanada/fdriCroSrch.html">
                https://www.ic.gc.ca/app/scr/cc/CorporationsCanada/fdriCroSrch.html
            </a>
            </div>

            <div className={s.point_text}>
                We additionally pull the most recent version of entity registration details via ESC and REQ (Quebec only
                satisfy this requirement.
            </div>

            <div className={s.point_subtitle}>
                2.7. Ensure any answers provided by the client satisfy the questions, regulations and/or internal
                policies.
            </div>

            <div className={s.point_text}>
                2.7.1. Ensure that the section in the form 'Third Party Payment Details' where the client is acting on
                behalf of ti party is filled by the client. <br/>
                2.7.2. Verify all signatures are provided where required.
            </div>

            <div className={s.point_text}>
                2.7.3. Check if the Authorized Person (AP) forms/sections are completed for all APs listed on the
                Application. <br/>
                2.7.4. Verify that at least one individual has signed the Authority to Bind the Entity section
            </div>

            <div className={s.point_text}>
                2.7.5. Also Verify that the Beneficial Owners are a natural person and the % add up to 100%. If they
                have
                not indicated anyon to own 25% more, they should be selecting the box to say no one owns it.
            </div>

            <div className={s.point_text}>
                2.7.6. Verify the identity of new APs (Authorized Person's). Refer to Appendix E for details on the ID
                Verification process through the approved methods.
            </div>

            <div className={s.point_text}>
                2.7.7. If a Politically Exposed Person (PEP) or Head of International Organization (HIO) or a close
                associate is identified during the review, please refer to Appendix C for the correct escalation
                process. In these circumstances, the client will be considered Auto-High Risk.
            </div>

            <div className={s.point_text}>
                2.7.8. Ensure that the Authority to Bind the Entity section is signed and dated (also required at KYC).
                Verify that you have obtained name(s) and signature(s) of those listed with Authority to Bind the
                Entity. If you are not able to independently verify the Signing Officer through corporate documents
                provided or other external research, a request for proof will be made through the Sales Support (board
                resolution or similar document demonstrating who is authorized to act on behalf of the entity)
            </div>

            <div className={s.point_subtitle}>
                2.8. Review by Tab
            </div>

            <div className={s.point_text}>
                <ul>
                    <li>
                        Following Document Review, the Analyst will enter and review client information in each tab
                        (listed
                        below) and verify the information is up to date, matches the forms, and that there are no
                        omissions:
                        "General Information" section (Client Name, Status, Entity Class, Address) Address Tab
                        (Address(es),
                        Phone numbers, Website)
                    </li>
                    <li>
                        Contacts Tab (add primary contact, all authorized persons, and authorized signatories -selecting
                        'Authorized to transact' for APs following ID Verification). Refer to Appendix E for
                        definitions.
                        Customer Service Tab (RM details, Start Date marked when new onboard review is finalized) Banks
                        Tab
                        (review Bank details from form; Fl clients exempt) Documents Tab (Upload additional Compliance
                        documents) Compliance Tab and Flex Field, including:
                    </li>
                    <li>
                        Corporate Directors (enter all Director details)
                    </li>
                    <li>
                        Corporate Identification (enter entities incorporation details)
                    </li>
                    <li>
                        Corporate Ownership (enter all Ownership details)
                    </li>
                    <li>
                        Corporate Profile and Flex Field (Fill client into as required)
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                Select products in the Flex Field (Bank Notes, Forwards, Spot, etc.) o Signature Card (upload Equifax
                and/or Utility bill/ Bank Statements and enter details of Govt. issued IDs for dual method under each
                name. For information regarding the ID Verification process and definitions, refer to Appendix E)
            </div>

            <div className={s.point_text}>
                <ul>
                    <li>
                        KYC Verification (Primarily used for notes on identified PEP/HIOs)
                    </li>
                    <li>
                        Internal Use (Manager allows to transact, LEI details for Forwards clients, additional notes) a
                        Analyst needs to mark the compliance status as Active once client is approved for Onboarding
                    </li>
                    <li>
                        KYC Renewal and Flex Field (Next periodic review date entered, Flex Field for analyst name a
                        review date on completion of KYC refresh reviews)
                    </li>
                    <li>
                        Estimated Trading Activity (from the review form)
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                During Tab review, if discrepancies are identified between documents and information entered, update
                information as is necessary.
            </div>

            <div className={s.point_subtitle}>
                2.9 Clarification Emails to RM
            </div>

            <div className={s.point_text}>
                Throughout the client review, the Analyst is expected to maintain a running list of incomplete items or
                documents per the requirements for the Entity Type. If items are identified that need clarification or
                updating, the analyst will forward this list on email to the RM prior to proceeding with the review. For
                examples of communication emails ar who to CC, please refer to Appendix D. When all expectations have
                been met, the analyst will proceed with Adver Media Searches, Screening, and the Client Risk Assessment
                Workbook.
            </div>

            <div className={s.point_text}>
                KYC Analyst must follow all the Primary and Secondary factors to determine if the client matches to an
                alert. For further information please refer to the Economic Sanctions and Suppression of Terrorism
                Operating Directive.
            </div>

            <div className={s.point_text}>
                2.9.1. To manually Search in LN:
            </div>

            <div className={s.point_text}>
                1. Open LexisNexis Program Installed to your desktop <br/>
                2. Search Entity Type - select Business or Individual <br/>
                3. For a Business Client search <br/>
                4. Type the Entity Business name and address (if available) and click Search <br/>
                5. If no matches, a pop-up will appear on the screen as 'No Match Found' C.
            </div>

            <div className={s.point_text}>
                2.9.2. Click on Print Report and the report will be generated. Click the Print icon and save as PDF in
                th respective client folder.
            </div>

            <div className={s.point_text}>
                1. Click on Close to return to the search window <br/>
                2. On adjudication, If there is a potential match (Sanctions, Adverse Media and PEP) or if the analyst
                is unsure, forward the alert details to the KYC Manager for management review
            </div>

            <div className={s.point_text}>
                2.9.3. For an Individual search:
            </div>

            <div className={s.point_text}>
                1. Change Entity Type to an Individual <br/>
                2. Type the First Name, Middle Name (if known), Last Name, and DOB (if known). <br/>
                3. If no matches, a pop-up will appear on the screen as No Match Found' <br/>
                4. Click on Print Report and the report will be generated. Click the Print icon and save as PDF in the
                respective client folder <br/>
                5. Click on Close to return to the search window <br/>
                6. If there is a potential match (Sanctions, Adverse Media, and PEP), or if the analyst is unsure,
                forward the alert details to the KY Manager for management review Reviewing Search Results (Adverse
                Media)
            </div>

            <div className={s.point_text}>
                2.9.4. Reviewing Search Results (Adverse Media)
            </div>

            <div className={s.point_text}>
                Not all negative news is material (i.e., would not necessarily be relevant to the decision of whether
                the Client poses a high-risk for money laundering or terrorist financing). Print the screenshots of
                negative news and open-source information relevant to the client and case under review (even if there
                are no true matches) in the previously created folder to provide evidence that the search was performed,
                and reasonable measures were taken to confirm a true match. All rationale should be documented in the
                Adverse Media folder, including confirmation if no adverse media was obtained in these screenshots, date
                the review was conducted, the reviewer's name, and any escalations and the outcome of the escalations.
                All evidence is required to be uploaded in the client folder.
            </div>

            <div className={s.point_text}>
                It is important to consider the legitimacy of information obtained from public sources before placing
                reliance on it.
                This could include reviewing multiple sites containing similar information obtained from a variety of
                sources.
            </div>

            <div className={s.point_text}>
                Any potential matches that are not "obvious false positives" are adjudicated by the Compliance Analyst,
                working with the RM (if needed). Please refer to Economics Sanctions and Suppression of Terrorism
                Operating Directive, "Appendix B for Assessing matches" for the guidelines to adjudication alerts for
                adverse media. The Compliance Analyst will determine whether the Client or associated persons is the
                subject of Adverse Media screening, using acceptable criteria to discount "false positives" (e.g., dates
                of birth, comparing addresses and names). The Compliance Analyst may conduct additional research to
                determine: (i) whether the person(s)/entities associated with the account are or have been involved in
                criminal activity; or (il) whether a connection exists between the person(s) and the Client. When
                Analyst is not able to conclude whether a match is a "True Match" or a "False Positive Match", it should
                be escalated. Escalations from the analyst. Where the Compliance Manager is unable to conclude whether
                the match is a "T Match" or a "False Positive Match", the match will be treated as a "True Match".
            </div>

            <div className={s.point_text}>
                The adverse media details should be saved and shared with the Compliance Manager and/or CAMLO for
                further review via email. The detailed artic should also be uploaded to the client folder.
            </div>

            <div className={s.point_text}>
                Compliance Manager and/or Compliance Analyst should escalate the True Matches to the CAMLO to confirm t
                match and if the client is within our risk appetite. The CAMLO will also determine if there are red
                flags in accordance with PCMLFTA, and proceed to determine if there the threshold for reasonable grounds
                for suspicion has been m to file a STR. STR should be filed in accordance with Section 12.4 of the
                AML/ATF Operating Directive To consider if the adverse media is material the following guidelines should
                also be utilized by the Compliance Manager, which would be considered materiality factors:
            </div>

            <div className={s.point_text}>
                <ul>
                    <li>
                        The article is from a credible source and the article is within the last 5 years (regardless of
                        when the
                        suspicious activity took place).
                    </li>
                    <li>
                        Fines and penalties were incurred as a result of the activity or actions of the clients, and /or
                        charges
                        were laid a a result.
                    </li>
                    <li>
                        The client was directly involved in the activities and the activities raise ML/TF, Sanctions,
                        Fraud,
                        Bribery and Corruption and/or Compliance concerns.
                    </li>
                    <li>
                        The article raises serious concerns on the ethical behavior of the client, raising concerns of
                        future
                        non-compliance.
                    </li>
                    <li>
                        Any potential matches and rationale from the CAMLO should be evidenced as discounted or
                        retained. These
                        email rationales should be uploaded in the client file.
                    </li>
                    <li>
                        Client True Matches are considered in the current Client Risk Scoring factors as described in
                        the RBA
                        and supporting Client Risk Scoring workbook. Clients may also be declined or demarketed as a
                        result of
                        material adverse media or civil or criminal penalties imposed. Please see Section 7.5.1 for
                        Declined
                        relationships, or Section 13.0 for Demarketing procedures in the AML/ATF Operating Directives
                    </li>
                    <li>
                        All screenshots of adverse media (including comments for rationale), communication and rationale
                        and
                        approval emails should always be uploaded within the client folder.
                    </li>
                </ul>
            </div>

            <div className={s.point_text}>
                2.10. Client Risk Assessment Workbook
            </div>

            <div className={s.point_text}>
                Completion of the Client Risk Assessment Workbook determines the entity's risk level with CompanyName.
                Please refer to ATF Operating Directive Section 6.0 and AML ATF Risk Based Approach policy for further
                details on the methodology.
            </div>

            <div className={s.point_text}>
                Fill out the risk workbook as per the questions. Raise all questions and concerns to Compliance Manager,
                to discuss definitions or questions. On completion of a New Onboard review, please seek approval from
                the required risk owners as per the below approval matrix from the AML ATF Operating Directive (Low risk
                - RM only. High Risk - below parties). On receiving all required email confirmations of approval, save
                to the client folder. If multiple ema save to a new folder called "Approval Emails". After the
                completion of the Risk Assessment Workbook for new client, send the approval request email to the
                required parties/risk owners as specified in the table below to retain approvals in folder.
            </div>

            <div className={s.point_text}>
                <ul>
                    <li>Risk Rating</li>
                    <li>Stakeholder approval required</li>
                    <li>Special Approval Required</li>
                    <li>High Risk</li>
                    <li>Moderate Risk</li>
                    <li>CAMLO (or Delegate)</li>
                    <li>Low Risk</li>
                    <li>Relationship Manager only</li>
                </ul>
            </div>

            <div className={s.point_subtitle}>
                2.11. Outside risk appetite
            </div>

            <div className={s.point_text}>
                N/A - we will not provide services to clients who are deemed to be outside our risk appetite. <br/>
                Based on the calculated risk score and rating, the RM or Senior Management or CAMLO/delegate (as
                appropriate) will determine whether to open the account with the noted Client. Example Emails; Risk
                Workboard Explanation.
            </div>

            <div className={s.point_text}>
                Finally, when the risk is determined, the analyst can proceed to enter or update the details in
                Corporate Profile Initial and/or Current Risk Scores, Analyst Name and Date). Following this, the
                analyst will also enter KYC renewal details under KYC Renewal tab; as per the AML ATF Operating
                Directive, the analyst must ensure high risk client files are updated in accordance with FINTRAC and
                OSFI requirements on an annual basis (minimum). Moderate-risk client files are refreshed every year and
                low risk clients are updated every two years (or upon a trigger event), Please enter details as the
                required year and the first.
            </div>

        </div>
    );
};

export default FourthPanel;
