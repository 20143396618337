import {
    validationSchema, validationSchemaAza,
    validationSchemaBWPBank,
    validationSchemaCash, validationSchemaEGPBank,
    validationSchemaEUR,
    validationSchemaGHSBank, validationSchemaKESBank, validationSchemaKESMobile,
    validationSchemaMADCash, validationSchemaMobile,
    validationSchemaNGNBank,
    validationSchemaNGNMobile,
    validationSchemaNGNUSDBank, validationSchemaPix, validationSchemaTed,
    validationSchemaUGXBank,
    validationSchemaUGXMobile,
    validationSchemaUSDBank, validationSchemaXBank,
    validationSchemaXOFCash, validationSchemaXOFMobile, validationSchemaZARBank, validationSchemaZMWBank
} from "./ValidationSchemas";

// Function that returns the validation schema based on the payment region
export const getValidationSchemaForTemplates = (paymentTypeByRegion: string) => {
    if (paymentTypeByRegion) {
    switch (paymentTypeByRegion) {
        case "NGN::Bank":
            return validationSchemaNGNBank;

        case "NGN::Mobile":
        case "NigeriaUSD::Cash":
            return validationSchemaNGNMobile;

        case "NigeriaUSD::Bank":
            return validationSchemaNGNUSDBank;

        case "GHS::Bank":
            return validationSchemaGHSBank;

        case "GHS::Mobile":
        case "XAF::Mobile":
        case "GNF::Mobile":
            return validationSchemaMobile;

        case "GHS::Cash":
            return validationSchemaCash;

        case "UGX::Bank":
            return validationSchemaUGXBank;

        case "UGX::Mobile":
            return validationSchemaUGXMobile;

        case "EUR::Bank":
        case "GBP::Bank":
            return validationSchemaEUR;

        case "USD::Bank":
            return validationSchemaUSDBank;

        case "MAD::Cash":
            return validationSchemaMADCash;

        case "XOF::Cash":
            return validationSchemaXOFCash;

        case "XOF::Mobile":
            return validationSchemaXOFMobile;

        case "XOF::Bank":
        case "XAF::Bank":
            return validationSchemaXBank;

        case "ZAR::Bank":
            return validationSchemaZARBank;

        case "BWP::Bank":
            return validationSchemaBWPBank;

        case "PIX Payments":
            return validationSchemaPix;

        case "TED Payments":
            return validationSchemaTed;

        case "ZMW::Bank":
            return validationSchemaZMWBank;

        case "KES::Bank":
            return validationSchemaKESBank;

        case "KES::Mobile":
            return validationSchemaKESMobile;

        case "EGP::Bank":
            return validationSchemaEGPBank;

        default:
            return validationSchemaAza;
    }
    }

    return validationSchema;

}
