import {motion} from 'framer-motion';
import React, {useEffect, useState} from 'react';
import s from './ProfileSettings.module.css'
import MyInput from "../../../../../components/ui/MyInput/MyInput";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import CustomModal from "../../../../../components/ui/CustomModal/CustomModal";
import {createNewPasswordThunk, getPhoneListThunk, setTokenState} from "../../../../../store/reducers/ActionCreators";
import {Formik} from "formik";
import * as yup from "yup";
import PhoneNumbers from "../../../../../components/PhoneNumbers/PhoneNumbers";
import ChangeEmail from "../../../../../components/ChangeEmail/ChangeEmail";
import classnames from "classnames";

const validationSchema = yup.object({
    oldPassword: yup.string().required('Required field'),
    newPassword: yup.string()
        .min(8, "min 8")
        .matches(/[1-9]+/, "Password must contain at least one number")
        .matches(/[a-z]+/, "Password must contain at least one lowercase")
        .matches(/[A-Z]+/, "Password must contain at least one uppercase")
        .required('Required field')
        .test({message: 'Please enter a different password (not the current one)',
            test: function (value) {
                const { oldPassword } = this.parent;
                return oldPassword !== value;
            },
        }),
})

const ProfileSettings = () => {

    const userInfo = useAppSelector(state => state.userReducer.user)
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const phoneList = useAppSelector(state => state.userReducer.user.phoneList)
    const dispatch = useAppDispatch()

    const [isChangeEmailProcess, setIsChangeEmailProcess] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        dispatch(getPhoneListThunk(token))
    }, [])

    const closePopup = () => {
        setIsModalOpen(false)
        setIsErrorModalOpen(false)
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <ChangeEmail isChangeEmailProcess={isChangeEmailProcess} setIsChangeEmailProcess={setIsChangeEmailProcess}/>

            <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                <div className={s.flex_column}>
                    <div className={s.modal_title}>
                        Success!
                    </div>
                    <div className={s.modal_subtitle}>
                        Your password has been changed.
                    </div>
                    <div className={s.button_block}>
                        <MyBtn title={"Ok"} onClick={() => closePopup()}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type={"error"} isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen}>
                <div className={s.flex_column}>
                    <div className={s.modal_title}>
                        Failed!
                    </div>
                    <div className={s.modal_subtitle}>
                        {errorText}
                    </div>
                    <div className={s.button_block}>
                        <MyBtn title={"Ok"} buttonType={"error"} onClick={() => closePopup()}/>
                    </div>
                </div>
            </CustomModal>

            <div className={s.wrapper}>
                <div className={s.accounts_list_block}>
                    <div className={s.list_title}>
                        Accounts
                    </div>
                    <div className={s.accounts_list}>
                        <div className={s.list_block}>
                            <div className={s.list_block_title}>
                                OWNER
                            </div>
                            <div className={s.list_block_value}>
                                {userInfo.userData.companyName}
                            </div>
                        </div>
                        <div className={s.list_block}>
                            <div className={s.list_block_title}>
                                ID
                            </div>
                            <div className={s.list_block_value}>
                                {userInfo.userData.clientId}
                            </div>
                        </div>
                        <div className={s.list_block}>
                            <div className={s.list_block_title}>
                                TYPE
                            </div>
                            <div className={s.list_block_value}>
                                {userInfo.userData.accountType}
                            </div>
                        </div>
                    </div>
                </div>

                <Formik
                    initialValues={{
                        oldPassword: '',
                        newPassword: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {resetForm}) => {
                        dispatch(createNewPasswordThunk(token, values))
                            .then((res: any) => {
                                if (res.data === "Done") {
                                    setIsModalOpen(true)
                                    resetForm()
                                }
                            })
                            .catch((e: any) => {
                                if (e.response.data === "Invalid old password") {
                                    setErrorText("The old password is wrong.")
                                    setIsErrorModalOpen(true)
                                } else if (e.response.data === "Invalid authorization data") {
                                    dispatch(setTokenState(false))
                                } else {
                                    setErrorText("Something went wrong. Try again a little later or contact \n" +
                                        "our support team.")
                                    setIsErrorModalOpen(true)
                                }
                        })
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={s.change_password_block}>
                                <div className={s.change_password_title}>
                                    Change Password
                                </div>
                                <div className={s.change_password_subtitle}>
                                    The new password must contain a minimum 8 characters with at least: 1 lowercase, 1 uppercase,
                                    and 1 number.
                                </div>
                                <div className={s.input_block}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * current password
                                        </div>
                                        <MyInput id='oldPassword'
                                                 name='oldPassword'
                                                 password
                                                 onChange={handleChange}
                                                 value={values.oldPassword}
                                                 isError={errors.oldPassword}
                                                 touched={touched.oldPassword}
                                        />
                                        {errors.oldPassword &&
                                            <div className={s.field_error}>{errors.oldPassword}</div>}
                                    </div>
                                </div>
                                <div className={s.input_block}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * New password
                                        </div>
                                        <MyInput id='newPassword'
                                                 name='newPassword'
                                                 password
                                                 onChange={handleChange}
                                                 value={values.newPassword}
                                                 isError={errors.newPassword}
                                                 touched={touched.newPassword}
                                        />
                                        {errors.newPassword && <div className={s.field_error}>{errors.newPassword}</div>}
                                    </div>
                                </div>
                                <div className={s.button_wrapper}>
                                    <div className={s.button_block}>
                                        <MyBtn title={'Change'} type={"submit"}/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
                <PhoneNumbers phoneList={phoneList}/>

                <div className={s.email_block}>
                    <div className={s.list_title}>
                        Email Address
                    </div>

                    <div className={s.desktop_email_block}>
                        <div className={s.accounts_list_header}>
                            <div className={s.list_block}>
                                <div className={s.list_block_title}>
                                    Email address
                                </div>
                            </div>
                        </div>
                        <div className={s.accounts_list}>
                            <div className={s.list_block}>
                                <div className={classnames(s.list_block_value, s.mobile_email)}>
                                    {userInfo.userData.email}
                                </div>
                            </div>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    <div>Verified</div>
                                </div>
                            </div>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    <div>Main</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.mobile_email_block}>
                        <div className={s.accounts_list_header}>
                            <div className={s.list_block}>
                                <div className={s.list_block_title}>
                                    Email address
                                </div>
                            </div>
                        </div>
                        <div className={s.list_block}>
                            <div className={classnames(s.list_block_value, s.mobile_email)}>
                                {userInfo.userData.email}
                            </div>
                        </div>
                        <div className={s.accounts_list}>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    <div>Verified</div>
                                </div>
                            </div>
                            <div className={s.list_block}>
                                <div className={s.list_block_value}>
                                    <div>Main</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.button_wrapper}>
                        <div className={s.button_block}>
                            <MyBtn title={'Change email'} onClick={() => setIsChangeEmailProcess(true)}/>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default ProfileSettings;
