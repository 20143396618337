import React, {useEffect, useState} from 'react';
import s from './MobileHeader.module.css'
import burger from '../../assets/newImg/mobile/burger.svg'
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import NavBar from "../Header/NavBar/NavBar";
import {useLocation, useNavigate} from "react-router-dom";
import bg from '../../assets/newImg/mobile/login_header_bg.svg'
import {useAppSelector} from "../../hooks/redux";

const MobileHeader = () => {

    const [navIsShow, setNavIsShow] = useState(false)
    const navigate = useNavigate()
    const [isHeaderLogin, setIsHeaderLogin] = useState(true)
    const location = useLocation()
    const isLogin = useAppSelector(state => state.userReducer.user.userToken)

    useEffect(() => {
        if (location.pathname === "/login" || location.pathname === "/sign_up") {
            setIsHeaderLogin(true)
        } else {
            setIsHeaderLogin(false)
        }
    }, [location.pathname])

    return (
        <>
            { isHeaderLogin
                ? <div className={s.login_wrapper}>
                    {/*<img src={logo_login} className={s.logo_login} alt="" onClick={() => navigate("/")}/>*/}
                    {/*<img src={company_name} className={s.company_name} alt="" onClick={() => navigate("/")}/>*/}
                    <img src={bg} className={s.login_header} alt=""/>
                </div>
                : <div className={s.wrapper}>
                    <div className={s.logo_block}>
                        {/*<img src={logo} alt="" onClick={() => navigate("/")}/>*/}
                    </div>
                    <div className={s.right}>
                        <div>
                            { isLogin
                                ? <TransparentButton title={"back"} onClick={() => navigate(-1)}/>
                                : <TransparentButton title={"log In"} onClick={() => navigate("/login")}/>
                            }

                        </div>
                        <div>
                            <img src={burger} alt="" onClick={() => setNavIsShow(true)}/>
                        </div>
                    </div>
                    <NavBar isActive={navIsShow} setIsActive={setNavIsShow}/>
                </div>
            }

        </>
    );
};

export default MobileHeader;
