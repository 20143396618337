import React from 'react';
import styles from "../../../../styles/styles.module.css";
import imageSrc from "../../../../assets/newImg/publicPages/services/foreignExchange/main.png";
import ServicesHeaderBlock from "../../../../components/ServicesHeaderBlock/ServicesHeaderBlock";
import s from "./ForeignExchange.module.css";
import SettingsCard from "../../../../components/SettingsCard/SettingsCard";
import bankCards from "../../../../assets/newImg/publicPages/services/virtualCurrencies/cards.svg";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import support from "../../../../assets/newImg/publicPages/services/foreignExchange/foreign_help.svg";
import ContactUsForm from "../../../../components/ContactUsForm/ContactUsForm";
import iconCards from "../../../../assets/newImg/publicPages/services/foreignExchange/cards.svg";
import like from "../../../../assets/newImg/publicPages/services/foreignExchange/like.svg";
import efficiency from "../../../../assets/newImg/publicPages/services/foreignExchange/efficiency.svg";
import seamless from "../../../../assets/newImg/publicPages/services/foreignExchange/seamless.svg";
import {useNavigate} from "react-router-dom";
import {motion} from 'framer-motion';

const ForeignExchange = () => {

    const title = "Foreign Exchange Excellence"
    const navigate = useNavigate()
    const cards = [
        {
            icon: iconCards,
            title: 'Competitive Rates and Transparency:',
            text: 'When it comes to foreign exchange, we offer competitive rates that ensure you get the most value for ' +
                'your money. Our transparent and fair pricing policy allows you to make informed decisions, knowing that ' +
                'you are getting a favorable exchange rate without hidden fees or surprises. We believe in building trust ' +
                'through transparency, so you can confidently exchange your currency with us.'
        },
        {
            icon: like,
            title: 'Expert Guidance and Personalized Solutions:',
            text: 'Navigating the intricacies of foreign exchange can be complex, but our team \n' +
                'of experienced professionals is here to provide expert guidance and personalized solutions tailored to ' +
                'your specific requirements. We take the time to understand your unique needs and provide strategic ' +
                'insights to help you make informed decisions. '
        },
        {
            icon: efficiency,
            title: 'Efficiency and Reliability:',
            text: 'We understand the importance of timely and reliable currency exchange. With CompanyName, you can expect ' +
                'efficient and secure transactions, ensuring that your funds are exchanged promptly and accurately. Our ' +
                'robust systems and meticulous attention to detail guarantee the highest level of reliability, giving ' +
                'you peace of mind as you engage in foreign exchange transactions.'
        },
        {
            icon: seamless,
            title: 'Seamless International Transfers:',
            text: 'Sending money internationally can be a complex process, but at CompanyName, we make it seamless and ' +
                'hassle-free. Our extensive network and partnerships allow us to facilitate swift and secure international ' +
                'transfers, ensuring that your funds reach their intended destination promptly. With our expertise, you ' +
                'can confidently navigate the global financial landscape.'
        },
        {
            icon: bankCards,
            title: 'Risk Management and Hedging Strategies:',
            text: 'Managing currency risk is crucial for businesses engaged in international trade or investment. At ' +
                'CompanyName, we offer comprehensive risk management solutions and hedging strategies to protect your business ' +
                'from adverse currency fluctuations. Our team of experts will work closely with you to develop tailored ' +
                'risk management plans that mitigate potential currency risks, allowing you to focus on your core business ' +
                'operations.'
        },
    ]

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={styles.container}>
            <div className={styles.wrapper}>

                <ServicesHeaderBlock title={title} imageSrc={imageSrc}/>

                <div className={s.page_description}>
                    At CompanyName, we understand the significance of foreign exchange in today's interconnected global
                    economy. Our commitment to Foreign Exchange Excellence sets us apart as a trusted partner for all
                    your currency exchange needs.
                </div>

                <div className={s.cards_block}>
                    {
                        cards.map((card) => {
                            return (
                                <SettingsCard icon={card.icon} title={card.title} text={card.text}/>
                            )
                        })
                    }
                </div>

                <div className={s.button_wrapper}>
                    <div className={s.button_block}>
                        <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                    </div>
                </div>

                <div className={s.support_wrapper}>
                    <div className={s.container_support}>
                        <div>
                            <div className={s.support_subtitle}>
                                Competitive rates, expert assistance, efficiency and reliability
                            </div>
                            <div className={s.support_title}>
                                We are committed to <span className={s.underline}> exceeding </span> your expectations
                            </div>
                            <div className={s.support_text}>
                                Join us now and discover the difference between working with a trusted partner for all
                                your currency exchange needs.
                            </div>
                        </div>
                        <div className={s.img}>
                            <img src={support} alt=""/>
                        </div>
                    </div>

                    <div className={s.support_text_mobile}>
                        Join us now and discover the difference between working with a trusted partner for all
                        your currency exchange needs.
                    </div>

                </div>

                <div className={s.contact_us_form_wrapper}>
                    <ContactUsForm/>
                </div>

            </div>
        </div>
        </motion.div>
    );
};

export default ForeignExchange;
