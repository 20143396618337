import React, {Dispatch, SetStateAction, useState} from 'react';
import {Loader} from "../Loader/Loader";
import s from "./Form2FaSettings.module.css";
import MyInput from "../ui/MyInput/MyInput";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import MyBtn from "../ui/MyBtn/MyBtn";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {useFormik} from "formik";
import {send2FaThunk, SetNew2FaStatus} from "../../store/reducers/ActionCreators";
import CustomModal from "../ui/CustomModal/CustomModal";
import {ReactComponent as Back} from '../../assets/newImg/personalAccount/back.svg'

type PropsType = {
    qrData: {
        code: string;
        qr: string;
    };
    setIs2faSettingsOpen: Dispatch<SetStateAction<boolean>>
}

const Form2FaSettings = (props: PropsType) => {

    const {isLoading} = useAppSelector(store => store.userReducer)
    const token = useAppSelector(state => state.userReducer.user.userToken)

    const dispatch = useAppDispatch()
    const [isCodeWrong, setIsCodeWrong] = useState(false)
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)


    const send2FaFormik = useFormik({
        initialValues: {
            code: '',
        },
        onSubmit: async values => {
            dispatch(send2FaThunk(token, values))
                .then((res) => {
                    setIsCodeWrong(false)
                    setIsSuccessModalOpen(true)
                    dispatch(SetNew2FaStatus())
                })
                .catch((rej) => {
                    setIsCodeWrong(true)
                    setIsErrorModalOpen(true)
                })
        }
    })

    const closeModal = () => {
        setIsSuccessModalOpen(false)
        props.setIs2faSettingsOpen(false)
    }

    return (
        <div>
            <CustomModal setIsModalOpen={setIsSuccessModalOpen} isModalOpen={isSuccessModalOpen}>
                <div>
                    <div className={s.modal_title}>
                        Success!
                    </div>
                    <div className={s.subtitle}>
                        Google Authenticator (2FA) has been enabled.
                    </div>
                    <div className={s.btn_block}>
                        <div className={s.btn_wrapper}>
                            <MyBtn title={"Ok"} onClick={() => closeModal()}/>
                        </div>
                    </div>
                </div>

            </CustomModal>
            <CustomModal type="error" isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen}>
                <div className={s.flex}>
                    <div className={s.modal_title}>
                        Oops!
                    </div>
                    <div className={s.subtitle}>
                        Something went wrong. Please try again.
                    </div>
                    <div className={s.btn_block}>
                        <div className={s.btn_wrapper}>
                            <MyBtn buttonType="error" title={"Ok"} onClick={() => {
                                setIsErrorModalOpen(false)
                            }}/>
                        </div>
                    </div>
                </div>
            </CustomModal>

            <form onSubmit={send2FaFormik.handleSubmit}>
                {
                    isLoading
                        ? <Loader/>
                        : <div className={s.wrapper}>
                            <div className={s.autentication_list_block}>
                                <div className={s.title}>
                                    2fa settings
                                </div>
                                <div className={s.settings_desc}>
                                    <div>
                                        <div className={s.desc_item}>
                                            <span><b>1.</b> Get the Google Authenticator App from the <b>Google Play/App Store.</b></span>
                                        </div>
                                        <div className={s.desc_item}>
                                            <span><b>2.</b> In the App select <b>Set up account.</b></span>
                                        </div>
                                        <div className={s.desc_item}>
                                            <span><b>3.</b> Choose <b>Scan barcode.</b></span>
                                        </div>
                                    </div>
                                </div>
                                <div className={s.qr_block}>
                                    <img src={props.qrData.qr} className={s.qr_image} alt=""/>
                                    <div className={s.input_wrapper}>
                                        <span className={s.input_label}>Code</span>
                                        <div className={s.input_wrapper}>
                                            <MyInput value={props.qrData.code}
                                                     onChange={() => {
                                                     }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={s.input_block}>
                                    <div className={s.title}>
                                        Enable two-factor authentication
                                    </div>
                                    <div className={s.mobile_width}>
                                        <span className={s.input_label}>code from Google Authenticator</span>
                                        <div className={s.input_wrapper}>
                                            <MyInput
                                                id="code"
                                                name="code"
                                                value={send2FaFormik.values.code}
                                                placeholder={"6 digits"}
                                                onChange={send2FaFormik.handleChange}
                                                isError={isCodeWrong}
                                                touched={send2FaFormik.touched}
                                            />
                                            {isCodeWrong &&
                                                <span className={s.error}>
                                                        invalid code
                                                    </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={s.buttons_block}>
                                    <div className={s.button_wrapper}>
                                        <TransparentButton title={'Back'}
                                                           large
                                                           style={{width: '100%'}}
                                                           icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                                           onClick={() => props.setIs2faSettingsOpen(false)}/>
                                    </div>
                                    <div className={s.button_wrapper}>
                                        <MyBtn title={'Enable'} type="submit"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </form>
        </div>
    );
};

export default Form2FaSettings;
