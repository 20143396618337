import React from 'react';
import s from "../LegalAndPrivacy.module.css";

const FifthPanel = () => {
    return (
        <div className={s.collapse_text}>

            <div className={s.point_title}>
                Title and References
            </div>

            <div className={s.point_text}>
                <b>CompanyName</b> Regulatory Compliance Management Program (RCM) (referred to in this document as
                “the
                Program” or “this Program”) The Program is subordinate to, and reliant on, CompanyName board approved
                Regulatory Compliance Management Policy. The Program is also subordinate to, and reliant on, CompanyName
                risk document called The Compliance Management (RCM) Framework to ensure that CompanyName (the
                “Company”), maintains a prudent regulatory compliance risk profile by establishing a framework for the
                management of regulatory compliance risk. This Program may be amended upon relative developments that
                occur within CompanyName and external to the company.
            </div>

            <div className={s.point_title}>
                Regulators’ expectations
            </div>

            <div className={s.point_text}>
                Guideline E-13 sets out OSFI's expectations for FRFIs with respect to the management of regulatory
                compliance risk inherent to CompanyName business activities enterprise-wide. A wide variety of laws
                and regulations that apply to CompanyName in Canada.
            </div>

            <div className={s.point_title}>
                Purpose
            </div>

            <div className={s.point_text}>
                The purpose of the Regulatory Compliance Management (RCM) Program is to ensure that CompanyName
                maintains a program that defines the inventory of regulatory requirements and self-assess against its
                controls. This document outlines and provides a description of how CompanyName delivers the following
                key topics which are required to assess and identify gaps in inherent risk, the acceptable level of
                risks, and residual risks with its controls:
            </div>

            <div className={s.point_text}>
                <ol>
                    <li>Inventory of regulatory compliance requirements</li>
                    <li>Monitoring of regulatory changes</li>
                    <li>Regulatory risk and compliance control self-assessment</li>
                    <li>Compliance monitoring and testing</li>
                    <li>Compliance risk management</li>
                    <li>Compliance reporting</li>
                </ol>
            </div>

            <div className={s.point_title}>
                Compliance self assessment process
            </div>

            <div className={s.point_subtitle}>
                1. Inventory of Regulatory Compliance Requirements
            </div>

            <div className={s.point_text}>
                The CCO will maintain a list of applicable Regulations (“Inventory”) along with accompanying full texts
                for consultation and review by staff. The Inventory is reviewed regularly for changes to the regulatory
                landscape, CompanyName business and strategic goals.
            </div>

            <div className={s.point_text}>
                The Inventory is documented within a spreadsheet and maintained by Compliance. The spreadsheet is saved
                on the shared drive.
            </div>

            <div className={s.point_subtitle}>
                2. Monitoring of Regulatory Changes
            </div>

            <div className={s.point_text}>
                The CCO is responsible for updating the Inventory for new Regulations and evaluating the impact on key
                risk factors, such as new distribution channels, products, and corporate functions. Monitoring of
                regulatory changes is done by Compliance staff and delegates from each department through the following
                channels:
            </div>

            <div className={s.point_text}>
                a) Direct interactions with regulators and industry associations i.e The Office of the Superintendent of
                Financial Institutions (OSFI), Financial Transactions and Report Analysis Center of Canada
                (FINTRAC). <br/>
                b) Subscription to sectoral/industry professional journals (local and international) <br/>
                c) Monitoring of legislative and regulatory changes through review of regulatory web pages and internet
                web searches.
            </div>

            <div className={s.point_text}>
                Compliance lists any regulations that are due to become in force for monitoring purposes within a
                spreadsheet called “the Compliance Observation Log. This is maintained by the CCO to track any emerging
                key risk factors.
            </div>

            <div className={s.point_subtitle}>
                3. Regulatory Risk Assessment and Compliance Self-Assessment
            </div>

            <div className={s.point_text}>
                Risk Assessment of the Inventory
            </div>

            <div className={s.point_text}>
                The prioritization and assessment of regulatory compliance risks associated with CompanyName ’s
                business activities is a key component of CompanyName’s risk management process. The Compliance
                Department reviews all Legislations, Regulations and Laws to keep up to date with all changes and
                documents using a spreadsheet method.
            </div>

            <div className={s.point_text}>
                At least annually and as changes to the Inventory are made, a risk assessment of the Inventory is
                conducted to develop, confirm, or amend risk assessments as required for new, amended, and existing
                regulatory requirements. Each regulatory requirement is riskassessed using CompanyName’s RCM risk
                assessment methodology outlined in the Regulatory Compliance Risk Framework. This risk assessment is
                conducted and documented by the CCO with input from applicable department management and oversight
                functions.
            </div>

            <div className={s.point_text}>
                For each Inventory element, department management (first line of defense) complete selfassessments of
                each element of the Inventory, as assigned by the CCO. The compliance self-assessment requires the
                assessor to <br/>
                (i) indicate compliance status,<br/>
                (ii) provide an inherent risk rating,<br/>
                (iii) provide an explanation and evidence of the controls in place to mitigate the inherent regulatory
                compliance risk; <br/>
                (iv) assess whether the controls are designed appropriately and operating effectively.
            </div>

            <div className={s.point_text}>
                The compliance department created an excel intake form that provides the first line of defence with a
                spreadsheet that allows them to fill out the required information to each of the requirements assigned.
                The follow heading and an explanation of each is found in Appendix 1.
            </div>

            <div className={s.point_text}>
                The business lines are responsible to provide two stages of assessment:
            </div>

            <div className={s.point_text}>
                <b>Stage 1:</b><br/>
                Setting the Acceptable Level of Risk (ALR) – Risk Tolerance Level: The ALR is the compliance exposure
                (Company name) is willing to undertake or accommodate for each element. Together with the Compliance
                Department, with the assistance of the Risk areas, business lines will assign each element an Acceptable
                Level of Risk. This exposure will be determined based on probability and impact of occurrence.
            </div>

            <div className={s.point_text}>
                <b>Stage 2:</b><br/>
                Determining the Current Level of Risk (CLR): This stage is a 2-phase process. The first phase is
                performed by the Business lines, where it assesses each element based on its probability of occurrence
                and impact if the risk of compliance crystalizes. This is done by assessing the current/existing
                controls in place (This is the CLR). In assessing the current controls, each Business lines will provide
                the Day2Day procedures and Day2Day Control for each element and go ahead to evaluate the control.
            </div>

            <div className={s.point_text}>
                The second phase is performed by the Compliance team. Here, the compliance team performs a two-step
                process.
            </div>

            <div className={s.point_text}>
                <b>•Step 1:</b> Compliance reviews the Day2day procedures provided by the business lines through a
                review of
                their documentation. KYC (Know your customer/client). requirements for verifying client identity,
                politically exposed persons, heads of international organizations, their family members and close
                associates, beneficial ownership, and third party determination;
            </div>

            <div className={s.point_text}>
                <ul>
                    <li><b>business relationship</b> and <b>ongoing monitoring requirements.</b></li>
                    <li><b>record keeping</b> requirements.</li>
                    <li><b>reporting</b> requirements.</li>
                    <li><b>travel rule</b> requirements: this includes your requirement to develop and apply written
                        risk-based
                        policies and procedures to help determine whether you should suspend or reject an electronic
                        funds transfer (EFT) or virtual currency transfer that you receive, and any other follow-up
                        measures, if the transfer does not include the required travel rule information and you are
                        unable to obtain this information through your reasonable measures;
                    </li>
                    <li><b>Ministerial directive</b> requirements.</li>
                </ul>
            </div>

            <div className={s.point_text}>
                <b>•Step 2:</b> Compliance tests controls provided by the Business lines for its operation
                effectiveness. The
                objective of testing the design effectiveness of controls is to determine if the control is properly
                designed to mitigate the regulatory compliance risk and whether the control consistently operates as
                designed over the testing period.
            </div>

            <div className={s.point_text}>
                The outcome of both steps above is shared with the business lines in a timely fashion before a final
                control rating/status is applied as the Current Level of Risk associated with each element. The CCO will
                request remediation plans from the impacted management if control gaps are detected, in either design or
                operating effectiveness. The department management will prepare a remediation plan and the CCO will
                provide advice to the impacted department in line with (Company’s name) risk appetite documented within
                the RCM Framework.
            </div>

            <div className={s.point_text}>
                Outcomes 3rd stage of assessment:
            </div>

            <div>
                <b>Stage 3:</b> <br/>
                Mitigation Identification & Testing: This stage required the Business Lines to propose additional
                controls and mitigation steps to close out the deficiencies observed from stage 2 above. The Compliance
                Department may also recommend certain controls based on the effectiveness review it carried out in Stage
                2. A target date for implementation is set. Also, a responsible owner is assigned. Once the mitigation
                has been implemented, the compliance department will carry out a test of effectiveness. The outcome of
                this test (RESIDUAL LEVEL OF RISK) is measured against the initial Acceptable Level of Risk determined
                at the beginning of the assessment. The outcome of these reviews will then determine.
            </div>

            <div className={s.point_text}>
                Once the outcomes have been decided, and any action items identified, they will be placed on the
                observation log. Each quarter, the Compliance Department will follow up with the owners of the business
                lines to ensure completion.
            </div>

            <div className={s.point_title}>
                4. Compliance monitoring and testing
            </div>

            <div className={s.point_text}>
                The CCO provides an independent challenge to the department’s compliance selfassessment by reviewing the
                responses to ensure the responses are complete, appropriate, and reasonable relative to the risk
                assessment. If the CCO detects a misalignment of risk against controls, the CCO will either conduct
                testing or provide a compliance gap evaluation working with the impacted department management As part
                of the compliance team’s monitoring strategy, Key Compliance Indicators are set for elements whose
                control requires significant improvement and the impact of noncompliance/occurrence.
            </div>

            <div className={s.point_title}>
                5. Compliance Risk Management
            </div>

            <div className={s.point_text}>
                Compliance gaps may be identified through <br/>
                (i) department self-assessment; <br/>
                (ii) monitoring or testing results; <br/>
                (iii) systemic complaints; or <br/>
                (iv) internal audit findings.
            </div>

            <div className={s.point_text}>
                Remediation plans will be prioritized taking into consideration the level of residual risk associated
                with the compliance gap and the strategic priorities of the CEO. Remedial plans will be designed by
                management to bring the regulatory risks to acceptable levels and in alignment with CompanyName’s risk
                appetite for compliance risk. The CCO will work with management to review the appropriateness of the
                remediation plans and track plan progress quarterly by requesting status updates. The CEO will ensure
                that remediation plans are appropriately resourced. All risk remediation plans will be logged in the
                Compliance Risk Action Plan Log and will be monitored, escalated, and reported by the CCO.
            </div>

            <div className={s.point_title}>
                6. Compliance Reporting
            </div>

            <div className={s.point_text}>
                Procedures exist to provide assurance that sufficient information about the adequacy of, adherence to
                and effectiveness of RCM is communicated on a timely basis to CompanyName CEO, the Board and other
                individuals with RCM responsibilities.
            </div>

            <div className={s.point_text}>
                Compliance Reports – Quarterly reports to the CEO and the Board are to be in a format that allows them
                to clearly understand the material regulatory risks to which CompanyName is exposed based on its risk
                appetite, and the adequacy of key controls to manage those risks and facilitate the performance of their
                oversight responsibilities. The CCO will provide ad hoc reports on material gaps, remediation plan
                progress and other matters relevant to the CEO, as required in the CCO’s judgment.
            </div>

            <div className={s.point_text}>
                The CCO has direct access to the CEO. The CCO will provide an opinion to the Board, on an annual basis,
                on the adequacy of, adherence to and effectiveness of the RCM Framework, day-to-day controls, and
                whether, based on the independent monitoring and testing conducted by or on behalf of CompanyName,
                CompanyName is in compliance with applicable Regulations.
            </div>

        </div>
    );
};

export default FifthPanel;
