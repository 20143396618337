import React, {Dispatch, SetStateAction, useState} from 'react';
import {motion} from "framer-motion";
import s from './BankTransferStepTwo.module.css'
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {ReactComponent as Back} from '../../../../../assets/newImg/personalAccount/back.svg'
import Icon from "@ant-design/icons";
import TransparentButton from "../../../../../components/ui/TransparentButton/TransparentButton";
import {ITransacton} from "../../../../../types/interfaces";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {SendTransferThunk} from "../../../../../store/reducers/ActionCreators";
import CustomModal from "../../../../../components/ui/CustomModal/CustomModal";
import {PrepareDataForAzaTransaction} from "../../../../../helpers/PrepareDataForAzaTranzactions";
import {setDisabled} from "../../../../../store/reducers/UserSlice";

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    current: number
    transferData: ITransacton
}

const BankTransferStepTwo = (props: PropsType) => {

    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.userReducer.user.userToken)
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)
    // const [fields, setFields] = useState({})
    const sendTransferData = async () => {
        dispatch(setDisabled(true))

        const preparedData = await PrepareDataForAzaTransaction(props.transferData)

        dispatch(SendTransferThunk(token, preparedData))
            .then((res) => {
                if (res.data === 'Created') {
                    setIsSuccessPopupOpen(true)
                } else {
                    setIsErrorPopupOpen(true)
                }
            })
            .catch((e) => {
            setIsErrorPopupOpen(true)
            })
            .finally(() => {
                localStorage.removeItem("transfer-form")
                dispatch(setDisabled(false))
            })
    }

    const closeAndRedirect = () => {
        setIsSuccessPopupOpen(false)
        localStorage.removeItem("transfer-form")
        props.setCurrent(0)
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >

            <CustomModal isModalOpen={isSuccessPopupOpen} setIsModalOpen={setIsSuccessPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_title}>
                        Success!
                    </div>
                    <div className={s.popup_subtitle}>
                        Your request has been sent.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn title={'Ok'} medium onClick={() => closeAndRedirect()}/>
                    </div>
                </div>
            </CustomModal>

            <CustomModal type="error" isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className={s.modal_content}>
                    <div className={s.popup_title}>
                        Oops!
                    </div>
                    <div className={s.popup_subtitle}>
                        Something went wrong. Please try again.
                    </div>
                    <div className={s.popup_button_block}>
                        <MyBtn buttonType="error" title={"Ok"} onClick={() => {
                            setIsErrorPopupOpen(false)
                        }}/>
                    </div>
                </div>
            </CustomModal>

            <div className={s.title}>
                Transfer Information
            </div>
            <div className={s.data_block}>
                {
                    props.transferData.payerAccount !== '' &&
                    <div className={s.row}>
                        <span className={s.row_name}>Payer account</span>
                        <span className={s.row_value}>
                            {props.transferData.payerAccount}
                        </span>
                    </div>
                }

                {
                    (props.transferData.securityQuestion !== '' && props.transferData.securityQuestion !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Seсurity Question</span>
                        <span className={s.row_value}>
                            {props.transferData.securityQuestion}
                        </span>
                    </div>
                }

                {
                    (props.transferData.beneficiaryName !== '' && props.transferData.beneficiaryName !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Beneficiary Name</span>
                        <span className={s.row_value}>
                            {props.transferData.beneficiaryName}
                        </span>
                    </div>
                }

                {
                    (props.transferData.beneficiaryEmail !== '' && props.transferData.beneficiaryEmail !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Beneficiary Email</span>
                        <span className={s.row_value}>
                            {props.transferData.beneficiaryEmail}
                        </span>
                    </div>
                }

                {
                    (props.transferData.securityAnswer !== '' && props.transferData.securityAnswer !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Sequrity Answer</span>
                        <span className={s.row_value}>
                            {props.transferData.securityAnswer}
                        </span>
                    </div>
                }

                {
                    (props.transferData.senderIdType?.name !== '' && props.transferData.senderIdType?.name !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Sender ID Type</span>
                        <span className={s.row_value}>
                            {props.transferData.senderIdType?.name}
                        </span>
                    </div>
                }

                {
                    (props.transferData.senderIdNumber !== undefined && props.transferData.senderIdNumber !== '' ) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Sender ID Number</span>
                        <span className={s.row_value}>
                            {props.transferData?.senderIdNumber}
                        </span>
                    </div>
                }

                {
                    props.transferData.uniqueId !== '' &&
                    <div className={s.row}>
                        <span className={s.row_name}>Unique Id</span>
                        <span className={s.row_value}>
                            {props.transferData.uniqueId}
                        </span>
                    </div>
                }

                {
                    props.transferData.transitNumber !== '' &&
                    <div className={s.row}>
                        <span className={s.row_name}>Transit Number</span>
                        <span className={s.row_value}>
                            {props.transferData.transitNumber}
                        </span>
                    </div>
                }

                {
                    props.transferData.sortCode !== '' &&
                    <div className={s.row}>
                        <span className={s.row_name}>Sort Code</span>
                        <span className={s.row_value}>
                            {props.transferData.sortCode}
                        </span>
                    </div>
                }

                {
                    props.transferData.institutionNumber !== '' &&
                    <div className={s.row}>
                        <span className={s.row_name}>Institution Number</span>
                        <span className={s.row_value}>
                            {props.transferData.institutionNumber}
                        </span>
                    </div>
                }

                {
                    (props.transferData.firstName !== undefined && props.transferData.lastName !== undefined &&
                        props.transferData.firstName !== '' && props.transferData.lastName !== '')
                    &&
                    <div className={s.row}>
                        <span className={s.row_name}>Recipient</span>
                        <span className={s.row_value}>
                            {props.transferData.firstName} {props.transferData.middleName} {props.transferData.lastName}
                        </span>
                    </div>
                }

                {
                    (props.transferData.fullName !== undefined && props.transferData.fullName !== '' ) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Recipient</span>
                        <span className={s.row_value}>
                            {props.transferData?.fullName}
                        </span>
                    </div>
                }

                {
                    (props.transferData.name !== undefined && props.transferData.name !== '') &&
                    <div className={s.row}>
                        <span className={s.row_name}>Recipient</span>
                        <span className={s.row_value}>
                            {props.transferData?.name}
                        </span>
                    </div>
                }

                {
                    (props.transferData.phoneNumber !== '' && props.transferData.phoneNumber !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Recipient's phone number</span>
                        <span className={s.row_value}>
                            {props.transferData.phoneNumber}
                        </span>
                    </div>
                }

                {
                    (props.transferData.recipientsAddress !== '' && props.transferData.recipientsAddress !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Recipient’s address</span>
                        <span className={s.row_value}>
                            {props.transferData.recipientsAddress}
                        </span>
                    </div>
                }

                {
                    (props.transferData.recipientsAccountNumber !== ''  && props.transferData.recipientsAccountNumber !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>IBAN / Account number</span>
                        <span className={s.row_value}>
                            {props.transferData.recipientsAccountNumber}
                        </span>
                    </div>
                }

                {
                    (props.transferData.recipientsSwiftBic !== ''  && props.transferData.recipientsSwiftBic !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>SWIFT / BIC code</span>
                        <span className={s.row_value}>
                            {props.transferData.recipientsSwiftBic}
                        </span>
                    </div>
                }

                {
                    (props.transferData.recipientsIban !== '' && props.transferData.recipientsIban !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>IBAN</span>
                        <span className={s.row_value}>
                            {props.transferData.recipientsIban}
                        </span>
                    </div>
                }

                {
                    (props.transferData.recipientsBankCountry !== '' && props.transferData.recipientsBankCountry !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Recipient’s bank country</span>
                        <span className={s.row_value}>
                            {props.transferData.recipientsBankCountry}
                        </span>
                    </div>
                }

                {
                    (props.transferData.recipientsBank !== '' && props.transferData.recipientsBank !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Recipient’s bank</span>
                        <span className={s.row_value}>
                            {props.transferData.recipientsBank}
                        </span>
                    </div>
                }

                {
                    (props.transferData.recipientsBankAddress !== '' && props.transferData.recipientsBankAddress !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Recipient’s bank address</span>
                        <span className={s.row_value}>
                            {props.transferData.recipientsBankAddress}
                        </span>
                    </div>
                }

                {
                    (props.transferData.email !== '' && props.transferData.email !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Recipient’s email</span>
                        <span className={s.row_value}>
                            {props.transferData.email}
                        </span>
                    </div>
                }

                {
                    (props.transferData.recipientCountry?.name !== '' && props.transferData.recipientCountry?.name !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Recipient’s country</span>
                        <span className={s.row_value}>
                            {props.transferData.recipientCountry?.name}
                        </span>
                    </div>
                }

                {
                    (props.transferData.mobileProvider?.name !== '' && props.transferData.mobileProvider?.name !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Mobile provider</span>
                        <span className={s.row_value}>
                            {props.transferData.mobileProvider?.name}
                        </span>
                    </div>
                }

                {
                    (props.transferData.city !== '' && props.transferData.city !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>City</span>
                        <span className={s.row_value}>
                            {props.transferData.city}
                        </span>
                    </div>
                }

                {
                    (props.transferData.identityCardId !== '' && props.transferData.identityCardId !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Identity card ID</span>
                        <span className={s.row_value}>
                            {props.transferData.identityCardId}
                        </span>
                    </div>
                }

                {
                    (props.transferData.identityCardType?.name !== '' && props.transferData.identityCardType?.name !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Identity card type</span>
                        <span className={s.row_value}>
                            {props.transferData.identityCardType?.name}
                        </span>
                    </div>
                }

                {
                    (props.transferData.transferReason?.name !== '' && props.transferData.transferReason?.name!== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Transfer reason</span>
                        <span className={s.row_value}>
                            {props.transferData.transferReason?.name}
                        </span>
                    </div>
                }

                {
                    (props.transferData.routingNumber !== '' && props.transferData.routingNumber !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Routing number</span>
                        <span className={s.row_value}>
                            {props.transferData.routingNumber}
                        </span>
                    </div>
                }

                {
                    (props.transferData.cashProvider !== '' && props.transferData.cashProvider!== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Cash provider</span>
                        <span className={s.row_value}>
                            {props.transferData.cashProvider}
                        </span>
                    </div>
                }

                {
                    (props.transferData.branchCode?.name !== '' && props.transferData.branchCode?.name!== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Branch code</span>
                        <span className={s.row_value}>
                            {props.transferData.branchCode?.name}
                        </span>
                    </div>
                }

                {
                    (props.transferData.pixKeyType?.name !== '' && props.transferData.pixKeyType?.name !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Pix key type</span>
                        <span className={s.row_value}>
                            {props.transferData.pixKeyType?.name}
                        </span>
                    </div>
                }

                {
                    (props.transferData.pixKeyValue !== '' && props.transferData.pixKeyValue !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Pix key value</span>
                        <span className={s.row_value}>
                            {props.transferData.pixKeyValue}
                        </span>
                    </div>
                }

                {
                    (props.transferData.relationshipToSender !== '' && props.transferData.relationshipToSender !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Relationship to sender</span>
                        <span className={s.row_value}>
                            {props.transferData.relationshipToSender}
                        </span>
                    </div>
                }

                {
                    (props.transferData.postalCode !== '' && props.transferData.postalCode !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Postal code</span>
                        <span className={s.row_value}>
                            {props.transferData.postalCode}
                        </span>
                    </div>
                }

                {
                    (props.transferData.street !== '' && props.transferData.street !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Street</span>
                        <span className={s.row_value}>
                            {props.transferData.street}
                        </span>
                    </div>
                }

                {
                    (props.transferData.paymentDate !== '' && props.transferData.paymentDate !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Payment date</span>
                        <span className={s.row_value}>
                            {props.transferData.paymentDate}
                        </span>
                    </div>
                }

                {
                    props.transferData.amount !== '' &&
                    <div className={s.row}>
                        <span className={s.row_name}>Amount</span>
                        <span className={s.row_value}>
                            {props.transferData.amount}
                        </span>
                    </div>
                }

                {
                    (props.transferData.purpose !== '' && props.transferData.purpose !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Purpose</span>
                        <span className={s.row_value}>
                            {props.transferData.purpose}
                        </span>
                    </div>
                }

                {
                    (props.transferData.paymentType !== '' && props.transferData.paymentType !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Payment type</span>
                        <span className={s.row_value}>
                            {props.transferData.paymentType}
                        </span>
                    </div>
                }

                {
                    (props.transferData.templateName !== '' && props.transferData.templateName !== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Template name</span>
                        <span className={s.row_value}>
                            {props.transferData.templateName}
                        </span>
                    </div>
                }

                {
                    (props.transferData?.bankCode?.name !== '' && props.transferData.bankCode?.name!== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Bank code</span>
                        <span className={s.row_value}>
                            {props.transferData?.bankCode?.name}
                        </span>
                    </div>
                }

                {
                    (props.transferData.bankAccount !== '' && props.transferData.bankAccount!== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Bank account</span>
                        <span className={s.row_value}>
                            {props.transferData.bankAccount}
                        </span>
                    </div>
                }

                {
                    (props.transferData.bankAccountType?.name !== '' && props.transferData.bankAccountType?.name!== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Bank account type</span>
                        <span className={s.row_value}>
                            {props.transferData.bankAccountType?.name}
                        </span>
                    </div>
                }

                {
                    (props.transferData.invoice !== '' && props.transferData.invoice?.name!== undefined) &&
                    <div className={s.row}>
                        <span className={s.row_name}>Invoice</span>
                        <span className={s.row_value}>
                            {props.transferData.invoice?.name}
                        </span>
                    </div>
                }


            </div>
            <div className={s.buttons_block}>
                <div className={s.button_wrapper}>
                    <TransparentButton
                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                        large
                        title={"Back"}
                        onClick={() => props.setCurrent(0)}/>
                </div>

                <div className={s.button_wrapper}>
                    <MyBtn title={"Transfer"} onClick={() => sendTransferData()}/>
                </div>
            </div>
        </motion.div>
    );
};

export default BankTransferStepTwo;
